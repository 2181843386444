import STATION_CONST from './station-constant';

var initial_state = {
    stations:         [],
    updateLoader:     false,
    selectedStation:  null,
    fetchLoader:      false,
    snackMsg:         null,
}

export const stationReducer = (state = initial_state, action) => {
    switch(action.type){

        ///////////////////////////////////////////////////////////////////////////////////////
        case STATION_CONST.SET_SNACK: {
            return {
                ...state,
                snackMsg: action.payload
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        case STATION_CONST.EMPTY_STATION_LIST: {
            return {
                ...state,
                stations: []
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        case STATION_CONST.SET_SELECTED_STATION: {
            return {
                ...state,
                selectedStation: action.payload
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        case STATION_CONST.UPDATE_STATION_START:
        case STATION_CONST.UPDATE_ST_OPERATOR_START:
        case STATION_CONST.DELETE_STATION_START: {
            return {
                ...state,
                updateLoader: true,
                snackMsg: null,
            }
        }
        case STATION_CONST.UPDATE_STATION_SUCCESS:
        case STATION_CONST.UPDATE_ST_OPERATOR_SUCCESS:
        case STATION_CONST.DELETE_STATION_SUCCESS: {
            return {
                ...state,
                updateLoader: false,
                selectedStation: action.payload,
            }
        }
        case STATION_CONST.UPDATE_STATION_FAIL:
        case STATION_CONST.UPDATE_ST_OPERATOR_FAIL:
        case STATION_CONST.DELETE_STATION_FAIL: {
            return {
                ...state,
                updateLoader: false,
                snackMsg: action.payload,
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        case STATION_CONST.FETCH_STATIONS_START: {
            return {
                ...state,
                fetchLoader: true,
                snackMsg: null
            }
        }
        case STATION_CONST.FETCH_STATIONS_SUCCESS: {
            return {
                ...state,
                fetchLoader: false,
                stations: action.payload,
            }
        }
        case STATION_CONST.FETCH_STATIONS_FAIL: {
            return {
                ...state,
                fetchLoader: false,
                snackMsg: action.payload // payload is the snackMsg
            }
        }

        default: return state;
    }
}