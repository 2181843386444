import ACTIVITY_CONST from './activity-constants';

var initial_state = {
    activities:         [],
    fetchLoader:        false,
    snackMsg:           null,
}

export const activityReducer = (state = initial_state, action) => {
    switch(action.type){

        ///////////////////////////////////////////////////////////////////////////////////////
        case ACTIVITY_CONST.SET_SNACK_MSG: {
            return {
                ...state,
                snackMsg: action.payload
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        case ACTIVITY_CONST.EMPTY_ACTIVITIES: {
            return {
                ...state,
                activities: []
            }
        }

    
        ///////////////////////////////////////////////////////////////////////////////////////
        case ACTIVITY_CONST.FETCH_ACTIVITIES_START: {
            return {
                ...state, 
                fetchLoader: true,
                snackMsg: null
            }
        }

        case ACTIVITY_CONST.FETCH_ACTIVITIES_SUCCESS: {
            return {
                ...state, 
                fetchLoader: false,
                activities: [ ...state.activities,  ...action.payload ],
            }
        }

        case ACTIVITY_CONST.FETCH_ACTIVITIES_FAIL: {
            return {
                ...state, 
                fetchLoader: false,
                snackMsg: action.payload
            }
        }

        default: return state;
    }
}