const REQUEST_CONST = {

    SET_SNACK:                  'SET_SNACK',
    SET_SELECTED_REQUEST:       'SET_SELECTED_REQUEST',
    EMPTY_REQUESTS:             'EMPTY_REQUESTS',

    FETCH_REQUESTS_START:       'FETCH_REQUESTS_START',
    FETCH_REQUESTS_SUCCESS:     'FETCH_REQUESTS_SUCCESS',
    FETCH_REQUESTS_FAIL:        'FETCH_REQUESTS_FAIL',

    UPDATE_REQUEST_START:       'UPDATE_REQUEST_START',
    UPDATE_REQUEST_SUCCESS:     'UPDATE_REQUEST_SUCCESS',
    UPDATE_REQUEST_FAIL:        'UPDATE_REQUEST_FAIL',
}

export default REQUEST_CONST;