import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Hidden, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import PropTypes from 'prop-types';
import { MdDashboard, GiWeight, GiTeamIdea, BsFillShieldLockFill, FaUserTie, BiSupport, ImStack, AiFillTool, SiGoogletagmanager, MdAdd, FiActivity } from 'react-icons/all';

import menuData from './data';

function renderIcon(slug){
    switch(slug){
        case 'dashboard':
            return <MdDashboard size={24}  />
        case 'packages':
            return <ImStack size={24}  />
        case 'weigh-stations':
            return <GiWeight size={24}  />
        case 'teams':
            return <GiTeamIdea size={24}  />
        case 'requests':
            return <BiSupport size={24}  />
        case 'admins':
            return <BsFillShieldLockFill size={22}  />
        case 'tsp':
            return <AiFillTool size={24}  />
        case 'omc':
            return <SiGoogletagmanager size={24}  />
        case 'directors':
            return <FaUserTie size={21}  />
        case 'new-resource':
            return <MdAdd size={21}  />
        case 'activities':
            return <FiActivity size={21}  />
        default:
            return <MdDashboard /> 
    }
}
//React.forwardRef((props, ref)
const SvDrawer = (props) => {
    return (
        <Drawer
            open    = { props.open }
            variant = { props.variant }
            onClose = { props.onCloseDrawer }
            anchor  = { 'left'  }
            classes = {{root: 'pr-drawer', paper: 'pr-drawer-paper' }} >
                <div  className = "pr-drawer-item-container">
                    <List>            
                        {/** rendering categories link  */}
                        { menuData.map((menuItem, i) =>
                            props.authReducer.user.role !== 1 && (
                                menuItem.slug === 'admins' || 
                                menuItem.slug === 'directors' || 
                                menuItem.slug === 'omc' || 
                                menuItem.slug === 'tsp' || 
                                menuItem.slug === 'packages' ||
                                menuItem.slug === 'new-resource') 
                            ? null
                            : (props.authReducer.user.role !== 1 && props.authReducer.user.role !== 5 && menuItem.slug === 'activities')
                            ? null
                            :
                            <ListItem 
                                button 
                                component = "a"
                                key = { i }
                                divider
                                onClick = { () => props.onClickMenuItem(menuItem.slug) }
                                selected = { menuItem.slug === props.slug } >
                                    <ListItemIcon 
                                        style={{minWidth: 40, color: menuItem.slug === props.slug  && '#cc0000' }} >
                                            { renderIcon(menuItem.slug) }
                                        </ListItemIcon>
                                    <ListItemText 
                                        primary={ menuItem.title } 
                                        primaryTypographyProps = {{ style: { color: menuItem.slug === props.slug ? '#cc0000' : '#666' } }}
                                    />
                                    { menuItem.secondaryTitle && <span style={{ color: '#888', fontSize: 13 }}>{ menuItem.secondaryTitle }</span> }
                            </ListItem>
                        )}
                    </List>
                </div>
        </Drawer>
    )
}

class PrimaryDrawer extends React.Component  {
    render(){
        return (
            <>
                <Hidden xsDown implementation="css"> { /** Drawer will show on larger device ( > 600px) */ }
                    <SvDrawer 
                        { ...this.props } 
                        variant = { 'permanent' }
                    />
                </Hidden>
                <Hidden smUp implementation="css"> { /** Drawer will show on smaller device ( <= 600px) */  }
                    <SvDrawer 
                        { ...this.props } 
                        variant = {"temporary"}
                    />
                </Hidden>
            </>
        )
    }
    
}

PrimaryDrawer.propTypes = {
    slug:           PropTypes.string,
    open:           PropTypes.bool,
    onCloseDrawer:  PropTypes.func,
}
PrimaryDrawer.defaultProps = {
    slug:           '/',
    open:           false,
    onCloseDrawer:  () => alert('Not Configured')
}

function mapStateToProps(state){
    return {
        authReducer: state.authReducer
    }
}

export default connect(mapStateToProps, null)(PrimaryDrawer);