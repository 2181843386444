import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme =  createMuiTheme({
    palette: {
        primary: {
            main: '#f4ae00',
        },
        secondary: {
            main: '#cc0000'
        }

    },
    mixins: {
        toolbar: {
            minHeight: 72
        }
    },
    overrides: {
        MuiButton: {
            root: {
                textTransform: 'none',
                fontSize: '1.5rem'
                
            },
            containedPrimary: {
                backgroundColor: '#f4ae00',
                
            },
            label: {
                fontFamily: 'Roboto',
                fontSize: '1rem',
                fontWeight: '700',
                color: '#333'
            }
        }
    }
    
});

const PrimaryContainer = (props) => {
    return (
        <ThemeProvider theme = { theme }>
            <div style={{ display: 'flex,', flex: 1 }}>
            { props.children }
            </div>
            
        </ThemeProvider>
    )
}

export default PrimaryContainer;