import ACTIVITY_CONST from './activity-constants';
import Utility from '../../lib/utility';
import action from '../action-generator';


export const setSnackMsg        = (err)      => action(ACTIVITY_CONST.SET_SNACK_MSG, err);
export const emptyActivities    = ()         => action(ACTIVITY_CONST.EMPTY_ACTIVITIES, null);

/*****************************************************************************************
 *              async actions
******************************************************************************************/
export const fetchActivites     = (url, body, cb) => asyncAction(url, body, cb, 'fetchActivites');

/****************************************************************************************
 *              Helper method for async actions
 ****************************************************************************************/
const asyncAction = (url, body, cb, actionType) => {
    let startAction = null, successAction = null, failAction = null;
    switch(actionType){
        case 'fetchActivites': {
            startAction     = ACTIVITY_CONST.FETCH_ACTIVITIES_START;
            successAction   = ACTIVITY_CONST.FETCH_ACTIVITIES_SUCCESS;
            failAction      = ACTIVITY_CONST.FETCH_ACTIVITIES_FAIL;
            break;
        }
        
        default: {
            startAction     = ACTIVITY_CONST.FETCH_ACTIVITIES_START;
            successAction   = ACTIVITY_CONST.FETCH_ACTIVITIES_SUCCESS;
            failAction      = ACTIVITY_CONST.FETCH_ACTIVITIES_FAIL;
            break;
        }
    }
    return (dispatch) => {
        dispatch(action(startAction, null));
        Utility._fetch(url, body, (err, success) => {
            if(err){
                dispatch(action(failAction, err));
                cb && cb(err, null);
            } else {
                dispatch(action(successAction, success));
                cb && cb(null, success);
            }
        })
    }
}