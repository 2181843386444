import React, { Component } from 'react';
import { Tabs, Tab, Avatar, IconButton, ListItem, ListItemText, ListItemAvatar, 
    List, CircularProgress, } from '@material-ui/core';
import { connect } from 'react-redux';
import { MdSend } from 'react-icons/md';

import { RequestDetail, RequestTimer } from '../components';
import ResolveRequest from './resolve-request';

import { setSnackMsg, updateRequest, setSelectedRequest } from '../../../../redux/requests/request-actions';
import { setChatRoom, sendMessage, fetchMessage } from '../../../../redux/chat/chat-actions';
import { fetchCompanies, setSelectedCompany } from '../../../../redux/company/company-actions';
import { PrimaryInput } from '../../../../components/inputs';
import { PrimarySnackbar } from '../../../../components/messages';
import { TwoColumnContainer } from '../../../../components/container';
import Utility from '../../../../lib/utility';
import { endPoints,host } from '../../../../lib/values';

class RequestEditor extends Component {

    constructor(props){
        super(props);
        this.state = {
            gen_pdf:     false,
            inputs:      props.requestReducer.selectedRequest || {},
            pdfDialog:   false,
            tabValue:    'comments',
            comment:    '',
        }
    }

    componentDidMount(){
        this.fetchTsp();
        this.fetchMessage();
    }
    
    

    componentWillUnmount(){
        this.props.setSelectedRequest(null);
    }

    onSendMsg = () => {
        const { comment } = this.state;
        const { chatReducer, authReducer } = this.props;

        const message = {
            _id: 1,
            text: comment,
            createdAt: new Date(),
            user: {
              _id: 2,
              name: authReducer.user.fullname,
              avatar: authReducer.user.image,
            },
          };
        this.props.setChatRoom({
            ...chatReducer.chatRoom, 
            messages: [ ...chatReducer.chatRoom.messages, message ]
        })
        const reqBody = {
            chat_id: chatReducer.chatRoom._id, 
            text: comment
        }
        this.setState({ comment: '' })
        this.props.sendMessage(endPoints.chat.sendMsg, Utility.httpRequest('POST', reqBody), (err, jsonRes) => {
            // do somthing...
        })
    }

    onGeneratePdf = () => {
        const { selectedRequest } = this.props.requestReducer;
        this.setState({ gen_pdf: true })
        Utility._fetch(`${endPoints.request.generatePdf}/${selectedRequest._id}`, Utility.httpRequest('GET'), (err, json) => {
            this.setState({ gen_pdf: false })
            json && window.open(`${host}/${json.file}`, '_blank');
        })
    }

    fetchTsp = () => {
        this.props.fetchTsp(`${endPoints.company.listAll}/1`, Utility.httpRequest('GET'), (err, jsonRes) => {
            // do somethign here...
            if(!err){
                this.setSelectedTsp(jsonRes);
            }
        })
    }
    
    fetchMessage = () => {
        const { selectedRequest } = this.props.requestReducer;
        this.props.fetchMessage(`${endPoints.chat.listMsgs}/${selectedRequest._id}`, Utility.httpRequest('GET'), (err, jsonRes) => {
            // do somethign here...
        })
    }

    setSelectedTsp = (tsps) => {
        const { selectedRequest } = this.props.requestReducer;
        selectedRequest && tsps.find((tsp) => {
            if(selectedRequest.tsp && selectedRequest.tsp._id === tsp._id){
                this.props.setSelectedTsp(tsp);
            }
            return undefined;
        })
    }

    onSelectTechOfficer = (tOfficer) => {
        const { selectedRequest } = this.props.requestReducer;
        const reqBody = { req_id: selectedRequest._id, player_id: tOfficer._id }
        this.props.updateRequest(endPoints.request.assignTofficer, Utility.httpRequest('POST', reqBody), (err, request) => {
            // some necessary stuff
        }) 
    }
    

    renderLeftContent = () => {
        const { requestReducer } = this.props;
        return (
            <>
                <RequestDetail 
                    selectedRequest      = { requestReducer.selectedRequest } 
                    gen_pdf              = { this.state.gen_pdf }
                    onClickGeneratePdf   = { this.onGeneratePdf /*() => this.setState({ pdfDialog: true }) */ }
                />
                { requestReducer.selectedRequest.tech_officer &&  <ResolveRequest /> }
            </>
        )
    }

    renderChatMsgs = () => {
        const { chatReducer } = this.props;
        return (
            <>
            { /** single Message */ }
            { chatReducer.chatRoom && chatReducer.chatRoom.messages.map((msg) => 
            <div>
                { /** user detail */ }
                <div style={{ display: 'flex', marginTop: 16 }}>
                    { /** user Image */ }
                    
                    <Avatar src = { msg.user.avatar } />
                    

                    { /** user name */ }
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
                        <span style={{ fontWeight: 700 }}>{ msg.user.name }</span>
                        <span style={{ color: '#888' }}> { Utility.UserRoleToStr(msg.user.role) } </span>
                    </div>
                </div>
                
                { /** single message */ }
                <div style={{ marginLeft: 40, padding: 12, backgroundColor: '#f8f8f8', borderRadius: 4 }}>
                    <span>
                        { msg.text }
                    </span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <span style={{ color: '#888', fontSize: '.9rem' }}>{ Utility.formatFullDate(msg.createdAt) }</span>
                </div>
            </div>
            )}
            
            </>
        )
    }

    onClickFilePicker = () => {
        this.filepicker.click();
        this.filepicker.onchange = () => {
            console.log(this.filepicker.files);
            this.props.setSnackMsg('SMS values conflict');
        }
    }

    renderRequestMeta = () => {
        const { requestReducer, companyReducer, authReducer, chatReducer } = this.props;
        return (
            <>
                <PrimarySnackbar 
                    open    = { Boolean(requestReducer.snackMsg) }
                    message = { requestReducer.snackMsg }
                    onClose = { () => this.props.setSnackMsg(null) }
                />
                <div className="sidebar-widget-container">
                    <div className="sidebar-widget-title">Timer</div>
                    { requestReducer.selectedRequest.status === 0 ?
                    <RequestTimer 
                        // created_time = {moment()} 
                        // expiry_time = {moment().subtract(10, 'hours').subtract(5, 'days').valueOf()}  
                        created_time = {requestReducer.selectedRequest.created} 
                        expiry_time  = {requestReducer.selectedRequest.duration}  
                    />
                    :
                    <>
                     <div className="content-title-container">
                            <span className="content-title-text">Resolved</span>
                        </div>
                        <div style={{ textAlign: 'center', color: '#333', fontSize: 16, fontWeight: 'bold', marginBottom: 16 }}>{ Utility.formatFullDate(requestReducer.selectedRequest.resolve_at) }</div>
                    </>
                    }
                </div>
                <div className="sidebar-widget-container">
                    <div className="sidebar-widget-title">Conversation</div>
                <Tabs 
                    value={ this.state.tabValue } 
                    variant="fullWidth"
                    onChange = { (e, tabValue) => this.setState({ tabValue })} 
                    style = {{ backgroundColor: '#f8f8f8' }}>
                    <Tab value = "comments" label="Comments" />
                    <Tab value = "attachment" label="Attachments"   />
                    { authReducer.user.role === 2 && <Tab value = "tsp" label="TSP" />  }
                </Tabs>
                { this.state.tabValue === 'comments' &&
                <div style={{ height: 450, width: '100%', }}>
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <div style={{ display: 'flex',flexDirection: 'column-reverse', height: '100%', overflow: 'auto', borderLeft: '1px solid #f1f1f1', borderRight: '1px solid #f1f1f1' }}>
                            { chatReducer.fetch_loader ? <CircularProgress /> :  this.renderChatMsgs() }    
                            { chatReducer.chatRoom && (chatReducer.chatRoom.messages.length === 0 || requestReducer.selectedRequest.status === 0) && <span style={{ textAlign: 'center', color: '#888', marginTop: 32, fontWeight: 'bold' }}>So far, no comments</span> }
                        </div>
                        <div style={{ display: 'flex', border: '1px solid #ddd', alignItems: 'center', borderRadius: 50 }}>
                            { requestReducer.selectedRequest && requestReducer.selectedRequest.status === 0 ? 
                            <>
                                <PrimaryInput
                                    formFullWidth =  { true }
                                    inputMultiline = { true }
                                    inputRows       = { 1 }
                                    inputMaxRows = { 3 }
                                    inputPlaceholder = "Type here..."
                                    inputStyle = {{ backgroundColor: 'transparent' }}
                                    inputValue  = { this.state.comment }
                                    onChangeInput = { e => this.setState({ comment: e.target.value }) }
                                />
                                <div style={{display: 'flex' }}>
                                    <input type="file" ref={ fp => this.filepicker = fp } style={{ display: 'none' }} />
                                    <IconButton onClick = {  this.onSendMsg } disabled = { chatReducer.sendLoader }>
                                        { chatReducer.sendLoader ? <CircularProgress size={24} />  : <MdSend /> }
                                    </IconButton>
                                </div>
                            </>
                            : <div style={{ padding: 16, display: 'flex', justifyContent: 'center', fontWeight: 'bold', color: '#333', flex: 1 }}>Comments Disabled, Request Resolved!</div>
                            }
                        </div>
                    </div>
                </div>
                }
                { this.state.tabValue === 'tsp' && 
                <>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: 8, minHeight: 32 }}>
                        <span style={{ color: '#cc0000', fontWeight: 'bold' }}>
                        Available Tech Officer ({ companyReducer.selectedCompany && companyReducer.selectedCompany.name })
                        </span>
                        {/* <div>
                            <PrimaryButton 
                                buttonColor = "primary"
                                buttonSize = "small"
                                buttonText = "Add New"
                                onClick     = {() => this.setState({ editDialog: 'newOperator' })}
                            />
                        </div> */}
                    </div>
                    <List>
                        { companyReducer.selectedCompany && companyReducer.selectedCompany.players.length === 0 
                        ?  <span style={{ color: '#888', display: 'flex', justifyContent: 'center', marginTop: 16}}>{ companyReducer.selectedCompany.name } has no Tech Officer </span>
                        : requestReducer.updateLoader 
                        ? 
                        <CircularProgress />
                        : 
                        requestReducer.selectedRequest.tech_officer 
                        ? <strong>Tech Officer has been assigned</strong>
                        :
                        companyReducer.selectedCompany && companyReducer.selectedCompany.players.map((officer) => 
                            <ListItem button divider key = { officer._id } onClick = {() => this.onSelectTechOfficer(officer) } >
                                <ListItemAvatar>
                                    <Avatar src={ officer.image || ' ' } alt = { officer.fullname } />
                                </ListItemAvatar>
                                <ListItemText primary={ officer.fullname } secondary = { officer.mobile } />
                                
                            </ListItem>
                        )}
                    </List>
                </>
                }
                </div>
            </>
        )
    }

    render(){
        return(
            <TwoColumnContainer 
                leftContent = { this.renderLeftContent() }
                rightContent = { this.renderRequestMeta() }
            />
        )
    }
}

function mapStateToProps(state){
    return {
        requestReducer: state.requestReducer,
        authReducer:    state.authReducer,
        stationReducer: state.stationReducer,
        companyReducer: state.companyReducer,
        chatReducer:    state.chatReducer,
    }
}

function mapDispatchToProps(dispatch){
    return {
        setSnackMsg:        (msg)   => dispatch(setSnackMsg(msg)),
        setSelectedRequest: (req)   => dispatch(setSelectedRequest(req)),
        setSelectedTsp:     (tsp)   => dispatch(setSelectedCompany(tsp)),
        updateRequest:      (url, body, cb) => dispatch(updateRequest(url, body, cb)),
        fetchTsp:           (url, body, cb) => dispatch(fetchCompanies(url, body, cb)),
        fetchMessage:       (url, body, cb) => dispatch(fetchMessage(url, body, cb)),
        sendMessage:        (url, body, cb) => dispatch(sendMessage(url, body, cb)),
        setChatRoom:        (chatRoom)      => dispatch(setChatRoom(chatRoom)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestEditor);