import React, { Component } from 'react';
import { connect } from 'react-redux';

import { PrimaryContainer } from '../../components/container';
import { PrimaryButton } from '../../components/buttons';
import { logout, setSessionError } from '../../redux/auth/auth-actions';
import { setContentView } from '../../redux/dom/dom-actions';
import { contentViews } from '../../lib/values';

class ErrorPage extends Component {

    componentDidMount(){
        this.props.authReducer.sessionError && this.props.logout()
    }

    onClickLogin = () => {
        this.props.setSessionError(null);
        this.props.setContentView(contentViews.dashboard);
        this.props.history.replace('/');
    }

    onClickTryAgain = () => {
        window.location = window.location.protocol + '//' + window.location.host;
    }

    renderError = () => {
        const { authReducer } = this.props;
        return (
            <div className="center-flex auth-bg full-height" >
                <div className="auth-form-container">
                    <h2 style={{ color: '#cc0000', fontFamily: 'sans-serif', margin: 0 }}>{ authReducer.sessionError ? "SESSION ERROR" : "LOADING FAILED" }</h2>
                    <h4 style={{color: '#888', fontFamily: 'sans-serif', fontWeight: 500, margin: 24, fontSize: 16, textAlign: 'center' }}>
                    { authReducer.sessionError 
                        ? "Your session has been expired. Please relogin to continue"
                        : "Check your internet connection or click Try Again button"
                    }   
                    </h4>
                    <PrimaryButton  
                        buttonText  = { authReducer.sessionError ? "Login Now" : "Try Again" }
                        buttonColor = "primary"
                        buttonSize  = "large"
                        onClick     = { authReducer.sessionError ? this.onClickLogin : this.onClickTryAgain }
                    />
                </div>
            </div>
        )
    }

    render(){
        return (
        <PrimaryContainer>
            { this.renderError() }
        </PrimaryContainer>

        )
    }
}

function mapStateToProps(state){
    return {
        authReducer: state.authReducer,
    }
}
function mapDispatchToProps(dispatch){
    return  {
        logout:             ()          => dispatch(logout()),
        setSessionError:    (err)       => dispatch(setSessionError(err)),
        setContentView:     (view)      => dispatch(setContentView(view)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);