import COMPANY_CONST from './company-const';

var initial_state = {
    companies:           [],
    selectedCompany:     null,
    fetchLoader:         false,
    addLoader:           false,
    playerLoader:        false,
    delLoader:           false,
    snackMsg:            null,
}

export const companyReducer = (state = initial_state, action) => {
    switch(action.type){

        ///////////////////////////////////////////////////////////////////////////////////////
        case COMPANY_CONST.SET_SNACK_MSG: {
            return {
                ...state,
                snackMsg: action.payload
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        case COMPANY_CONST.EMPTY_COMPANY_LIST: {
            return {
                ...state,
                companies: []
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        case COMPANY_CONST.SET_SELECTED_COMPANY: {
            return {
                ...state,
                selectedCompany: action.payload
            }
        }
        
    ///////////////////////////////////////////////////////////////////////////////////////
        case COMPANY_CONST.FETCH_COMPANY_START: {
            return {
                ...state, 
                fetchLoader: true,
                snackMsg: null
            }
        }

        case COMPANY_CONST.FETCH_COMPANY_SUCCESS: {
            return {
                ...state, 
                fetchLoader: false,
                companies: action.payload,
            }
        }

        case COMPANY_CONST.FETCH_COMPANY_FAIL: {
            return {
                ...state, 
                fetchLoader: false,
                snackMsg: action.payload // payload is the snackMsg
            }
        }

///////////////////////////////////////////////////////////////////////////////////////
        case COMPANY_CONST.ADD_COMPANY_START: {
            return {
                ...state,
                addLoader: true,
                snackMsg: null,
            }
        }
        case COMPANY_CONST.ADD_COMPANY_SUCCESS: {
            return {
                ...state,
                addLoader: false,
            }
        }
        case COMPANY_CONST.ADD_COMPANY_FAIL: {
            return {
                ...state,
                addLoader: false,
                snackMsg: action.payload // payload is the snackMsg
            }
        }

///////////////////////////////////////////////////////////////////////////////////////
        case COMPANY_CONST.DEL_COMPANY_START: {
            return {
                ...state,
                delLoader: true,
                snackMsg: null,
            }
        }
        case COMPANY_CONST.DEL_COMPANY_SUCCESS: {
            return {
                ...state,
                delLoader: false,
                
            }
        }
        case COMPANY_CONST.DEL_COMPANY_FAIL: {
            return {
                ...state,
                delLoader: false,
                snackMsg: action.payload // payload is the snackMsg
            }
        }

///////////////////////////////////////////////////////////////////////////////////////
        case COMPANY_CONST.UPDATE_PLAYER_START: {
            return {
                ...state,
                playerLoader: true,
                snackMsg: null,
            }
        }
        case COMPANY_CONST.UPDATE_PLAYER_SUCCESS: {
            return {
                ...state,
                playerLoader: false,
                
            }
        }
        case COMPANY_CONST.UPDATE_PLAYER_FAIL: {
            return {
                ...state,
                playerLoader: false,
                snackMsg: action.payload // payload is the snackMsg
            }
        }
        default: return state;
    }
}