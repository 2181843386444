import React from 'react';
import { Snackbar } from '@material-ui/core';
import PropTypes from 'prop-types';

const PrimarySnackbar = (props) => {
    return (
        <Snackbar 
            open    =   { props.open }
            onClose =    {props.onClose }
            message =   { props.message }
            autoHideDuration    =   { 4000 }
            anchorOrigin = {{
                vertical: 'bottom',
                horizontal: 'center',
            }}
        />
    )
}

PrimarySnackbar.propTypes = {
    open: PropTypes.bool,
    message: PropTypes.string,
    onClose: PropTypes.func,
}
PrimarySnackbar.defaultProps = {
    open: false,
    message: "No message provided",
    onClose: () => console.log('Snackbar onClose missing'),
}

export default PrimarySnackbar;