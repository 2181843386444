import axios from 'axios';
import moment from 'moment';
import { host, storageKeys } from './values';
import { setSessionError } from '../redux/auth/auth-actions';
import store from '../redux/store';


export default class Utility{
 
    static httpRequest(method, jsonBody, contentType, access_token){
        return {
            method: method,
            headers: {
                'Content-Type'  :   contentType ? contentType : 'application/json',
                'Authorization' :   'Bearer ' + localStorage.getItem(storageKeys.appTokken)
            },
            timeout: 20000,
            data: jsonBody ? JSON.stringify(jsonBody) : undefined
        }
    }

    static _fetch(url, body, cb){
        url = host + url;
        axios({
            url,
            ...body
        })
        .then((response) => {
            cb && cb(null, response.data);
            
        })
        .catch(error => {
            const { response } = error;
        
            if(error.code === 'ECONNABORTED'){
                cb && cb('Request Timeout. Try again', null);
            } else if(response === undefined){
                cb && cb('Network Error', null);
            } else if(response.status === 426) {
                store.dispatch(setSessionError('Your session has expired, Please login to continue'));
            }else {
                cb && cb(response.data.err || "Unknown error", null);
            } 
        });
    }

    static uploadFile(endPoint, formData, onProgress, onComplete){
        const reqUrl = host + endPoint;
        axios.post(reqUrl, formData, {
            headers: {
            'Content-Type' : 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(storageKeys.appTokken)
            },
            timeout: 15000,
            onUploadProgress: onProgress && onProgress
        })
        .then(res => onComplete && onComplete(null, res.data))
        .catch(err => onComplete && onComplete(err.response.data ? err.response.data.err : 'Some thing went wrong. try again.', null));
    }

    static formatFullDate(fullDate){
        return moment(fullDate).format("hh:mm A - MMM DD, YYYY");
    }
    
    static formatDateOnly(fullDate){
        return moment(fullDate).format(" MMM DD, YYYY");
    }

    static timeAgoFormat(date){
        return moment(date).fromNow();
    }

    static subString(str, length){
        return str.length > length 
        ? str.substring(0, length) + '...' 
        : str.substring(0, length);
    }

    static UserRoleToStr(role){
        switch(role){
            case 1: return "NHA Admin";
            case 2: return "Package Manager";
            case 3: return "TSP";
            case 4: return "OMC";
            case 5: return "Director";
            default: return "Unknow"
        }
    }

    static requestTypeToStr(rType){
        switch(rType){
            case 1: return "Routine Maintenance";
            case 2: return "Incidental Maintenance";
            default: return null;
        }
    }
    
    static reqSupportToStr(supportType){
        switch(supportType){
            case 1: return "Normal";
            case 2: return "Urgent";
            default: return null;
        }
    }
    
    static reqProblemToStr(problemType){
        switch(problemType){
            case 1: return "Hardware";
            case 2: return "Software";
            case 3: return "Electrical";
            default: return null;
        }
    }

    static reqStatusToStr(statusType){
        switch(statusType){
            case 0: return "Issued";
            case 1: return "Resolved";
            default: return null;
        }
    }
}