import CHAT_CONST from './chat-constants';
import Utility from '../../lib/utility';
import action from '../action-generator';

// set actions
export const setSnackMsg    = (msg)         => action(CHAT_CONST.SET_SNACK, msg);
export const setChatRoom    = (chatRoom)     => action(CHAT_CONST.SET_SELECTED_CHATROOM, chatRoom);

/**********************************************************************************
 *      async actions
 *********************************************************************************/
export const fetchMessage   = (url, body, cb) => asyncAction(url, body, cb, 'fetchMessage');
export const sendMessage    = (url, body, cb) => asyncAction(url, body, cb, 'sendMessage');

/**********************************************************************************
 *      Helper method for async actions
 *********************************************************************************/
const asyncAction = (url, body, cb, actionType) => {
    let startAction = null, successAction = null, failAction = null;
    switch(actionType){
        case 'fetchMessage': {
            startAction     = CHAT_CONST.FETCH_MESSAGE_START;
            successAction   = CHAT_CONST.FETCH_MESSAGE_SUCCESS;
            failAction      = CHAT_CONST.FETCH_MESSAGE_FAIL;
            break;
        }
        case 'sendMessage': {
            startAction     = CHAT_CONST.SEND_MESSAGE_START;
            successAction   = CHAT_CONST.SEND_MESSAGE_SUCCESS;
            failAction      = CHAT_CONST.SEND_MESSAGE_FAIL;
            break;
        }

        default: {
            startAction     = CHAT_CONST.FETCH_MESSAGE_START;
            successAction   = CHAT_CONST.FETCH_MESSAGE_SUCCESS;
            failAction      = CHAT_CONST.FETCH_MESSAGE_FAIL;
            break;
        }
    }
    return (dispatch) => {
        dispatch(action(startAction, null));
        Utility._fetch(url, body, (err, success) => {
            if(err){
                dispatch(action(failAction, err));
                cb && cb(err, null);
            } else {
                dispatch(action(successAction, success));
                cb && cb(null, success);
            }
        })
    }
}