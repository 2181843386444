import { combineReducers } from 'redux';

import { authReducer } from './auth/auth-reducer';
import { usersReducer } from './users/users-reducers';
import { packageReducer } from './packages/packages-reducers';
import { stationReducer } from './station/station-reducer';
import { requestReducer } from './requests/request-reducer';
import { companyReducer } from './company/company-reducer';
import { dom } from './dom/dom-reducer';
import { chatReducer } from './chat/chat-reducer';
import { activityReducer } from './activity/activity-reducer';


const appReducer = combineReducers({
    authReducer,
    usersReducer,
    packageReducer,
    stationReducer,
    requestReducer,
    companyReducer,
    dom,
    chatReducer,
    activityReducer,
})

export default appReducer;