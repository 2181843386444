import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import PropTypes from 'prop-types';

const CustomDialog = (props) => {
    return (
        <Dialog 
            open = { props.open }
            onClose = { props.onClose }
            fullWidth = { props.fullWidth }>

            { props.title &&
                <DialogTitle>
                    { props.title }
                </DialogTitle>
            }

            <DialogContent dividers = { props.dividers }>
                { props.content }
            </DialogContent>

            { props.action && 
                <DialogActions> 
                    { props.action }
                </DialogActions>
            }
        </Dialog>
    )
}

CustomDialog.propTypes = {
    open:       PropTypes.bool,
    dividers:       PropTypes.bool,
    onClose:    PropTypes.func,
    fullWidth:  PropTypes.bool,
    title:      PropTypes.string,
    content:    PropTypes.node,
    action:     PropTypes.node,
}
CustomDialog.defaultProps = {
    open:       false,
    dividers:   true,
    fullWidth:  true,
}

export default CustomDialog;