import React from 'react';
import { AppBar, Toolbar, IconButton, Hidden, Avatar, CircularProgress } from '@material-ui/core';
import { MdMenu } from 'react-icons/md';
import { IoMdLogOut } from 'react-icons/all';
import PropTypes from 'prop-types';


const PrimaryAppbar = React.forwardRef((props, ref) => {

    return (
        <AppBar className="app-bar-light" style={{ direction: 'ltr' }}>
            <Toolbar>
                <Hidden smUp>
                    <IconButton color="secondary" onClick = { props.onClickMenu }>
                        <MdMenu />
                    </IconButton>
                </Hidden>
                <div style = {{ flex: 1, verticalAlign: 'center', alignItem: 'center' }} >
                    <Hidden smUp>
                        <h3 className="appbar-logo">WSS</h3>
                    </Hidden>
                    <Hidden xsDown>
                        <h3 className="appbar-logo">Weigh Station Support</h3>
                    </Hidden>
                </div>
                <div className="appbar-action-btns-wrapper">
                    <Avatar 
                        className="appbar-user-img" 
                        onClick = { props.onClickUserImg }
                        src={ props.avatar } 
                    />
                    <IconButton color="secondary" onClick = { props.onClickLogout }>
                        { props.logoutProgress === true ? <CircularProgress size={24} color="secondary" /> : <IoMdLogOut />} 
                    </IconButton>
                </div>
            </Toolbar>
        </AppBar>
    )
})

PrimaryAppbar.defaultProps = {
    onClickMenu:    () => console.log('Not configured'),
    onClickLogout:  () => console.log('Not configured'),
    onClickUserImg: () => console.log('Not configured'),
}

PrimaryAppbar.propTypes = {
    onClickMenu:    PropTypes.func,
    onClickLogout:  PropTypes.func,
    onClickUserImg: PropTypes.func,
    logoutProgress: PropTypes.bool,
}

export default PrimaryAppbar;