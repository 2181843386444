import React from 'react';
import { connect } from 'react-redux';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { Heading } from '../components'; 

import { fetchCompanies, emptyCompanyList } from '../../../../redux/company/company-actions';
import { fetchPackages, emptyPackageList } from '../../../../redux/packages/packages-actions';
import { updateStation, setSnackMsg } from '../../../../redux/station/station-actions';
import { PrimaryInput } from '../../../../components/inputs';
import { PrimaryButton } from '../../../../components/buttons';
import { PrimarySnackbar } from '../../../../components/messages';
import { endPoints } from '../../../../lib/values';
import Utility from '../../../../lib/utility';


class NewStation extends React.Component {
    

    state = {
        inputs: {},
    }

    componentDidMount(){
        this.fetchPackages();
        this.fetchOmc();
    }

    componentWillUnmount(){
        this.props.emptyCompanyList();
        this.props.emptyPackageList();
    }


    fetchPackages = () => {
        const url = `${endPoints.package.listAll}`;
        this.props.fetchPackages(url, Utility.httpRequest('GET'), (err, jsonData) => {
            // do something here....
        })
    }

    fetchOmc = () => {
        this.props.fetchOmc(`${endPoints.company.listAll}/2`, Utility.httpRequest('GET'), () => {
            // do something here...
        })
    }

    onSave = () => {
        this.props.updateStation(endPoints.station.update, Utility.httpRequest('POST', this.state.inputs), (err, resp) => {
            !err && this.props.onSuccess('New Station has been added successfully!')
        })
        
    }

    render(){
        const { inputs } = this.state;
        const { packageReducer, companyReducer, stationReducer  } = this.props;
        return (
            <>
                <PrimarySnackbar 
                    open    = { Boolean(stationReducer.snackMsg) }
                    message = { stationReducer.snackMsg }
                    onClose = { () => this.props.setSnackMsg(null) }
                />

                <Heading 
                    headingFirst = "New Station" 
                    headingSecond = "Weigh stations are physical stations where support request generated"
                />

                <PrimaryInput
                    inputValue          =  { inputs.name || "" }
                    inputfullWidth      = { true }
                    inputLabel          = "Station Name"
                    formControlClasses  = { "light-input-container" }
                    inputPlaceholder    = "Punjab"
                    onChangeInput       = {(e) => this.setState({ inputs: { ...inputs, name: e.target.value }})}
                />

                <div style={{ display: 'flex', flex: 1 }}>
                    <FormControl fullWidth style={{ marginRight: 16 }}>
                        <Select
                            fullWidth
                            variant     = "outlined"
                            style       = {{ backgroundColor: '#f2f2f2', marginBottom: 24 }}
                            value       = { inputs.bound || " " }
                            onChange    = { e => this.setState({ inputs: { ...inputs, bound: e.target.value } }) }
                            >
                            <MenuItem value={" "}>
                                <em>Choose Bound</em>
                            </MenuItem>
                                <MenuItem value = { "North"}>North</MenuItem>  
                                <MenuItem value = { "South"}>South</MenuItem>  
                        </Select>
                    </FormControl>
                    
                    <FormControl fullWidth>
                        <Select
                            fullWidth
                            variant     = "outlined"
                            style       = {{ backgroundColor: '#f2f2f2', marginBottom: 24 }}
                            value       = { inputs.lane || " " }
                            onChange    = { e => this.setState({ inputs: { ...inputs, lane: e.target.value } }) }
                            >
                            <MenuItem value={" "}>
                                <em>Choose Lane</em>
                            </MenuItem>
                                <MenuItem value = {"1"}>1</MenuItem>  
                                <MenuItem value = {"2"}>2</MenuItem>  
                        </Select>
                    </FormControl>
                </div>
                
                <FormControl fullWidth>
                    <Select
                        fullWidth
                        variant     = "outlined"
                        style       = {{ backgroundColor: '#f2f2f2', marginBottom: 24 }}
                        value       = { inputs.sys_installed || " " }
                        onChange    = { e => this.setState({ inputs: { ...inputs, sys_installed: e.target.value } }) }
                        >
                        <MenuItem value={" "}>
                            <em>Choose System Installed</em>
                        </MenuItem>
                            <MenuItem value = {"SSWIM-iSINC (IRD-CANADA)"}>SSWIM-iSINC (IRD-CANADA)</MenuItem>  
                            <MenuItem value = {"DAW-300 (MOBILE)"}>DAW-300 (MOBILE)</MenuItem>  
                            <MenuItem value = {"SSWIM INTERCOMP, USA MADE"}>SSWIM INTERCOMP, USA MADE</MenuItem>  
                            <MenuItem value = {"SSWIM-1068 (IRD-Canda)"}>SSWIM-1068 (IRD-Canda) </MenuItem>  
                            <MenuItem value = {"I-BRIDGE STATIC TUNAYLAR TURKEY"}>I-BRIDGE STATIC TUNAYLAR TURKEY </MenuItem>  
                            <MenuItem value = {"SSWIM (MUGHAL)"}>SSWIM (MUGHAL)</MenuItem>  
                            <MenuItem value = {"STATIC"}>STATIC</MenuItem>  
                            <MenuItem value = {"SSWIM(CHINA)"}>SSWIM(CHINA)</MenuItem>  
                            <MenuItem value = {"DP-400(MOBILE)"}>DP-400(MOBILE)</MenuItem>  
                            <MenuItem value = {"SSWIM-SUPAWEIGH 5000C"}>SSWIM-SUPAWEIGH 5000C</MenuItem>  
                    </Select>
                </FormControl>
                
                <FormControl fullWidth>
                    <Select
                        fullWidth
                        variant     = "outlined"
                        style       = {{ backgroundColor: '#f2f2f2', marginBottom: 24 }}
                        value       = { inputs.package_id || " " }
                        onChange    = { e => this.setState({ inputs: { ...inputs, package_id: e.target.value } }) }
                        >
                        <MenuItem value={" "}>
                            <em>Choose Package</em>
                        </MenuItem>
                        { packageReducer.packages.length > 0 && packageReducer.packages.map((pkg) => 
                            <MenuItem key = { pkg._id } value = { pkg._id }>{ pkg.name }</MenuItem> 
                        )} 
                    </Select>
                </FormControl>

                <FormControl fullWidth>
                    <Select
                        fullWidth
                        variant     = "outlined"
                        style       = {{ backgroundColor: '#f2f2f2', marginBottom: 24 }}
                        value       = { inputs.omc_id || " " }
                        onChange    = { e => this.setState({ inputs: { ...inputs, omc_id: e.target.value } }) }>
                        <MenuItem value={" "}><em>Choose OMC</em></MenuItem>
                        
                        { companyReducer.companies.map(omc =>
                            <MenuItem 
                                key     = { omc._id } 
                                value   = { omc._id }>
                                    { omc.name }
                            </MenuItem> 
                        )}
                        
                    </Select>
                </FormControl>

                { inputs.omc_id && 
                <FormControl fullWidth>
                    <Select
                        fullWidth
                        variant     = "outlined"
                        style       = {{ backgroundColor: '#f2f2f2', marginBottom: 24 }}
                        value       = { inputs.operator || " " }
                        onChange    = { e => this.setState({ inputs: { ...inputs, operator: e.target.value } }) }>
                        <MenuItem value={" "}><em>Choose Supervisor</em></MenuItem>
                        
                        { companyReducer.companies.map(omc =>
                            (omc._id === inputs.omc_id) && omc.players.map(operator =>
                            <MenuItem 
                                key     = { operator._id } 
                                value   = { operator._id }>
                                    { operator.fullname }
                            </MenuItem> 
                            )
                        )}
                        
                    </Select>
                </FormControl>
                }
                 <div className="auth-btns-container">
                    <PrimaryButton
                        buttonText      = "Start Again"
                        buttonVariant   = "outlined"
                        buttonColor     = "secondary"
                        onClick         = { this.props.onStartAgain }
                    />
                    <PrimaryButton
                        buttonText      = "Save"
                        inProgress      = { stationReducer.updateLoader }
                        buttonDisabled  = { stationReducer.updateLoader }
                        onClick         = { this.onSave }
                    />
                </div>
            </>
        )
    }
}

function mapStateToProps(state){
    return {
        stationReducer: state.stationReducer,
        packageReducer: state.packageReducer,
        companyReducer: state.companyReducer,
    }
}

function mapDispatchToProps(dispatch){
    return {
        setSnackMsg:        (msg)           => dispatch(setSnackMsg(msg)),
        emptyCompanyList:   ()              => dispatch(emptyCompanyList()),
        emptyPackageList:   ()              => dispatch(emptyPackageList()),
        fetchOmc:           (url, body, cb) => dispatch(fetchCompanies(url, body, cb)),
        fetchPackages:      (url, body, cb) => dispatch(fetchPackages(url, body, cb)),
        updateStation:      (url, body, cb) => dispatch(updateStation(url, body, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewStation);