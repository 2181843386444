import React from 'react';
import { Grid } from '@material-ui/core';
import { GiWeight, BsFillShieldLockFill, FaUserTie,  ImStack, AiFillTool, SiGoogletagmanager } from 'react-icons/all';
import { segments } from '../new-resource'; 
import { Heading } from '../components'; 


class MainSegment extends React.Component {

    render(){
        return (
            <>
                <Heading headingSecond = "What kind of resouce you want to add?" />
                <Grid container spacing={2}>
                    <Grid item sm={4}>
                        <div className="resource-card-container" onClick = {() => this.props.onClickCard(segments.ws)}>
                            <GiWeight size={48} color="#16A085"  /> 
                            <h3 style={{ color: '#444' }}>Weigh Station</h3>
                        </div>
                    </Grid>
                    <Grid item sm={4}>
                        <div className="resource-card-container" onClick = {() => this.props.onClickCard(segments.pkg)}>
                            <ImStack size={48} color="#AF7AC5"  /> 
                            <h3 style={{ color: '#444' }}>Package</h3>
                        </div>
                    </Grid>
                    <Grid item sm={4}>
                        <div className="resource-card-container" onClick = {() => this.props.onClickCard(segments.tsp)}>
                            <AiFillTool size={48} color="#7B241C"  />
                            <h3 style={{ color: '#444' }}>TSP</h3>
                        </div>
                    </Grid>
                    <Grid item sm={4}>
                        <div className="resource-card-container" onClick = {() => this.props.onClickCard(segments.omc)}>
                            <SiGoogletagmanager size={48} color="#EDBB99"  />
                            <h3 style={{ color: '#444' }}>OMC</h3>
                        </div>
                    </Grid>
                    <Grid item sm={4}>
                        <div className="resource-card-container" onClick = {() => this.props.onClickCard(segments.director)}>
                            <FaUserTie size={48} color="#21618C"  />
                            <h3 style={{ color: '#444' }}>Director</h3>
                        </div>
                    </Grid>
                    <Grid item sm={4}>
                        <div className="resource-card-container" onClick = {() => this.props.onClickCard(segments.admin)}>
                            <BsFillShieldLockFill size={48} color="#2ECC71"  />
                            <h3 style={{ color: '#444' }}>Admin</h3>
                        </div>
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default MainSegment;