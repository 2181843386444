import React from 'react';
import { connect } from 'react-redux';
import { Grid, Avatar, List, ListItem, ListItemText, CircularProgress } from '@material-ui/core';
import { Pie } from 'react-chartjs-2';
import { GiWeight, BsFillShieldLockFill, BiSupport, ImStack, AiFillTool, SiGoogletagmanager, MdAdd, } from 'react-icons/all';
import Utility from '../../../../lib/utility';
import { setContentView } from '../../../../redux/dom/dom-actions';
import { contentViews, endPoints } from '../../../../lib/values';

const reqCountData = {
    labels: ['Resolved', 'Un-Resolved'],
    datasets: [
      {
        label: '# of Votes',
        data: [],
        backgroundColor: [
          'rgba(34, 139, 34, 0.8)',
          '#cc0000',
        ],
        borderColor: [
          'rgba(34, 139, 34, 1)',
          '#cc0000',
        ],
        borderWidth: 1,
      },
    ],
  };


class HigherDashboard extends React.Component {

    state = {
        requests:       [],
        activities:     [],
        counts:         {},
        reqLoader:      false,
        activityLoader: false,
        countLoader:    false,
        reqCountLoader: false,
        reqCountData:   reqCountData
    }

    componentDidMount = () => {
        setTimeout(() => {
            this.countAll();
            this.reqCount();
            this.fetchRequest();
            this.fetchActivities();
        }, 100)
        
    }

    fetchRequest = () => {
        const url = `${endPoints.request.listAll}?limit=5`;
        this.setState({ reqLoader: true })
        Utility._fetch(url, Utility.httpRequest('GET'), (err, jsonRes) => {
            if(!err){
                this.setState({ reqLoader: false, requests: jsonRes });
            }
        })
    }

    fetchActivities = () => {
        const url = `${endPoints.activity.listAll}?limit=5`;
        this.setState({ activityLoader: true })
        Utility._fetch(url, Utility.httpRequest('GET'), (err, jsonRes) => {
            if(!err){
                this.setState({ activityLoader: false, activities: jsonRes });
            }
        })
    }
    
    countAll = () => {
        const url = `${endPoints.report.countAll}`;
        this.setState({ countLoader: true })
        Utility._fetch(url, Utility.httpRequest('GET'), (err, jsonRes) => {
            if(!err){
                this.setState({ countLoader: false, counts: jsonRes });
            }
        })
    }
    
    reqCount = () => {
        let { reqCountData } = this.state;
        const url = `${endPoints.report.requestCount}`;
        this.setState({ reqCountLoader: true })
        Utility._fetch(url, Utility.httpRequest('GET'), (err, jsonRes) => {
            if(!err){
                console.log(jsonRes);
                reqCountData.datasets[0].data[0] = jsonRes.resolved;
                reqCountData.datasets[0].data[1] = jsonRes.unresolved;
                this.setState({ reqCountLoader: false, reqCountData });
            }
        })
    }
    
    
    render(){
        const { authReducer } = this.props;
        const { reqLoader, requests, activities, activityLoader, countLoader, counts, reqCountLoader, reqCountData } = this.state;

        return (
            <div className="fixed-content-width" style={{ margin: 'unset' }}>

            { countLoader
            ?
                <div className="single-elm-container">
                    <CircularProgress color="secondary" />
                </div>
            : 
            <Grid container spacing={2}>
            
                <Grid item xs={12} sm={6} md={3}>
                    <div className="large-box">
                        <Avatar style={{ width: 120, height: 120, marginBottom: 16 }} src={ authReducer.user.image } />
                        <h3 style={{ margin: 0, padding: 0, fontFamily: 'sans-serif', color: '#333' }}>Hi, { authReducer.user.fullname.split(' ')[0] }</h3>
                        <h4 style={{ margin: 0, padding: 0, fontFamily: 'sans-serif', color: '#666' }}>{ Utility.UserRoleToStr(authReducer.user.role)}</h4>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Grid item>
                        <div className="top-box-container" onClick = { () => this.props.setContentView(contentViews.requests) }>
                            <div className="box-left-container" style={{ backgroundColor: '#16A085' }}>
                            <BiSupport size={32} color="#fff"  />
                            </div>
                            <div className="box-right-container">
                                <h3 style={{ margin: 0, padding: 0, fontFamily: 'sans-serif', color: '#16A085' }}>{ counts.supportRequests }</h3>
                                <h4 style={{ margin: 0, padding: 0, fontFamily: 'sans-serif', color: '#16A085' }}>Support Requests</h4>
                            </div>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className="top-box-container" onClick = { () => this.props.setContentView(contentViews.packages) }>
                            <div className="box-left-container" style={{ backgroundColor: '#AF7AC5' }}>
                                <ImStack  size={32} color="#fff"  />
                            </div>
                            <div className="box-right-container">
                                <h3 style={{ margin: 0, padding: 0, fontFamily: 'sans-serif', color: '#AF7AC5' }}>{ counts.packages }</h3>
                                <h4 style={{ margin: 0, padding: 0, fontFamily: 'sans-serif', color: '#AF7AC5' }}>Packages</h4>
                            </div>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className="top-box-container" onClick = { () => this.props.setContentView(contentViews.omc) }>
                            <div className="box-left-container" style={{ backgroundColor: '#EDBB99' }}>
                                <SiGoogletagmanager  size={32} color="#fff"  />
                            </div>
                            <div className="box-right-container">
                                <h3 style={{ margin: 0, padding: 0, fontFamily: 'sans-serif', color: '#EDBB99' }}>{ counts.omc }</h3>
                                <h4 style={{ margin: 0, padding: 0, fontFamily: 'sans-serif', color: '#EDBB99' }}>OMCs</h4>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                
                
                <Grid item xs={12} sm={6} md={3}>
                    <Grid>
                        <div className="top-box-container" onClick = { () => this.props.setContentView(contentViews.stations) }>
                            <div className="box-left-container" style={{ backgroundColor: '#9A7D0A' }}>
                                <GiWeight  size={32} color="#fff"  />
                            </div>
                            <div className="box-right-container">
                                <h3 style={{ margin: 0, padding: 0, fontFamily: 'sans-serif', color: '#9A7D0A' }}>{ counts.stations }</h3>
                                <h4 style={{ margin: 0, padding: 0, fontFamily: 'sans-serif', color: '#9A7D0A' }}>Weigh Stations</h4>
                            </div>
                        </div>
                        
                    </Grid>
                    <Grid>
                        <div className="top-box-container" onClick = { () => this.props.setContentView(contentViews.tsp) }>
                            <div className="box-left-container" style={{ backgroundColor: '#7B241C' }}>
                                <AiFillTool  size={32} color="#fff"  />
                            </div>
                            <div className="box-right-container">
                                <h3 style={{ margin: 0, padding: 0, fontFamily: 'sans-serif', color: '#7B241C' }}>{ counts.tsp }</h3>
                                <h4 style={{ margin: 0, padding: 0, fontFamily: 'sans-serif', color: '#7B241C' }}>TSPs</h4>
                            </div>
                        </div>
                        
                    </Grid>
                    <Grid item>
                        <div className="top-box-container" onClick = { () => this.props.setContentView(contentViews.admins) }>
                            <div className="box-left-container" style={{ backgroundColor: '#2ECC71' }}>
                                <BsFillShieldLockFill size={32} color="#fff"  />
                            </div>
                            <div className="box-right-container">
                                <h3 style={{ margin: 0, padding: 0, fontFamily: 'sans-serif', color: '#2ECC71' }}>{ counts.admins }</h3>
                                <h4 style={{ margin: 0, padding: 0, fontFamily: 'sans-serif', color: '#2ECC71' }}>Admins</h4>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <div className="large-box large-box-anim" style={{ cursor: 'pointer' }}  onClick = { () => this.props.setContentView(contentViews.newResource) }>
                        <MdAdd color="#cc0000" className="heart-beat-anim" />
                        <h3 style={{ margin: 0, padding: 0, fontFamily: 'sans-serif', color: '#333' }}>Add a New Resource</h3>
                    </div>
                </Grid>
            </Grid>
            }

            <Grid container  spacing={2}>
                <Grid item xs={12} sm={4} md={6} >
                    <h4>Latest Support Request</h4>
                    <List>
                        { reqLoader ?
                        <div className="single-elm-container">
                            <CircularProgress color="secondary" />
                        </div>
                        : 
                        requests.map(req => 
                            <ListItem button divider key = { req._id }>
                                <ListItemText primary={ req.title } secondary={ req.station && `${req.station.name} ${req.station.bound} ${req.station.lane}` } />
                            </ListItem>
                        )}
                    </List>
                </Grid>
                <Grid item xs={12} sm={4} md={3} >
                    <h4>Support Request</h4>
                    { reqCountLoader ?
                        <div className="single-elm-container">
                            <CircularProgress color="secondary" />
                        </div>
                        : <Pie data = { reqCountData } />
                    }
                </Grid>
                <Grid item xs={12} sm={4} md={3} >
                    <h4>Latest Activities</h4>
                    <List>
                        { activityLoader ?
                        <div className="single-elm-container">
                            <CircularProgress color="secondary" />
                        </div>
                        : 
                        activities.map(activity => 
                            <ListItem button divider key = { activity._id }>
                                <ListItemText primary={ activity.text }/>
                            </ListItem>
                        )}
                    </List>
                </Grid>
            </Grid>
            </div>
        )
    }
}

function mapStateToProps(state){
    return {
        authReducer: state.authReducer
    }
}
function mapDispatchToProps(dispatch){
    return {
        setContentView: (view) => dispatch(setContentView(view))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HigherDashboard);