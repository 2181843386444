import COMPANY_CONST from './company-const';
import Utility from '../../lib/utility';
import action from '../action-generator';


export const setSnackMsg        = (err)      => action(COMPANY_CONST.SET_SNACK_MSG, err);
export const emptyCompanyList   = ()         => action(COMPANY_CONST.EMPTY_COMPANY_LIST, null);
export const setSelectedCompany = (company)  => action(COMPANY_CONST.SET_SELECTED_COMPANY, company);

/*****************************************************************************************
 *              async actions
******************************************************************************************/
export const fetchCompanies     = (url, body, cb) => asyncAction(url, body, cb, 'fetchCompanies');
export const newCompany         = (url, body, cb) => asyncAction(url, body, cb, 'newCompany');
export const delCompany         = (url, body, cb) => asyncAction(url, body, cb, 'delCompany');
export const addManager         = (url, body, cb) => asyncAction(url, body, cb, 'addManager');
export const updatePlayer       = (url, body, cb) => asyncAction(url, body, cb, 'updatePlayer');

/****************************************************************************************
 *              Helper method for async actions
 ****************************************************************************************/
const asyncAction = (url, body, cb, actionType) => {
    let startAction = null, successAction = null, failAction = null;
    switch(actionType){
        case 'fetchCompanies': {
            startAction     = COMPANY_CONST.FETCH_COMPANY_START;
            successAction   = COMPANY_CONST.FETCH_COMPANY_SUCCESS;
            failAction      = COMPANY_CONST.FETCH_COMPANY_FAIL;
            break;
        }
        case 'newCompany': {
            startAction     = COMPANY_CONST.ADD_COMPANY_START;
            successAction   = COMPANY_CONST.ADD_COMPANY_SUCCESS;
            failAction      = COMPANY_CONST.ADD_COMPANY_FAIL;
            break;
        }
        case 'delCompany': {
            startAction     = COMPANY_CONST.DEL_COMPANY_START;
            successAction   = COMPANY_CONST.DEL_COMPANY_SUCCESS;
            failAction      = COMPANY_CONST.DEL_COMPANY_FAIL;
            break;
        }
        case 'addManager':
        case 'updatePlayer': {
            startAction     = COMPANY_CONST.UPDATE_PLAYER_START;
            successAction   = COMPANY_CONST.UPDATE_PLAYER_SUCCESS;
            failAction      = COMPANY_CONST.UPDATE_PLAYER_FAIL;
            break;
        }
        
        default: {
            startAction     = COMPANY_CONST.FETCH_COMPANY_START;
            successAction   = COMPANY_CONST.FETCH_COMPANY_SUCCESS;
            failAction      = COMPANY_CONST.FETCH_COMPANY_FAIL;
            break;
        }
    }
    return (dispatch) => {
        dispatch(action(startAction, null));
        Utility._fetch(url, body, (err, success) => {
            if(err){
                dispatch(action(failAction, err));
                cb && cb(err, null);
            } else {
                dispatch(action(successAction, success));
                cb && cb(null, success);
            }
        })
    }
}