import React from 'react';
import { connect } from 'react-redux';
import { MainSegment, NewAdmin, NewDirector, NewPackage, NewTSP, NewOMC, NewStation, Success } from './segments';
import { GiWeight, BsFillShieldLockFill, FaUserTie,  ImStack, AiFillTool, SiGoogletagmanager } from 'react-icons/all';

import { setContentView } from '../../../redux/dom/dom-actions';
import { contentViews } from '../../../lib/values';

export const segments = {
    main:       'main',
    ws:         'ws',
    pkg:        'pkg',
    tsp:        'tsp',
    omc:        'omc',
    director:   'director',
    admin:      'admin',
}

class NewResource extends React.Component {

    state = {
        current_segment: segments.main,
        success_msg: null,
    }

    onSuccessViewList = () => {
        const { current_segment } = this.state;
        switch(current_segment){
            case segments.ws:       return this.props.setContentView(contentViews.stations);
            case segments.pkg:      return this.props.setContentView(contentViews.packages);
            case segments.tsp:      return this.props.setContentView(contentViews.tsp);
            case segments.omc:      return this.props.setContentView(contentViews.omc);
            case segments.director: return this.props.setContentView(contentViews.directors);
            case segments.admin:    return this.props.setContentView(contentViews.admins);
            default: return null;
        }
    }

    success_icon = () => { 
        const { current_segment } = this.state;
        switch(current_segment){
            case segments.ws:       return <GiWeight             size = { 48 } color = "#16A085" style = {{ marginBottom: 16 }} /> ;
            case segments.pkg:      return <ImStack              size = { 48 } color = "#AF7AC5" style = {{ marginBottom: 16 }}  />;
            case segments.tsp:      return <AiFillTool           size = { 48 } color = "#7B241C" style = {{ marginBottom: 16 }}  />;
            case segments.omc:      return <SiGoogletagmanager   size = { 48 } color = "#EDBB99" style = {{ marginBottom: 16 }}  />;
            case segments.director: return <FaUserTie            size = { 48 } color = "#21618C" style = {{ marginBottom: 16 }}  />;
            case segments.admin:    return <BsFillShieldLockFill size = { 48 } color = "#2ECC71" style = {{ marginBottom: 16 }}  />;
            default:                return null;
        }
    }

    renderSegment = () => {
       const { current_segment } = this.state; 
       switch(current_segment){
            case segments.main: 
                return (
                    <MainSegment 
                        onClickCard = { current_segment => this.setState({ current_segment }) }
                    />
                )
            case segments.admin: 
                return (
                    <NewAdmin 
                        onStartAgain = { () => this.setState({ current_segment: segments.main }) } 
                        onSuccess    = { success_msg => this.setState({ success_msg }) }
                    />
                )
            case segments.director: 
                return (
                    <NewDirector
                        onStartAgain = { () => this.setState({ current_segment: segments.main }) } 
                        onSuccess    = { success_msg => this.setState({ success_msg }) }
                    />
                )
            case segments.pkg: 
                return (
                    <NewPackage 
                        onStartAgain = { () => this.setState({ current_segment: segments.main }) } 
                        onSuccess    = { success_msg => this.setState({ success_msg }) }
                    />
                )
            case segments.tsp: 
                return (
                    <NewTSP 
                        onStartAgain = { () => this.setState({ current_segment: segments.main }) } 
                        onSuccess    = { success_msg => this.setState({ success_msg }) }
                    />
                )
            case segments.omc: 
                return (
                    <NewOMC 
                        onStartAgain = { () => this.setState({ current_segment: segments.main }) } 
                        onSuccess    = { success_msg => this.setState({ success_msg }) }
                    />
                )
            case segments.ws: 
                return (
                    <NewStation 
                        onStartAgain = { () => this.setState({ current_segment: segments.main }) } 
                        onSuccess    = { success_msg => this.setState({ success_msg }) }
                    />
                )
            default:
                return <MainSegment onClickCard = { current_segment => this.setState({ current_segment }) } />
       }
    }
    
    render(){
        const { success_msg, current_segment } = this.state;
        return (
            <div className="resource-container">
                { success_msg 
                ? 
                <Success 
                    success_msg         = { success_msg }
                    success_icon        = { this.success_icon() }
                    current_segment     = { current_segment }
                    onStartAgain        = { () => this.setState({ success_msg: null, current_segment: segments.main })  }
                    onSuccessViewList   = { this.onSuccessViewList  }
                /> 
                : 
                this.renderSegment() 
                }
            </div>
        )
    }
}

function mapStateToProp(state){
    return {
        domReducer: state.dom,
    }
}

function mapDispatchToProps( dispatch ){
    return {
        setContentView: (view) => dispatch(setContentView(view))
    }
}

export default connect(mapStateToProp, mapDispatchToProps)(NewResource);

