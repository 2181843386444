import React, { Component } from 'react';
import { IconButton, CircularProgress, Switch, FormControlLabel, Collapse, Grid, 
    InputAdornment, List, ListItem, ListItemText, ListSubheader, ListItemIcon, ListItemSecondaryAction } from '@material-ui/core';
import { connect } from 'react-redux';
import { MdPhotoCamera, MdVisibility, MdVisibilityOff, MdDesktopWindows, } from 'react-icons/md';
import { FaMobileAlt, } from 'react-icons/fa';
import { IoMdLogOut } from 'react-icons/io';


import { PrimaryButton } from '../../../components/buttons';
import { PrimaryContainer } from '../../../components/container';
import { PrimarySnackbar } from '../../../components/messages';
import { PrimaryInput } from '../../../components/inputs';
import { setUserImg, uploadUserImg, updateProfile, setLoginUser, setSnackMsg } from '../../../redux/auth/auth-actions';
import Utility from '../../../lib/utility';
import { endPoints } from '../../../lib/values';

class Profile extends Component {

    constructor(props){
        super(props);
        this.state = {
            showPassArea:       false,
            visiblePassword:    false,
            inputs:             props.authReducer.user,
            devices:            null,
            device4Action:      null,
            deviceLoader:       false,
        }
    }

    componentDidMount(){
       this.fetchDevices()
    }

    fetchDevices = () => {
        this.setState({ deviceLoader: true, devices: null })
        Utility._fetch(endPoints.auth.listDevices, Utility.httpRequest('GET'), (err, devices) => {
            this.setState({ deviceLoader: false })
            if(err) return this.props.setSnackMsg(err);
            this.setState({ devices }, () => console.log(this.state))
        })
    }

    logout = (device4Action) => {
        let { devices } = this.state;
        this.setState({ deviceLoader: true, device4Action });
        const url = `${endPoints.auth.logout}?device=${device4Action}`
        Utility._fetch(url, Utility.httpRequest('GET'), (err, success) => {
            this.setState({ deviceLoader: false, device4Action: null })
            if(err) return this.setSnackMsg(err);
            
            if(device4Action === 'all'){
                devices.other_devices = [];
                this.setState({ devices });
            } else {
                devices.other_devices.forEach((d, i) => {
                    if(d._id === device4Action) devices.other_devices.splice(i, 1);
                })
                this.setState({ devices });
            }
        })
    }
    
    onChangeImage = (e) => {
        let fd      = new FormData();
        const file  = this.photoChooser.files[0];
        
        fd.append('file', file);
        this.props.uploadUserImg(endPoints.auth.updateImage, fd, (err, updatedUser) => {
            if(updatedUser){
                this.props.setUserImg(updatedUser.image + '?' + Date.now());
            }
        })
    } 

    onUpdateProfile = () => {
        const { inputs, showPassArea } = this.state;
        let reqBody = {
            fullname:           inputs.fullname,
            new_password:       showPassArea === false ? undefined : inputs.new_password,
            current_password:   showPassArea === false ? undefined : inputs.current_password,
        }
        this.props.updateProfile(endPoints.auth.updateProfile, Utility.httpRequest("POST", reqBody), (err, user) => {
            if(user){
                this.props.setLoginUser(user);
                this.props.setSnackMsg('Profile updated');
            }
        })
    }

    renderAdornment = () => {
        const { visiblePassword } = this.state;
        return (
            <InputAdornment position="end">
                <IconButton 
                    onClick = { () => this.setState({ visiblePassword: !visiblePassword }) } 
                    title   = { !visiblePassword ? "Show Password" : "Hide Password" } >
                    { !visiblePassword 
                        ? <MdVisibility /> 
                        : <MdVisibilityOff /> 
                    } 
                </IconButton>
            </InputAdornment>
        )
    }

    renderUserImage = () => {
        const { authReducer } = this.props;
        return (
            <div className="user-img-wrapper">
                <div className="image-container">
                    {!authReducer.userImgUploading && 
                        <img 
                            alt  = { "user thumbnail" } 
                            src = { authReducer.user.image }
                            className = "user-img"
                        /> 
                    }
                    <div className="img-btn">
                        { !authReducer.userImgUploading && 
                            <IconButton 
                                color       = { "secondary" } 
                                className   = { "profile-add-img-btn" } 
                                onClick     = {() => this.photoChooser.click() } >
                                <MdPhotoCamera size={26} />
                            </IconButton>
                        }
                        <input 
                            type     = "file" 
                            style    = {{display: 'none'}} 
                            accept   = "image/*" 
                            onChange = { this.onChangeImage } 
                            ref      = { ref => this.photoChooser = ref }
                        />
                    </div>
                    { authReducer.userImgUploading && 
                    <div className="uploader">
                        <CircularProgress />
                    </div>
                    }
                </div>
            </div>
        )
    }

    renderCollapsedContent = () => {
        const { authReducer } = this.props;
        const { inputs } = this.state;
        return (
            <>
                <Collapse 
                    in = { this.state.showPassArea } >
                    <PrimaryInput 
                        inputLabel          = { "Current Password" }
                        inputType           = { !this.state.visiblePassword ? 'password' : 'text' }
                        formControlClasses  = { "light-input-container" } 
                        inputValue          = { inputs.current_password }
                        onChangeInput       = {(e) => this.setState({ inputs: { ...inputs, current_password: e.target.value } })}
                    />
                    <PrimaryInput 
                        inputLabel          = { "New Password" }
                        inputType           = { !this.state.visiblePassword ? 'password' : 'text' }
                        endAdornment        = { this.renderAdornment() }
                        formControlClasses  = { "light-input-container" } 
                        inputValue          = { inputs.new_password }
                        onChangeInput       = {(e) => this.setState({ inputs: { ...inputs, new_password: e.target.value } })}
                    />
                </Collapse> 
                
                <Collapse 
                    in = { !this.state.showPassArea } >
                    <PrimaryInput 
                        inputLabel          = { "Mobile" }
                        formControlClasses  = { "light-input-container" } 
                        inputValue          = { inputs.mobile }
                        inputDisabled       = { true }
                    />
                    <PrimaryInput 
                        inputLabel          = { "Designation" }
                        formControlClasses  = { "light-input-container" } 
                        inputValue          = { Utility.UserRoleToStr(authReducer.user.role) }
                        inputDisabled       = { true }
                    />
                </Collapse>
            </>
        )
    }

    render(){
        const { authReducer } = this.props;
        const { inputs } = this.state;
        return(
            <PrimaryContainer>
                <PrimarySnackbar 
                    open    = { Boolean(authReducer.snackMsg) }
                    message = { authReducer.snackMsg }
                    onClose = { () => this.props.setSnackMsg(null) }
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        
                    { this.renderUserImage() }

                    <div className="pr-input-wrapper">
                        <PrimaryInput 
                             inputLabel          = { "Full Name" }
                             formControlClasses  = { "light-input-container" } 
                             inputValue          = { inputs.fullname }
                             onChangeInput       = {(e) => this.setState({ inputs: { ...inputs, fullname: e.target.value } })}
                        />

                        <div style={{ marginBottom: 24 }}>
                            <FormControlLabel
                                control = {
                                <Switch
                                    color    = "secondary"
                                    checked  = { this.state.showPassArea }
                                    onChange = {(e, val) => this.setState({showPassArea: val})}
                                />
                                }
                                label ="Change Password"
                                style = {{color: '#888'}}
                            />
                        </div>
                        
                        { this.renderCollapsedContent() }

                        <div className="update-btn-container">
                            <PrimaryButton 
                                buttonVariant  = "contained"
                                buttonText     = "Update"
                                inProgress     = { authReducer.profileUpdating }
                                buttonDisabled = { authReducer.profileUpdating }
                                onClick        = { this.onUpdateProfile }
                            />
                        </div>
                    </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="user-img-wrapper">
                            <h2 style={{ margin: 0 }}>My Devices</h2>
                            <p style={{ color: '#888' }}>
                                Lis of all devices that you used to get access your acocunt. 
                                You can manage your devices from here. 
                            </p>
                        </div>
                        { (!this.state.devices && this.state.deviceLoader) && <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div> }
                        { (this.state.devices) &&
                        <List>
                            <ListSubheader>This Device</ListSubheader>
                            <ListItem style={{ backgroundColor: '#f9f9f9' }} divider>
                                <ListItemIcon>
                                    {this.state.devices.this_device.device_type === 'desktop' ? <MdDesktopWindows size={32} color="#1976d2" /> : <FaMobileAlt size={32} color="#cc0000" />}     
                                </ListItemIcon>
                                <ListItemText style={{ margin: 0 }} 
                                    primary = { `${this.state.devices.this_device.client_name} ${this.state.devices.this_device.client_version}`  }
                                    secondary={
                                    <>
                                    <span>{ `${this.state.devices.this_device.device_type} ${this.state.devices.this_device.device_brand} (${this.state.devices.this_device.os_name} ${this.state.devices.this_device.os_version})`  }</span><br />
                                    <span><b>Since:</b> { Utility.formatFullDate(this.state.devices.this_device.added) }</span>
                                    </>
                                }> </ListItemText>
                            </ListItem>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16, marginBottom: 8 }}>
                        <ListSubheader>Other Devices </ListSubheader>
                        <PrimaryButton  
                            buttonSize      = "small"
                            buttonText      = "Logout All"
                            buttonVariant   = "outlined"
                            buttonColor     = "secondary"
                            buttonDisabled  = { Boolean(this.state.device4Action === 'all') }
                            inProgress      = { Boolean(this.state.device4Action === 'all') }
                            onClick         = { () => this.logout('all') }
                        />
                        </div>
                        { this.state.devices.other_devices.map((device) => 
                        <ListItem style={{ backgroundColor: '#f9f9f9' }} divider key={device._id}>
                            <ListItemIcon>
                                {device.device_type === 'desktop' ? <MdDesktopWindows size={32} color="#1976d2" /> : <FaMobileAlt size={32} color="#359C1B" />}
                            </ListItemIcon>
                            <ListItemText style={{ margin: 0 }} 
                                primary = { `${device.client_name} ${device.client_version}`  }
                                secondary={
                                <>
                                <span>{ `${device.device_type} ${device.device_brand} (${device.os_name} ${device.os_version})`  }</span><br />
                                <span><b>Since:</b> { Utility.formatFullDate(device.added) }</span>
                                </>
                            }> </ListItemText>
                            <ListItemSecondaryAction>
                                <IconButton
                                    disabled    = { Boolean(device._id === this.state.device4Action) }
                                    onClick     = { () => this.logout(device._id) }
                                    color       = "secondary">
                                        { (device._id === this.state.device4Action) ? <CircularProgress /> : <IoMdLogOut /> }
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>

                        )}
                        
                        </List>
                        }
                        
                    </Grid>
                </Grid>
            </PrimaryContainer>
        )
    }
}

function mapStateToProps(state){
    return {
        authReducer: state.authReducer,
    }
}

function mapDispatchToProps(dispatch){
    return {
        setSnackMsg:    (msg)               =>  dispatch(setSnackMsg(msg)),
        setLoginUser:   (user)              => dispatch(setLoginUser(user)),
        setUserImg:     (imageUrl)          => dispatch(setUserImg(imageUrl)),
        updateProfile:  (url, body, cb)     => dispatch(updateProfile(url, body, cb)),
        uploadUserImg:  (url, formData, cb) => dispatch(uploadUserImg(url, formData, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);