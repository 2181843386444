import AUTH_CONST from './auth-constants';
import Utility from '../../lib/utility';
import action from '../action-generator';
import { storageKeys } from '../../lib/values';


export const setLoginUser    = (user)       => action(AUTH_CONST.SET_LOGIN_USER, user)
export const setUserImg      = (imgUrl)     => action(AUTH_CONST.SET_USER_IMG, imgUrl)
export const setAppLoading   = (appLoading) => action(AUTH_CONST.SET_APP_LOADING, appLoading)
export const setSnackMsg     = (err)        => action(AUTH_CONST.SET_SNACK, err)
export const setSessionError = (error)      => action(AUTH_CONST.SET_SESSION_ERR, error)

/*****************************************************************************************
 *              async actions
 ****************************************************************************************/
export const authorizing     = (url, body, cb) => asyncAction(url, body, cb, 'authorizing');
export const loadingProfile  = (url, body, cb) => asyncAction(url, body, cb, 'loadingProfile');
export const updateProfile   = (url, body, cb) => asyncAction(url, body, cb, 'updateProfile');
export const genAuthCode     = (url, body, cb) => asyncAction(url, body, cb, 'genAuthCode');
export const resetPassword   = (url, body, cb) => asyncAction(url, body, cb, 'resetPassword');

/*****************************************************************************************
 *              Helper method for async actions
 *****************************************************************************************/
const asyncAction = (url, body, cb, actionType) => {
    let startAction = null, successAction = null, failAction = null;
    switch(actionType){
        case 'authorizing': {
            startAction     = AUTH_CONST.AUTH_START;
            successAction   = AUTH_CONST.AUTH_SUCCESS;
            failAction      = AUTH_CONST.AUTH_FAIL;
            break;
        }
        case 'loadingProfile': {
            startAction     = AUTH_CONST.FETCH_PROFILE_START;
            successAction   = AUTH_CONST.FETCH_PROFILE_SUCCESS;
            failAction      = AUTH_CONST.FETCH_PROFILE_FAIL;
            break;
        }
        case 'updateProfile': {
            startAction     = AUTH_CONST.PROFILE_UPDATE_START;
            successAction   = AUTH_CONST.PROFILE_UPDATE_SUCCESS;
            failAction      = AUTH_CONST.PROFILE_UPDATE_FAIL;
            break;
        }
        case 'genAuthCode': {
            startAction     = AUTH_CONST.GEN_AUTH_CODE_START;
            successAction   = AUTH_CONST.GEN_AUTH_CODE_SUCCESS;
            failAction      = AUTH_CONST.GEN_AUTH_CODE_FAIL;
            break;
        }
        case 'resetPassword': {
            startAction     = AUTH_CONST.FORGOT_PASS_START;
            successAction   = AUTH_CONST.FORGOT_PASS_SUCCESS;
            failAction      = AUTH_CONST.FORGOT_PASS_FAIL;
            break;
        }
        
        default: {
            startAction     = AUTH_CONST.FETCH_PROFILE_START;
            successAction   = AUTH_CONST.FETCH_PROFILE_SUCCESS;
            failAction      = AUTH_CONST.FETCH_PROFILE_FAIL;
            break;
        }
    }
    return (dispatch) => {
        dispatch(action(startAction, null));
        Utility._fetch(url, body, (err, success) => {
            if(err){
                dispatch(action(failAction, err));
                cb && cb(err, null);
            } else {
                dispatch(action(successAction, success));
                cb && cb(null, success);
            }
        })
    }
}

/*****************************************************************************************
 *              user image uplaoding action
 *****************************************************************************************/
export const uploadUserImg = (url, formData, cb) => {
    return (dispatch) => {
        dispatch(action(AUTH_CONST.USER_IMG_UPLOAD_START, null));
        Utility.uploadFile(url, formData, null, (err, success) => {
            if(err){
                dispatch(action(AUTH_CONST.USER_IMG_UPLOAD_FAIL, err));
                cb && cb(err, null);
            } else {
                dispatch(action(AUTH_CONST.USER_IMG_UPLOAD_SUCCESS, success));
                cb && cb(null, success);
            }
        })  
    }    
}

/*****************************************************************************************
 *              logout action
 *****************************************************************************************/
export const logout = () => {
    localStorage.removeItem(storageKeys.appTokken);
    return action(AUTH_CONST.LOGOUT, null);
}