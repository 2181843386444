import USER_CONST from './users-constants';

var initial_state = {
    users:              [],
    selectedUser:       null,
    fetchLoader:        false,
    addLoader:          false,
    updateLoader:       false,
    snackMsg:           null,
}

export const usersReducer = (state = initial_state, action) => {
    switch(action.type){

        ///////////////////////////////////////////////////////////////////////////////////////
        case USER_CONST.SET_SNACK: {
            return {
                ...state,
                snackMsg: action.payload
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        case USER_CONST.EMPTY_USER_LIST: {
            return {
                ...state,
                users: []
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        case USER_CONST.SET_SELECTED_USER: {
            return {
                ...state,
                selectedUser: action.payload
            }
        }

    ///////////////////////////////////////////////////////////////////////////////////////
        case USER_CONST.FETCH_USERS_START: {
            return {
                ...state, 
                fetchLoader: true,
                snackMsg: null
            }
        }

        case USER_CONST.FETCH_USERS_SUCCESS: {
            return {
                ...state, 
                fetchLoader: false,
                users: [ ...state.users,  ...action.payload ],
            }
        }

        case USER_CONST.FETCH_USERS_FAIL: {
            return {
                ...state, 
                fetchLoader: false,
                snackMsg: action.payload // payload is the snackMsg
            }
        }

///////////////////////////////////////////////////////////////////////////////////////
        case USER_CONST.ADD_USER_START: {
            return {
                ...state,
                addLoader: true,
                snackMsg: null,
            }
        }
        case USER_CONST.ADD_USER_SUCCESS: {
            return {
                ...state,
                addLoader: false,
                
            }
        }
        case USER_CONST.ADD_USER_FAIL: {
            return {
                ...state,
                addLoader: false,
                snackMsg: action.payload // payload is the snackMsg
            }
        }

///////////////////////////////////////////////////////////////////////////////////////
        case USER_CONST.USER_UPDATE_START: {
            return {
                ...state,
                updateLoader: true, 
                snackMsg: null,
            }
        }
        case USER_CONST.USER_UPDATE_SUCCESS: {
            return {
                ...state,
                updateLoader: false ,
                
            }
        }
        case USER_CONST.USER_UPDATE_FAIL: {
            return {
                ...state,
                updateLoader: false ,
                snackMsg: action.payload // payload is the snackMsg
            }
        }

        default: return state;
    }
}