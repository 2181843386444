import React from 'react';
import { connect } from 'react-redux';
import { Heading } from '../components'; 
import { PrimaryInput } from '../../../../components/inputs';
import { PrimaryButton } from '../../../../components/buttons';
import { PrimarySnackbar } from '../../../../components/messages';
import { endPoints } from '../../../../lib/values';
import Utility from '../../../../lib/utility';
import { addUser, setSnackMsg } from '../../../../redux/users/users-actions';


class NewDirector extends React.Component {
    
    state = {
        inputs: {}
    }

    onSave = (role) => {
        const { inputs } = this.state;
        const reqBody = {
            role: this.props.role || role,
            ...inputs
        }
        this.props.addUser(endPoints.users.addUser, Utility.httpRequest('POST', reqBody), (err, resp) => {
            if(err === null){
                this.props.onSuccess('New Director has been added successfully!')
            }
        })
    }

    render(){
        const { inputs } = this.state;
        const { usersReducer } = this.props;
        return (
            <>
                <PrimarySnackbar 
                    open    = { Boolean(usersReducer.snackMsg) }
                    message = { usersReducer.snackMsg }
                    onClose = { () => this.props.setSnackMsg(null) }
                />
                
                <Heading 
                    headingFirst = "New Director"
                    headingSecond = "Unlike Admins, Director can view all support request, comments & reports"
                />
                
                <PrimaryInput
                    inputValue          =  { inputs.fullname || "" }
                    inputfullWidth      = { true }
                    inputLabel          = "Fullname"
                    formControlClasses  = { "light-input-container" }
                    inputPlaceholder    = "Abdus Samad"
                    onChangeInput       = {(e) => this.setState({ inputs: { ...inputs, fullname: e.target.value }})}
                />

                <PrimaryInput
                    inputValue          = { inputs.mobile || "" }
                    inputLabel          = "Mobile"
                    formControlClasses  = { "light-input-container" }
                    inputPlaceholder    = "03336999640"
                    inputMaxLength      = { 11 }
                    onChangeInput       = {(e) => this.setState({ inputs: { ...inputs, mobile: e.target.value.replace(/[^0-9]/g, '') }})}
                />
                
                <div className="auth-btns-container">
                    <PrimaryButton
                        buttonText      = "Start Again"
                        buttonVariant   = "outlined"
                        buttonColor     = "secondary"
                        onClick         = { this.props.onStartAgain }
                    />
                    <PrimaryButton
                        buttonText      = "Save"
                        inProgress      = { usersReducer.addLoader }
                        buttonDisabled  = { usersReducer.addLoader }
                        onClick         = { () => this.onSave(5) }
                    />
                </div>
            </>
        )
    }
}

function mapStateToProps(state){
    return {
        usersReducer: state.usersReducer
    }
}

function mapDispatchToProps(dispatch){
    return {
        setSnackMsg:     (msg)           => dispatch(setSnackMsg(msg)),
        addUser:         (url, body, cb) => dispatch(addUser(url, body, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewDirector);