import React from 'react';
import { connect } from 'react-redux';

import { setSnackMsg } from '../../redux/auth/auth-actions';
import { Login, Forgot } from './segments';
import { PrimaryContainer } from '../../components/container';
import { PrimarySnackbar } from '../../components/messages';

const viewSegments = {
    login:  'login',
    forgot: 'forgot'
}

class Auth extends React.Component {

    state = {
        current_segment: viewSegments.login
    }

    renderLogo = () => {
        return (
            <div className = "auth-logo-container">
                <img 
                    alt = "WSS" 
                    src = "/assets/images/logo.png" 
                />
                <h2>Weigh Station</h2>
            </div>
        )
    }


    renderAuthSegment = () => {
        switch(this.state.current_segment){
            case viewSegments.login: 
                return (
                    <Login 
                        onForgotClick   = { () => this.setState({ current_segment: viewSegments.forgot }) } 
                    />
                )
            
            case viewSegments.forgot: 
                return (
                    <Forgot 
                        onLoginClick        = { () => this.setState({ current_segment: viewSegments.login }) } 
                    />
                )
            default: 
            return (
                <Login 
                    onForgotClick       = { () => this.setState({current_segment: viewSegments.forgot}) } 
                />
            )
        }
    }

    render(){
        const { authReducer } = this.props;
        return (
            <PrimaryContainer>
                <PrimarySnackbar 
                    open    = { Boolean(authReducer.snackMsg) }
                    message = { authReducer.snackMsg }
                    onClose = {() => this.props.setSnackMsg(null) }
                />

                <div className="center-flex auth-bg full-height" >
                    <div className="auth-form-container">
                        { this.renderLogo() }
                        { this.renderAuthSegment() }
                    </div>
                </div>
            </PrimaryContainer>
        )
    }
}

function mapStateToProps(state){
    return {
        authReducer: state.authReducer
    }
}

function mapDispatchToProps(dispatch){
    return {
        setSnackMsg: (msg) => dispatch(setSnackMsg(msg)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);