import CHAT_CONST from './chat-constants';

var initial_state = {
    chatRoom:           null,
    fetchLoader:        false,
    sendLoader:         false,
    snackMsg:           null,
}

export const chatReducer = (state = initial_state, action) => {
    switch(action.type){

        //////////////////////////////////////////////////////////////////////////////////
        case CHAT_CONST.SET_SNACK: {
            return {
                ...state,
                snackMsg: action.payload
            }
        }

        //////////////////////////////////////////////////////////////////////////////////
        case CHAT_CONST.SET_SELECTED_CHATROOM: {
            return {
                ...state,
                chatRoom: action.payload
            }
        }
        
        //////////////////////////////////////////////////////////////////////////////////
        case CHAT_CONST.FETCH_MESSAGE_START: {
            return {
                ...state,
                fetchLoader: true,
                snackMsg: null,
            }
        }
        case CHAT_CONST.FETCH_MESSAGE_SUCCESS: {
            return {
                ...state,
                fetchLoader: false,
                chatRoom: action.payload
            }
        }
        case CHAT_CONST.FETCH_MESSAGE_FAIL: {
            return {
                ...state,
                fetchLoader: false,
                snackMsg: action.payload,
            }
        }
        
        //////////////////////////////////////////////////////////////////////////////////
        case CHAT_CONST.SEND_MESSAGE_START: {
            return {
                ...state,
                sendLoader: true,
                snackMsg: null,
            }
        }
        case CHAT_CONST.SEND_MESSAGE_SUCCESS: {
            return {
                ...state,
                sendLoader: false,
                chatRoom: action.payload
            }
        }
        case CHAT_CONST.SEND_MESSAGE_FAIL: {
            return {
                ...state,
                sendLoader: false,
                snackMsg: action.payload,
            }
        }

        default: return state;
    }
}