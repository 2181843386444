import React from 'react';
import { Table, TableBody, TableRow, TableCell, } from '@material-ui/core';
import Utility from '../../../../lib/utility';

const StationDetail = (props) => {
    return (
        <>
            <Table>
                <TableBody>
                <TableRow>
                    <TableCell className="tb-head">Name</TableCell>
                    <TableCell>{props.selectedStation.name}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tb-head">Bound</TableCell>
                    <TableCell>{props.selectedStation.bound}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tb-head">Lane</TableCell>
                    <TableCell>{props.selectedStation.lane}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tb-head">System</TableCell>
                    <TableCell>{props.selectedStation.sys_installed}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tb-head">Package</TableCell>
                    <TableCell>{ props.selectedStation.package && props.selectedStation.package.name }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tb-head">Project Manager</TableCell>
                    <TableCell>
                        { 
                            props.selectedStation.package && 
                            props.selectedStation.package.manager &&  
                            props.selectedStation.package.manager.fullname 
                        }
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tb-head">TSP</TableCell>
                    <TableCell>{ props.selectedStation.tsp && props.selectedStation.tsp.name }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tb-head">OMC</TableCell>
                    <TableCell>{ props.selectedStation.omc && props.selectedStation.omc.name }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tb-head">Supervisor</TableCell>
                    <TableCell>{ props.selectedStation.operator && props.selectedStation.operator.fullname }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tb-head">Created</TableCell>
                    <TableCell>{ Utility.formatFullDate(props.selectedStation.created) }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tb-head" style={{ border: 'none' }}>Created By</TableCell>
                    <TableCell style={{ border: 'none' }}>{props.selectedStation.created_by && props.selectedStation.created_by.fullname}</TableCell>
                </TableRow>
                </TableBody>
            </Table> 
        </>
    )
}

export default StationDetail;