import React from 'react';
import moment from 'moment';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Utility from '../../../../lib/utility';

class RequestTimer extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            created_time: props.created_time,
            expiry_time: props.expiry_time,
            expired: props.expiry_time > moment().valueOf() ? false : true,
            duration: moment.duration((props.expiry_time > moment().valueOf() ? props.expiry_time - moment().valueOf() : moment().valueOf() - props.expiry_time), 'milliseconds'),
        }
    }

    componentDidMount(){
        this.intervalHandler = setInterval(() => {
            if(!this.state.expired && this.state.duration.asSeconds() > 0){
                this.setState({ duration: moment.duration( this.state.duration - 1000,  'milliseconds') })
            } else {
                this.setState({ duration: moment.duration(moment().valueOf() - this.state.expiry_time, 'milliseconds' ) })
            }
            if(this.state.duration.asSeconds() < 0){
                return this.setState({ expired: true })
             }
        }, 1000)
    }

    componentWillUnmount(){
        clearInterval(this.intervalHandler);
    }

    renderRemainingTime =  () => {
        let t = "";
        const { duration } = this.state;
        t += duration.hours() < 10 ? `0${duration.hours()}:` : duration.hours() + ':';
        t += duration.minutes() < 10 ? `0${duration.minutes()}:` : duration.minutes() + ':';
        t += duration.seconds() < 10 ? `0${duration.seconds()}` : duration.seconds();
        return t;
    }

    renderTabularData = (duration) => {
        return (duration.days() > 0 || duration.months() > 0 || duration.years() > 0) ? true : false;
    }



    render(){
        const { duration } = this.state;
        return (
            <>
                <div className="content-title-container">
                    <span className="content-title-text">{ this.state.expired ? "Exceeded Time" : "Remaining Time" }</span>
                </div>
                <div style={{ display: 'flex',  alignItems: 'center', flexDirection: 'column', marginBottom: 16 }}>
                    { !this.renderTabularData(duration) && <h1 style={{ margin: 0, color: this.state.expired ? '#cc0000' : '#444' }}>{ this.renderRemainingTime() }</h1> }
                    { this.renderTabularData(duration) && 
                    <Table style={{ color: 'red' }}>
                        <TableHead>
                            <TableRow>
                                { duration.years() > 0 && <TableCell className="tb-head tb-center-data">Years</TableCell> }
                                { (duration.years() > 0 || duration.months() > 0) && <TableCell className="tb-head tb-center-data">Months</TableCell> }
                                { (duration.years() > 0 ||  duration.months() > 0 || duration.days() > 0) && <TableCell className="tb-head tb-center-data">Days</TableCell> }
                                <TableCell className="tb-head tb-center-data">Hours</TableCell>
                                <TableCell className="tb-head tb-center-data">Minutes</TableCell>
                                <TableCell className="tb-head tb-center-data">Seconds</TableCell>
                            </TableRow>
                        </TableHead>
                            <TableBody>
                                <TableRow>
                                    { duration.years() > 0 && <TableCell className="red-table-data tb-center-data">{ duration.years() }</TableCell> }
                                    { (duration.years() > 0 || duration.months() > 0) && <TableCell className="red-table-data tb-center-data">{ duration.months() }</TableCell> }
                                    { (duration.years() > 0 || duration.months() > 0 || duration.days() > 0) && <TableCell className="red-table-data tb-center-data">{ duration.days() }</TableCell> }
                                    <TableCell className="red-table-data tb-center-data">{ duration.hours() }</TableCell>
                                    <TableCell className="red-table-data tb-center-data">{ duration.minutes() }</TableCell>
                                    <TableCell className="red-table-data tb-center-data">{ duration.seconds() }</TableCell>
                                </TableRow>
                            </TableBody>
                    </Table>
                     }
                    {/* <h4 style={{ margin: 0, marginTop: 4, color: '#888' }}>{ moment(this.state.expiry_time).format('DD MMM YYYY - hh:mm A') }</h4> */}
                    <h4 style={{ margin: 0, marginTop: 8, color: this.state.expired ? '#cc0000' : '#444' }}>Max Duration</h4>
                    <strong  style={{ margin: 0, marginTop: 4, color: this.state.expired ? '#cc0000' : '#444' }}>{ Utility.formatFullDate(this.state.expiry_time) }</strong>
                    <small style={{ color: '#888' }}>Request should resolve within duration period</small>
                </div>
            </>
        )
    }
}

export default RequestTimer;