import React, { Component } from 'react';
import { Button, IconButton, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Grid } from '@material-ui/core';
import { IoMdArrowDropright, MdDelete } from 'react-icons/all';
import { connect } from 'react-redux';

import { setSnackMsg, emptyCompanyList, setSelectedCompany, updatePlayer, fetchCompanies, delCompany } from '../../../redux/company/company-actions';
import { updateUser } from '../../../redux/users/users-actions';
import { PrimaryContainer } from '../../../components/container';
import { PrimaryInput } from '../../../components/inputs';
import { CustomDialog } from '../../../components/dialog';
import { UserCard } from '../../../components/card';
import { PrimarySnackbar } from '../../../components/messages';
import { PrimaryButton } from '../../../components/buttons';
import Utility from '../../../lib/utility';
import { endPoints } from '../../../lib/values';

class OMC extends Component {


    state = {
        omcForAction:       null,
        userForAction:      null,
        editDialog:         false,
        inputs:             {},
    }

    componentDidMount(){
        this.fetchOmc();
    }

    componentWillUnmount(){
        this.props.emptyOmcList();
        this.props.setSelectedOmc(null);
    }

    updateUser = () => {
        this.props.updateUser(endPoints.users.update, Utility.httpRequest('POST', this.state.inputs), (err, jsonRes) => {
            // do something here...
            this.setState({ inputs: {}, userForAction: null, editDialog: false })
            this.props.setSnackMsg("Password changed successfully");
        })
    }

    suspendUser(){
        const { companyReducer } = this.props;
        const { userForAction } = this.state;
        let tempPlayers = [];

        const reqBody = {
            userid:     userForAction._id,
            suspension: true,
        }
        this.props.updateUser(endPoints.users.suspension, Utility.httpRequest('POST', reqBody), (err, resp) => {
            
            if(!err) {
                companyReducer.selectedCompany.players.forEach(player => {
                    if(player._id === userForAction._id) player.suspended = true;
                    tempPlayers.push(player)
                })

                this.setState({ userForAction: null });
                this.fetchOmc()
                this.props.setSelectedOmc({ ...companyReducer.selectedCompany, players: tempPlayers })
            }
        })
    }

    unSuspendUser(){
        const { companyReducer } = this.props;
        const { userForAction } = this.state;
        let tempPlayers = [];

        const reqBody = {
            userid:     this.state.userForAction._id,
            suspension: false,
        }
        this.props.updateUser(endPoints.users.suspension, Utility.httpRequest('POST', reqBody), (err, resp) => {
            
            if(!err) {
                companyReducer.selectedCompany.players.forEach(player => {
                    if(player._id === userForAction._id) player.suspended = false;
                    tempPlayers.push(player)
                })

                this.setState({ userForAction: null });
                this.fetchOmc()
                this.props.setSelectedOmc({ ...companyReducer.selectedCompany, players: tempPlayers })
            }
        })
    }

    fetchOmc = () => {
        this.props.fetchOmc(`${endPoints.company.listAll}/2`, Utility.httpRequest('GET'), (err, jsonData) => {
            // do something here...
        })
    }

    delOmc = () => {
        const reqBody = {
            company_id: this.state.omcForAction._id
        }
        this.props.delOmc(endPoints.company.delete, Utility.httpRequest('DELETE', reqBody), (err, jsonData) => {
            this.setState({ omcForAction: null })
            if(!err) {
                this.props.emptyOmcList();
                this.fetchOmc();
            }
        })
    }

    deletePlayer = () => {
        const { userForAction } = this.state;
        this.props.updatePlayer(endPoints.company.deletePlayer, Utility.httpRequest('DELETE', { player_id: userForAction._id }), (err, jsonRes) => {
            this.setState({ userForAction: null });
            if(!err) {
                this.props.setSelectedOmc(jsonRes);
                this.fetchOmc();
            }
        })
    }

    renderOmcList = () => {
        const { companyReducer } = this.props;
        const { omcForAction } = this.state;
        return companyReducer.companies.length === 0 
            ? <div style={{ textAlign: 'center', padding: 24, color: '#888' }}>No Data Found!</div>
            :
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className="tb-head">Name    </TableCell>
                        <TableCell className="tb-head tb-center-data">Created   </TableCell>
                        <TableCell className="tb-head">Created By    </TableCell>
                        <TableCell className="tb-head">Supervisors </TableCell>
                        <TableCell className="tb-head tb-center-data">List </TableCell>
                        <TableCell className="tb-head">Delete  </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { companyReducer.companies.map((omc) => 
                    <TableRow key={omc._id}>
                        <TableCell>{ omc.name  }</TableCell>
                        <TableCell className="tb-center-data">
                            { Utility.formatFullDate(omc.created) } <br/> 
                            <span style={{ color: '#888' }}>{ Utility.timeAgoFormat(omc.created) } </span>
                        </TableCell>
                        <TableCell>
                            { omc.created_by && omc.created_by.fullname } <br/> 
                        </TableCell>
                        <TableCell>
                            { omc.players.length }
                        </TableCell>
                        <TableCell className="tb-center-data">
                            <PrimaryButton 
                                buttonVariant = "outlined"
                                buttonText    = "Supervisors"
                                buttonColor   = "secondary"
                                buttonSize    = "small"
                                onClick       = { () => this.props.setSelectedOmc(omc) }
                            />
                        </TableCell>
                        <TableCell>
                            <IconButton 
                                color       = { "secondary" }
                                onClick     = { () => this.setState({ omcForAction: omc }, this.delOmc)}
                                disabled    = { Boolean(companyReducer.delLoader && omcForAction && omcForAction._id === omc._id) }>
                                { (companyReducer.delLoader && omcForAction &&  omcForAction._id === omc._id) ? <CircularProgress size={24} color = "secondary" /> : <MdDelete /> }
                            </IconButton>
                        </TableCell>
                    </TableRow>
                    )}
                </TableBody>
            </Table>
    }

    renderSupervisors = () => {
        const { companyReducer, usersReducer } = this.props;
        const { userForAction } = this.state;
        return (
            <>
            <Grid container spacing={6}>
                { companyReducer.selectedCompany.players.map((omc) => 
                <Grid 
                    item 
                    xs = { 12 } 
                    sm = { 6 } 
                    md = { 4 } 
                    lg = { 3 } 
                    key = { omc._id }>
                    <UserCard 
                        user = { omc }
                        inProgress      = { Boolean((companyReducer.playerLoader || usersReducer.updateLoader) && userForAction && userForAction._id === omc._id) }
                        deleteUser      = { () => this.setState({ userForAction: omc }, this.deletePlayer)}
                        suspendUser     = { () => this.setState({ userForAction: omc }, () => omc.suspended ? this.unSuspendUser() : this.suspendUser() )}
                        resetPassword   = { () => this.setState({ userForAction: omc, inputs: omc, editDialog: true }) }
                    />
                </Grid>
                )}
            </Grid>
            </>
        )
    }

    renderDialogContent = () => {
        const { inputs } = this.state;
        return (
            <>
                <PrimaryInput
                    inputValue          = { inputs.fullname || "" }
                    inputfullWidth      = { true }
                    inputLabel          = { "Fullname"}
                    formControlClasses  = { "light-input-container" }
                    inputPlaceholder    = { "Khurram"}
                    inputDisabled       = { true }
                />
                <PrimaryInput
                    inputValue          = { inputs.password || "" }
                    inputfullWidth      = { true }
                    inputLabel          = { "Password" }
                    inputType           = { "password" }
                    formControlClasses  = { "light-input-container" }
                    inputPlaceholder    = { "Set Password" }
                    onChangeInput       = { e => this.setState({ inputs: { ...inputs, password: e.target.value }})}
                />
            </>
        )
    }

    renderAction = () => {
        const { usersReducer } = this.props;
        return (
            <PrimaryButton
                buttonText      = { "Update" }
                inProgress      = { usersReducer.updateLoader }
                buttonDisabled  = { usersReducer.updateLoader }
                onClick         = { this.updateUser }
            />
        )
    }

    render(){
        const { companyReducer } = this.props;
        const { editDialog } = this.state;
        
        return(
            <PrimaryContainer>
                <div style={{ borderBottom: '1px solid #eee', display: 'flex', alignItems: 'center', flexDirection: 'row' }} >
                    <Button>
                        { companyReducer.selectedCompany ? companyReducer.selectedCompany.name : 'All OMC' }
                    </Button>
                    <IconButton disabled> <IoMdArrowDropright /> </IconButton>
                    { companyReducer.selectedCompany && <Button disabled>Supervisors</Button> }
                    { companyReducer.selectedCompany && 
                    <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                        <PrimaryButton 
                            buttonText  = { "Go Back To OMC"}
                            buttonSize  = { "small"}
                            onClick     = { () => this.props.setSelectedOmc(null) }
                        />
                    </div>
                    }
                </div>

                <PrimarySnackbar 
                    open    = { Boolean(companyReducer.snackMsg)  }
                    message = { companyReducer.snackMsg }
                    onClose = { () => this.props.setSnackMsg()}
                />

                <CustomDialog 
                    open    = { editDialog }
                    title   = { "Reset Password"}
                    content = { this.renderDialogContent()  } 
                    action  = { this.renderAction() }
                    onClose = { () => this.setState({ editDialog: false })}
                />
            { companyReducer.fetchLoader && !companyReducer.selectedCompany 
            ? 
            <div className="single-elm-container">
                <CircularProgress color="secondary" />
            </div>
            : 
            <div className="fixed-content-width">
                    { companyReducer.selectedCompany 
                        ? this.renderSupervisors() 
                        : this.renderOmcList() 
                    }
                </div>
            }
            </PrimaryContainer>
        )
    }
}

function mapStateToProps(state){
    return {
        companyReducer: state.companyReducer,
        usersReducer:   state.usersReducer,
    }
}

function mapDispatchToProps(dispatch){
    return {
        setSnackMsg:    (msg)           => dispatch(setSnackMsg(msg)),
        emptyOmcList:   ()              => dispatch(emptyCompanyList()),
        setSelectedOmc: (omc)           => dispatch(setSelectedCompany(omc)),
        fetchOmc:       (url, body, cb) => dispatch(fetchCompanies(url, body, cb)),
        delOmc:         (url, body, cb) => dispatch(delCompany(url, body, cb)),
        updateUser:     (url, body, cb) => dispatch(updateUser(url, body, cb)),
        updatePlayer:   (url, body, cb) => dispatch(updatePlayer(url, body, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OMC);