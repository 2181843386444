const USER_CONST = {
    SET_SNACK:              'SET_SNACK',
    EMPTY_USER_LIST:        'EMPTY_USER_LIST',
    SET_SELECTED_USER:      'SET_SELECTED_USER',

    FETCH_USERS_START:      'FETCH_USERS_START',
    FETCH_USERS_SUCCESS:    'FETCH_USERS_SUCCESS',
    FETCH_USERS_FAIL:       'FETCH_USERS_FAIL',

    USER_UPDATE_START:      'USER_UPDATE_START',
    USER_UPDATE_SUCCESS:    'USER_UPDATE_SUCCESS',
    USER_UPDATE_FAIL:       'USER_UPDATE_FAIL',

    ADD_USER_START:         'ADD_USER_START',
    ADD_USER_SUCCESS:       'ADD_USER_SUCCESS',
    ADD_USER_FAIL:          'ADD_USER_FAIL',
}

export default USER_CONST;