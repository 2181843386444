import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';


const PrimaryInput = (props) => {
    return (
        <FormControl 
            className   =   { props.formControlClasses } 
            fullWidth   =   { props.formFullWidth }
            style       =   { props.formControlStyle }>
                        
            <TextField 
                value       =   { props.inputValue }
                label       =   { props.inputLabel }
                variant     =   { props.inputVariant }
                placeholder =   { props.inputPlaceholder }
                fullWidth   =   { props.inputfullWidth }
                type        =   { props.inputType  }
                multiline   =   { props.inputMultiline  }
                rows        =   { props.inputRows  }
                rowsMax     =   { props.inputMaxRows }
                classes     =   { { root: 'light-input' } }
                style       =   { props.inputStyle }
                inputProps  =   { { maxLength: props.inputMaxLength } } // style to html input
                onChange    =   { props.onChangeInput }
                disabled    =   { props.inputDisabled }
                InputProps  =   { { classes: { notchedOutline: "light-input-outlined" }, endAdornment: props.endAdornment }} // style to MUI Input Compoent
            />
            { props.helperText &&
            <FormHelperText 
                error       = { props.helperTextHasError } 
                variant     = { props.helperTextVariant }>
                { props.helperText }
            </FormHelperText> 
            }
        </FormControl>
    )
}

PrimaryInput.propTypes = {
    formControlClasses:     PropTypes.string,
    formFullWidth:          PropTypes.bool,
    inputLabel:             PropTypes.string,
    formControlStyle:       PropTypes.object,
    inputVariant:           PropTypes.oneOf(['standard', 'outlined', 'filled']),
    inputPlaceholder:       PropTypes.string,
    inputfullWidth:         PropTypes.bool,
    inputDisabled:          PropTypes.bool,
    inputMultiline:         PropTypes.bool,
    inputRows:              PropTypes.number,
    inputMaxRows:           PropTypes.number,
    inputType:              PropTypes.string,
    inputStyle:             PropTypes.object,
    inputValue:             PropTypes.string,
    inputMaxLength:         PropTypes.number,
    onChangeInput:          PropTypes.func,
    helperTextHasError:     PropTypes.bool,
    helperTextVariant:      PropTypes.oneOf(['standard', 'outlined', 'filled']),
    helperText:             PropTypes.string,
    endAdornment:           PropTypes.node
    
}

PrimaryInput.defaultProps = {
    formControlClasses:     '',
    formControlStyle:       {},
    inputVariant:           'outlined',
    inputfullWidth:         true,
    inputMultiline:         false,
    inputType:              'text',
    inputStyle:             {},
    helperTextHasError:     false,
    helperTextVariant:      'standard',
    onChangeInput:          (e) => console.log(e.target.value)
}

export default PrimaryInput;