import React, { Component } from 'react';
import { Button, IconButton, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Menu, MenuItem } from '@material-ui/core';
import { IoMdArrowDropright, MdMoreVert } from 'react-icons/all';
import { connect } from 'react-redux';
import { setSnackMsg, fetchStations, emptyStationList, setSelectedStation, deleteStation } from '../../../redux/station/station-actions';

import { PrimaryContainer } from '../../../components/container';
import { PrimarySnackbar } from '../../../components/messages';
import { PrimaryButton } from '../../../components/buttons';
import { CustomDialog } from '../../../components/dialog';
import { UpdateStation } from './segments';
import { StationDetail } from './components';
import Utility from '../../../lib/utility';
import { endPoints } from '../../../lib/values';

class Stations extends Component {

    state = {
        anchorEl:           null,
        editDialog:         false,
        stationForAction:   null,
        stationDialog:      false,
    }

    closeMenu   = () => this.setState({ anchorEl: null, stationForAction: null })
    showMenu    = (e, station)  => this.setState({ anchorEl: e.currentTarget, stationForAction: station })

    componentDidMount(){
        this.fetchStations();
    }

    componentWillUnmount(){
        this.props.emptyStationList();
    }

    fetchStations = () => {
        const url = `${endPoints.station.listAll}`;
        this.props.fetchStations(url, Utility.httpRequest('GET'), (err, jsonData) => {
            // do something here....
        })
    }

    onClickDetail = () => {
        this.props.setSelectedStation(this.state.stationForAction);
        this.setState({ stationDialog: true, anchorEl: null })
    }

    onClickEdit = () => {
        this.props.setSelectedStation(this.state.stationForAction);
        this.setState({ editDialog: true, anchorEl: null })
    }
    
    onClickDelete = () => {
        this.setState({ anchorEl: null });
        this.deleteStation();
    }

    deleteStation = () => {
        const reqBody = { station_id: this.state.stationForAction._id }
        this.props.deleteStation(endPoints.station.deleteStation, Utility.httpRequest("DELETE", reqBody), (err, jsonRes) => {
            if(!err){
                this.setState({ stationForAction: null })
                this.props.emptyStationList();
                this.fetchStations();
            }
        })
    }

    renderStations = () => {
        const { stationReducer, authReducer } = this.props;
        const { stationForAction } = this.state;
        return stationReducer.fetchLoader
            ? 
            <div className="single-elm-container">
                <CircularProgress color="secondary" />
            </div>
            : stationReducer.stations.length === 0 
            ? <div style={{ textAlign: 'center', padding: 24, color: '#888' }}>No Data Found!</div>
            :
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className="tb-head">Name     </TableCell>
                        <TableCell className="tb-head">Bound    </TableCell>
                        <TableCell className="tb-head">Lane     </TableCell>
                        <TableCell className="tb-head">System   </TableCell>
                        <TableCell className="tb-head">Package  </TableCell>
                        <TableCell className="tb-head">TSP      </TableCell>
                        <TableCell className="tb-head">OMC      </TableCell>
                        { authReducer.user.role === 1 && 
                        <TableCell className="tb-head">Actions  </TableCell> }
                    </TableRow>
                </TableHead>
                <TableBody>
                    { stationReducer.stations.map((station) => 
                    <TableRow key={station._id}>
                        <TableCell>{ station.name  }            </TableCell>
                        <TableCell>{ station.bound  }           </TableCell>
                        <TableCell>{ station.lane  }            </TableCell>
                        <TableCell>{ station.sys_installed  }   </TableCell>
                        <TableCell>{ station.package && station.package.name   }</TableCell>
                        <TableCell>{ station.tsp && station.tsp.name }</TableCell>
                        <TableCell>{ station.omc && station.omc.name }</TableCell>
                        { authReducer.user.role === 1 && 
                        <TableCell>
                            <IconButton 
                                color       = { "secondary" }
                                disabled    = { Boolean(stationForAction && stationForAction._id === station._id && stationReducer.updateLoader) } 
                                onClick     = { e => { this.showMenu(e, station) } }
                                >
                                { stationForAction && stationForAction._id === station._id && stationReducer.updateLoader
                                ? <CircularProgress color="secondary" size={24} />
                                : <MdMoreVert />
                                }
                            </IconButton>
                        </TableCell>
                        }
                    </TableRow>
                    )}
                </TableBody>
            </Table>
    }

    render(){
        const { stationReducer } = this.props;
        const { anchorEl, editDialog, stationDialog } = this.state;
        return(
            <PrimaryContainer>

                <PrimarySnackbar 
                    open    = { Boolean(!this.state.editDialog && stationReducer.snackMsg) }
                    message = { stationReducer.snackMsg }
                    onClose = { () => this.props.setSnackMsg(null) }
                />

                <UpdateStation 
                    showDialog  =  { editDialog }
                    onClose = { () => this.setState({ editDialog: false }) }
                />
                
                <div style={{ borderBottom: '1px solid #eee' }} >
                    <Button>Weigh Stations</Button>
                    <IconButton disabled><IoMdArrowDropright /></IconButton>
                </div>

                <Menu
                    open        = { Boolean(anchorEl)}
                    anchorEl    = { anchorEl }
                    onClose     = { this.closeMenu }>
                    <MenuItem onClick = { this.onClickDetail }> Details</MenuItem>
                    <MenuItem onClick = { this.onClickEdit }>   Edit Station</MenuItem>
                    <MenuItem onClick = { this.onClickDelete }> Delete Station</MenuItem>
                </Menu>

                {  stationReducer.selectedStation && 
                    <CustomDialog 
                        open    = { stationDialog }
                        title   = { "Stataion Detail" }
                        content = { <StationDetail selectedStation = { stationReducer.selectedStation }  /> }
                        action  = { <PrimaryButton buttonText ="Dismiss" onClick ={ () => this.setState({ stationDialog: false }) } /> }
                        onClose = { () => this.setState({ stationDialog: false }, () => this.props.setSelectedStation(null)) }
                    />
                }

                { this.renderStations() }

            </PrimaryContainer>
        )
    }
}

function mapStateToProps(state){
    return {
        stationReducer: state.stationReducer,
        authReducer:    state.authReducer,
    }
}

function mapDispatchToProps(dispatch){
    return {
        setSnackMsg:        (msg)           => dispatch(setSnackMsg(msg)),
        emptyStationList:   ()              => dispatch(emptyStationList()),
        setSelectedStation: (station)       => dispatch(setSelectedStation(station)),
        fetchStations:      (url, body, cb) => dispatch(fetchStations(url, body, cb)),
        deleteStation:      (url, body, cb) => dispatch(deleteStation(url, body, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stations);