import React, { Component } from 'react';
import { Button, IconButton, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Grid, Avatar } from '@material-ui/core';
import { IoMdArrowDropright, MdDelete } from 'react-icons/all';
import { connect } from 'react-redux';

import { setSnackMsg, emptyCompanyList, setSelectedCompany, updatePlayer, fetchCompanies, delCompany } from '../../../redux/company/company-actions';
import { fetchPackages, emptyPackageList } from '../../../redux/packages/packages-actions';
import { updateUser } from '../../../redux/users/users-actions';
import { PrimaryContainer } from '../../../components/container';
import { PrimaryInput } from '../../../components/inputs';
import { CustomDialog } from '../../../components/dialog';
import { UserCard } from '../../../components/card';
import { PrimarySnackbar } from '../../../components/messages';
import { PrimaryButton } from '../../../components/buttons';
import Utility from '../../../lib/utility';
import { endPoints } from '../../../lib/values';

class TSP extends Component {


    state = {
        tspForAction:       null,
        userForAction:      null,
        editDialog:         false,
        managerDialog:      false,
        inputs:             {},
    }

    componentDidMount(){
        this.fetchTsp();
    }

    componentWillUnmount(){
        this.props.emptyTspList();
        this.props.setSelectedTsp(null);
        this.props.emptyPkgList()
    }

    fetchPackages = () => {
        const { tspForAction } = this.state;
        const url = `${endPoints.package.filter}?tsp=${ tspForAction._id }`;
        this.props.fetchPackages(url, Utility.httpRequest('GET'), (err, jsonRes) => {
            // do something here...
        })
    }

    updateUser = () => {
        this.props.updateUser(endPoints.users.update, Utility.httpRequest('POST', this.state.inputs), (err, jsonRes) => {
            // do something here...
            this.setState({ inputs: {}, userForAction: null, editDialog: false })
            this.props.setSnackMsg("Password changed successfully");
        })
    }

    suspendUser(){
        const { companyReducer } = this.props;
        const { userForAction } = this.state;
        let tempPlayers = [];

        const reqBody = {
            userid:     userForAction._id,
            suspension: true,
        }
        this.props.updateUser(endPoints.users.suspension, Utility.httpRequest('POST', reqBody), (err, resp) => {
            
            if(!err) {
                companyReducer.selectedCompany.players.forEach(player => {
                    if(player._id === userForAction._id) player.suspended = true;
                    tempPlayers.push(player)
                })

                this.setState({ userForAction: null });
                this.fetchTsp()
                this.props.setSelectedTsp({ ...companyReducer.selectedCompany, players: tempPlayers })
            }
        })
    }

    unSuspendUser(){
        const { companyReducer } = this.props;
        const { userForAction } = this.state;
        let tempPlayers = [];

        const reqBody = {
            userid:     this.state.userForAction._id,
            suspension: false,
        }
        this.props.updateUser(endPoints.users.suspension, Utility.httpRequest('POST', reqBody), (err, resp) => {
            
            if(!err) {
                companyReducer.selectedCompany.players.forEach(player => {
                    if(player._id === userForAction._id) player.suspended = false;
                    tempPlayers.push(player)
                })

                this.setState({ userForAction: null });
                this.fetchTsp()
                this.props.setSelectedTsp({ ...companyReducer.selectedCompany, players: tempPlayers })
            }
        })
    }

    fetchTsp = () => {
        this.props.fetchTsp(`${endPoints.company.listAll}/1`, Utility.httpRequest('GET'), (err, jsonData) => {
            // do something here...
        })
    }

    delTsp = () => {
        const reqBody = {
            company_id: this.state.tspForAction._id
        }
        this.props.delTsp(endPoints.company.delete, Utility.httpRequest('DELETE', reqBody), (err, jsonData) => {
            this.setState({ tspForAction: null })
            if(!err) {
                this.props.emptyTspList();
                this.fetchTsp();
            }
        })
    }

    deletePlayer = () => {
        const { userForAction } = this.state;
        this.props.updatePlayer(endPoints.company.deletePlayer, Utility.httpRequest('DELETE', { player_id: userForAction._id }), (err, jsonRes) => {
            this.setState({ userForAction: null });
            if(!err) {
                this.props.setSelectedTsp(jsonRes);
                this.fetchTsp();
            }
        })
    }

    renderTspList = () => {
        const { companyReducer } = this.props;
        const { tspForAction } = this.state;
        return companyReducer.companies.length === 0 
            ? <div style={{ textAlign: 'center', padding: 24, color: '#888' }}>No Data Found!</div>
            :
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className="tb-head">Name    </TableCell>
                        <TableCell className="tb-head tb-center-data">Created   </TableCell>
                        <TableCell className="tb-head">Created By    </TableCell>
                        <TableCell className="tb-head">Tech Officers </TableCell>
                        <TableCell className="tb-head tb-center-data">List </TableCell>
                        <TableCell className="tb-head">Project Manager </TableCell>
                        <TableCell className="tb-head">Delete  </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { companyReducer.companies.map((tsp) => 
                    <TableRow key={tsp._id}>
                        <TableCell>{ tsp.name  }</TableCell>
                        <TableCell className="tb-center-data">
                            { Utility.formatFullDate(tsp.created) } <br/> 
                            <span style={{ color: '#888' }}>{ Utility.timeAgoFormat(tsp.created) } </span>
                        </TableCell>
                        <TableCell>
                            { tsp.created_by && tsp.created_by.fullname }
                        </TableCell>
                        <TableCell>
                            { tsp.players.length }
                        </TableCell>
                        <TableCell className="tb-center-data">
                            <PrimaryButton 
                                buttonVariant = "outlined"
                                buttonText    = "Tech Officers"
                                buttonColor   = "secondary"
                                buttonSize    = "small"
                                onClick       = { () => this.props.setSelectedTsp(tsp) }
                            /> 
                        </TableCell>
                        <TableCell>
                        <PrimaryButton 
                                buttonVariant = "outlined"
                                buttonText    = "Project Manager"
                                buttonColor   = "secondary"
                                buttonSize    = "small"
                                onClick       = { () => this.setState({ managerDialog: true, tspForAction: tsp }, this.fetchPackages ) }
                            /> 
                        </TableCell>
                        <TableCell>
                            <IconButton 
                                color       = { "secondary" }
                                onClick     = { () => this.setState({ tspForAction: tsp }, this.delTsp)}
                                disabled    = { Boolean(companyReducer.delLoader && tspForAction && tspForAction._id === tsp._id) }>
                                { (companyReducer.delLoader && tspForAction &&  tspForAction._id === tsp._id) ? <CircularProgress size={24} color = "secondary" /> : <MdDelete /> }
                            </IconButton>
                        </TableCell>
                    </TableRow>
                    )}
                </TableBody>
            </Table>
    }

    renderTechOfficers = () => {
        const { companyReducer, usersReducer } = this.props;
        const { userForAction } = this.state;
        return (
            <>
            <Grid container spacing={6}>
                { companyReducer.selectedCompany.players.map((officer) => 
                <Grid 
                    item 
                    xs = { 12 } 
                    sm = { 6 } 
                    md = { 4 } 
                    lg = { 3 } 
                    key = { officer._id }>
                    <UserCard 
                        user            = { officer }
                        inProgress      = { Boolean((companyReducer.playerLoader || usersReducer.updateLoader) && userForAction && userForAction._id === officer._id) }
                        deleteUser      = { () => this.setState({ userForAction: officer }, this.deletePlayer)}
                        suspendUser     = { () => this.setState({ userForAction: officer }, () => officer.suspended ? this.unSuspendUser() : this.suspendUser() )}
                        resetPassword   = { () => this.setState({ userForAction: officer, inputs: officer, editDialog: true }) }
                    />
                </Grid>
                )}
            </Grid>
            </>
        )
    }

    renderDialogContent = () => {
        const { inputs } = this.state;
        return (
            <>
                <PrimaryInput
                    inputValue          = { inputs.fullname || "" }
                    inputfullWidth      = { true }
                    inputLabel          = { "Fullname"}
                    formControlClasses  = { "light-input-container" }
                    inputPlaceholder    = { "Khurram"}
                    inputDisabled       = { true }
                />
                <PrimaryInput
                    inputValue          = { inputs.password || "" }
                    inputfullWidth      = { true }
                    inputLabel          = { "Password" }
                    inputType           = { "password" }
                    formControlClasses  = { "light-input-container" }
                    inputPlaceholder    = { "Set Password" }
                    onChangeInput       = { e => this.setState({ inputs: { ...inputs, password: e.target.value }})}
                />
            </>
        )
    }
    
    renderProjectManager = () => {
        const { packageReducer } = this.props;
        return packageReducer.fetchLoader
                ? 
                <div className="single-elm-container">
                    <CircularProgress color="secondary" />
                </div>
                : !packageReducer.packages[0]
                ?   <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 18, color: '#666', marginTop: 50, marginBottom: 50 }}>No Project Manager found</div>
                :
                <>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 16 }}>
                    <Avatar style = {{ width: 120, height: 120}} src  ={ packageReducer.packages[0].manager.image } />
                </div>
                
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell className="tb-head">Name</TableCell>
                            <TableCell>{ packageReducer.packages[0].manager.fullname }</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="tb-head">Mobile</TableCell>
                            <TableCell>{ packageReducer.packages[0].manager.mobile }</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="tb-head" style={{ borderBottom: 'none' }}>Package</TableCell>
                            <TableCell style={{ borderBottom: 'none' }}>{ packageReducer.packages[0].name }</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                </>
    }

    renderAction = () => {
        const { usersReducer } = this.props;
        return (
            <PrimaryButton
                buttonText      = { "Update" }
                inProgress      = { usersReducer.updateLoader }
                buttonDisabled  = { usersReducer.updateLoader }
                onClick         = { this.updateUser }
            />
        )
    }

    renderManagerAction = () => {
        return (
            <PrimaryButton
                buttonText      = { "Dismiss" }
                onClick         = { () => this.setState({ managerDialog: false  }) }
            />
        )
    }

    render(){
        const { companyReducer } = this.props;
        const { editDialog, managerDialog } = this.state;
        
        return(
            <PrimaryContainer>
                <div style={{ borderBottom: '1px solid #eee', display: 'flex', alignItems: 'center', flexDirection: 'row' }} >
                    <Button>
                        { companyReducer.selectedCompany ? companyReducer.selectedCompany.name : 'All TSP' }
                    </Button>
                    <IconButton disabled> <IoMdArrowDropright /> </IconButton>
                    { companyReducer.selectedCompany && <Button disabled>Tech Officers</Button> }
                    { companyReducer.selectedCompany && 
                    <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                        <PrimaryButton 
                            buttonText  = { "Go Back To TSP"}
                            buttonSize  = { "small"}
                            onClick     = { () => this.props.setSelectedTsp(null) }
                        />
                    </div>
                    }
                </div>

                <PrimarySnackbar 
                    open    = { Boolean(companyReducer.snackMsg)  }
                    message = { companyReducer.snackMsg }
                    onClose = { () => this.props.setSnackMsg()}
                />

                <CustomDialog 
                    open    = { editDialog }
                    title   = { "Reset Password"}
                    content = { this.renderDialogContent()  } 
                    action  = { this.renderAction() }
                    onClose = { () => this.setState({ editDialog: false })}
                />

                <CustomDialog 
                    open    = { managerDialog }
                    title   = { "Project Manager"}
                    content = { this.renderProjectManager()  } 
                    action  = { this.renderManagerAction() }
                    onClose = { () => this.setState({ managerDialog: false })}
                />

                { companyReducer.fetchLoader && !companyReducer.selectedCompany 
                ? 
                <div className="single-elm-container">
                    <CircularProgress color="secondary" />
                </div>
                : 
                <div className="fixed-content-width">
                        { companyReducer.selectedCompany 
                            ? this.renderTechOfficers() 
                            : this.renderTspList() 
                        }
                    </div>
                }
            </PrimaryContainer>
        )
    }
}

function mapStateToProps(state){
    return {
        companyReducer:     state.companyReducer,
        usersReducer:       state.usersReducer,
        packageReducer:     state.packageReducer,
    }
}

function mapDispatchToProps(dispatch){
    return {
        setSnackMsg:    (msg)           => dispatch(setSnackMsg(msg)),
        emptyTspList:   ()              => dispatch(emptyCompanyList()),
        emptyPkgList:   ()              => dispatch(emptyPackageList()),
        setSelectedTsp: (tsp)           => dispatch(setSelectedCompany(tsp)),
        fetchTsp:       (url, body, cb) => dispatch(fetchCompanies(url, body, cb)),
        delTsp:         (url, body, cb) => dispatch(delCompany(url, body, cb)),
        updateUser:     (url, body, cb) => dispatch(updateUser(url, body, cb)),
        updatePlayer:   (url, body, cb) => dispatch(updatePlayer(url, body, cb)),
        fetchPackages:  (url, body, cb) => dispatch(fetchPackages(url, body, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TSP);