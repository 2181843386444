import PACAKGES_CONST from './packages-constants';

var initial_state = {
    packages:            [],
    selectedPackage:     null,
    fetchLoader:         false,
    addLoader:           false,
    replaceLoader:       false,
    delLoader:           false,
    snackMsg:            null,
}

export const packageReducer = (state = initial_state, action) => {
    switch(action.type){

        ///////////////////////////////////////////////////////////////////////////////////////
        case PACAKGES_CONST.SET_SNACK: {
            return {
                ...state,
                snackMsg: action.payload
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        case PACAKGES_CONST.EMPTY_PACKAGE_LIST: {
            return {
                ...state,
                packages: []
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        case PACAKGES_CONST.SET_SELECTED_PACKAGE: {
            return {
                ...state,
                selectedPackage: action.payload
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        case PACAKGES_CONST.FETCH_PACKAGES_START: {
            return {
                ...state, 
                fetchLoader: true,
                snackMsg: null
            }
        }

        case PACAKGES_CONST.FETCH_PACKAGES_SUCCESS: {
            return {
                ...state, 
                fetchLoader: false,
                packages: action.payload,
            }
        }

        case PACAKGES_CONST.FETCH_PACKAGES_FAIL: {
            return {
                ...state, 
                fetchLoader: false,
                snackMsg: action.payload // payload is the snackMsg
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        case PACAKGES_CONST.ADD_NEW_PACKAGE_START: {
            return {
                ...state,
                addLoader: true,
                snackMsg: null,
            }
        }
        case PACAKGES_CONST.ADD_NEW_PACKAGE_SUCCESS: {
            return {
                ...state,
                addLoader: false,
            }
        }
        case PACAKGES_CONST.ADD_NEW_PACKAGE_FAIL: {
            return {
                ...state,
                addLoader: false,
                snackMsg: action.payload // payload is the snackMsg
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        case PACAKGES_CONST.DEL_PACKAGE_START: {
            return {
                ...state,
                delLoader: true,
                snackMsg: null,
            }
        }
        case PACAKGES_CONST.DEL_PACKAGE_SUCCESS: {
            return {
                ...state,
                delLoader: false,
                selectedPackage: null,
                
            }
        }
        case PACAKGES_CONST.DEL_PACKAGE_FAIL: {
            return {
                ...state,
                delLoader: false,
                snackMsg: action.payload // payload is the snackMsg
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        case PACAKGES_CONST.REPLACE_MANAGER_START: {
            return {
                ...state,
                replaceLoader: true,
                snackMsg: null,
            }
        }
        case PACAKGES_CONST.REPLACE_MANAGER_SUCCESS: {
            return {
                ...state,
                replaceLoader: false,
                
            }
        }
        case PACAKGES_CONST.REPLACE_MANAGER_FAIL: {
            return {
                ...state,
                replaceLoader: false,
                snackMsg: action.payload // payload is the snackMsg
            }
        }
        default: return state;
    }
}