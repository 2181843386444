import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Dialog, DialogTitle, DialogContent, DialogActions, Table, TableRow, TableHead, TableBody, TableCell,
    FormControl, Select, MenuItem, } from '@material-ui/core';

import { setSnackMsg, updateRequest } from '../../../../redux/requests/request-actions';
import { PrimaryButton } from '../../../../components/buttons';
import { PrimaryInput } from '../../../../components/inputs';
import Utility from '../../../../lib/utility';
import { endPoints } from '../../../../lib/values';

class ResolveRequest extends Component {

    constructor(props){
        super(props);
        this.state = {
            inputs: props.requestReducer.selectedRequest || {}, 
            editDialog: null, // eqDialog, confirmDialog
            eqDetail: {},
        }
    }

    editableForUser = () => {
        const { requestReducer, authReducer } = this.props;
        const { selectedRequest } = requestReducer;
        return (selectedRequest.status === 0 && selectedRequest.tech_officer._id === authReducer.user._id) ? true : false;
    }

    onAddEquipment = () => {
        const { eqDetail, inputs } = this.state;
        if(!eqDetail.name || !eqDetail.serial_no || !eqDetail.quantity){
            return this.setState({ eqDetail: {}, editDialog: null })
        };
        this.setState({ 
            eqDetail: {},
            editDialog: null,
            inputs: { ...inputs, replaced_parts: [ ...inputs.replaced_parts, eqDetail ] }, 
        })
    }
    

    resolveRequest = () => {
        const { inputs } = this.state;
        const { selectedRequest } = this.props.requestReducer
        const reqBody = {
            request_id:     selectedRequest._id,
            action_taken:   inputs.action_taken,
            replaced_parts: inputs.replaced_parts,
            respond_via:    inputs.respond_via,
        }

        this.props.updateRequest(endPoints.request.resolveReq, Utility.httpRequest('POST', reqBody), (err, jsonRes) => {
            if(err === null) this.setState({ inputs: jsonRes })
        })
    }

    onRemovePart = (index) => {
        const { inputs } = this.state;
        inputs.replaced_parts.splice(index, 1)
        this.setState({ inputs });
    }
     

    renderEqDetail = () => {
        const { inputs } = this.state;
        return (
            !inputs.replaced_parts || inputs.replaced_parts.length === 0
            ? null
            :
            <Table style={{ marginBottom: 16 }}>
                <TableHead>
                    <TableRow>
                        <TableCell className="tb-head">Name</TableCell>
                        <TableCell className="tb-head">Serial</TableCell>
                        <TableCell className="tb-head">Quantity</TableCell>
                       { this.editableForUser() &&  <TableCell className="tb-head tb-center-data">Remove</TableCell> }
                    </TableRow>
                </TableHead>
                <TableBody>
                    { inputs.replaced_parts.map((part, i) =>
                    
                    <TableRow key={i}>
                        <TableCell>{ part.name }</TableCell>
                        <TableCell>{ part.serial_no }</TableCell>
                        <TableCell>{ part.quantity }</TableCell>
                        { this.editableForUser() &&  
                        <TableCell className="tb-center-data">
                            <PrimaryButton 
                                buttonText  = "Remove"
                                buttonColor = "default"
                                buttonSize  = "small"
                                onClick     = { () => this.onRemovePart(i) }
                            />
                        </TableCell>
                        }

                    </TableRow>
                    )}
                </TableBody>
            </Table>
        )
    }

    equipmentDialog = () => {
        const { eqDetail, editDialog } = this.state;
        return (
            <Dialog open = { Boolean(editDialog === 'eqDialog') } onClose={() => this.setState({ editDialog: null, eqDetail: {} })}>
                <DialogTitle>Equipment Detail</DialogTitle>
                <DialogContent>
                    <PrimaryInput
                        inputValue          =  { eqDetail.name || "" }
                        inputfullWidth      = { true }
                        inputLabel          = "Name"
                        formControlClasses  = { "light-input-container" }
                        inputPlaceholder    = "Name of equipment"
                        onChangeInput       = {(e) => this.setState({ eqDetail: { ...eqDetail, name: e.target.value }})}
                    />
                    <PrimaryInput
                        inputValue          =  { eqDetail.serial_no || "" }
                        inputfullWidth      = { true }
                        inputLabel          = "Serial No."
                        formControlClasses  = { "light-input-container" }
                        inputPlaceholder    = "Serial No. of equipment"
                        onChangeInput       = {(e) => this.setState({ eqDetail: { ...eqDetail, serial_no: e.target.value }})}
                    />
                    <PrimaryInput
                        inputValue          =  { eqDetail.quantity || "" }
                        inputfullWidth      = { true }
                        inputLabel          = "Quantity"
                        formControlClasses  = { "light-input-container" }
                        inputPlaceholder    = "Quantity of equipment"
                        onChangeInput       = {(e) => this.setState({ eqDetail: { ...eqDetail, quantity: e.target.value }})}
                    />
                </DialogContent>
                <DialogActions>
                    <PrimaryButton 
                        buttonText      = { "Cancel" }
                        buttonVariant   = { "outlined" }
                        buttonColor     = { "default" }
                        buttonSize      = { "small" }
                        onClick         = {() => this.setState({ editDialog: null, eqDetail: {} })}
                    />
                    <PrimaryButton 
                        buttonText      = { "Add Equipment" }
                        buttonVariant   = { "outlined" }
                        buttonColor     = { "secondary" }
                        buttonSize      = { "small" }
                        onClick         = { this.onAddEquipment }
                    />
                </DialogActions>
            </Dialog>
        )
    }

    render(){
        const { inputs } = this.state;
        const { requestReducer, authReducer } = this.props;
        return (
            <> 
                <div className="content-title-container">
                    <span className="content-title-text">Request Resolve Detail</span>
                </div>

                <FormControl fullWidth>
                    <Select
                        fullWidth
                        variant     = "outlined"
                        style       = {{ backgroundColor: '#f2f2f2', marginBottom: 24 }}
                        value       = { inputs.respond_via || " " }
                        disabled    = { Boolean(requestReducer.selectedRequest.status === 1 || requestReducer.selectedRequest.tech_officer._id !== authReducer.user._id) }
                        onChange    = { e => this.setState({ inputs: { ...inputs, respond_via: e.target.value } }) }>
                        <MenuItem value={" "}>
                            <em>Choose Respond Via</em>
                        </MenuItem>
                        <MenuItem value = {"Site Visit"}>Site Visit</MenuItem> 
                        <MenuItem value = {"Remotely"}>Remotely</MenuItem> 
                    </Select>
                </FormControl>

                <PrimaryInput
                    inputValue          = { inputs.action_taken || "" }
                    inputfullWidth      = { true }
                    inputMultiline      = { true }
                    inputRows           = { 4}
                    inputLabel          = { "Action Taken"}
                    formControlClasses  = { "light-input-container" }
                    inputPlaceholder    = { "Detail of action taken"}
                    inputDisabled       = { Boolean(requestReducer.selectedRequest.status === 1 || requestReducer.selectedRequest.tech_officer._id !== authReducer.user._id) }
                    onChangeInput       = { (e) => this.setState({ inputs: { ...inputs, action_taken: e.target.value }})}
                />
                
                
                { this.renderEqDetail() }

                {  // control only shows when request need to resolve
                 this.editableForUser() && 
                    <>
                        { this.equipmentDialog() }
                        <div className="content-title-container">
                            <PrimaryButton 
                                buttonText      = "Add Replaced Equipment"
                                buttonSize      = "small"
                                onClick         = {() => this.setState({ editDialog: 'eqDialog' })}
                            />
                            <PrimaryButton 
                                buttonText      = "Resolve"
                                buttonColor     = "secondary"
                                buttonSize      = "small"
                                btnTextStyle    = {{ color: '#fff' }}
                                buttonDisabled  = { requestReducer.updateLoader }
                                inProgress      = { requestReducer.updateLoader }
                                onClick         = { this.resolveRequest }
                                
                            />
                        </div>
                    </>
                }
            </>
        )
    }
}

function mapStateToProps(state){
    return {
        requestReducer: state.requestReducer,
        authReducer: state.authReducer,
    }
}

function mapDispatchToProps(dispatch){
    return {
        setSnackMsg:        (msg) => dispatch(setSnackMsg(msg)),
        updateRequest:      (url, body, cb) => dispatch(updateRequest(url, body, cb)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResolveRequest);