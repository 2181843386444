import React, { Component } from 'react';
import { Button, IconButton, Grid, CircularProgress } from '@material-ui/core';
import { IoMdArrowDropright } from 'react-icons/all';
import { connect } from 'react-redux';

import { setSnackMsg, fetchUsers, emptyUserList, setSelectedUser, updateUser } from '../../../redux/users/users-actions';
import { PrimaryContainer, } from '../../../components/container';
import { PrimarySnackbar, } from '../../../components/messages';
import { CustomDialog } from '../../../components/dialog';
import { UserCard } from '../../../components/card';
import { PrimaryButton } from '../../../components/buttons';
import { PrimaryInput } from '../../../components/inputs';
import { endPoints, dataLimit } from '../../../lib/values';
import Utility from '../../../lib/utility';


class Directors extends Component {

    state = {
        inputs:         {},
        editDialog:     false,
        userForAction:  null,
        page:           1,
        
    }

    componentDidMount(){
        this.fetchDirectors();
    }

    componentWillUnmount(){
        this.props.emptyUserList();
    }

    updateUser = () => {
        this.props.updateUser(endPoints.users.update, Utility.httpRequest('POST', this.state.inputs), (err, jsonRes) => {
            // do something here...
            this.setState({ inputs: {}, userForAction: null, editDialog: false })
        })
    }

    fetchDirectors = () => {
        const { page } = this.state;
        const url = `${endPoints.users.fetchUserByRole}?role=5&page=${page}&limit=${dataLimit.perPage}`;
        this.props.fetchUsers(url, Utility.httpRequest('GET'), (err, jsonData) => {
            if(jsonData){
                jsonData.length < dataLimit.perPage ? this.setState({ haveMore: false }) :this.setState({ haveMore: true })
            }
        })
    }

    deleteUser(){
        const { userForAction } = this.state;
        this.props.updateUser(`${endPoints.users.delete}/${userForAction._id}`, Utility.httpRequest('DELETE'), (err, resp) => {
            if(!err) {
                this.setState({ userForAction: null });
                this.onRefresh()
            }
        })
    }

    suspendUser(){
        const reqBody = {
            userid: this.state.userForAction._id,
            suspension: true,
        }
        this.props.updateUser(endPoints.users.suspension, Utility.httpRequest('POST', reqBody), (err, resp) => {
            if(!err) {
                this.setState({ userForAction: null });
                this.onRefresh()
            }
        })
    }

    unSuspendUser(){
        const reqBody = {
            userid: this.state.userForAction._id,
            suspension: false,
        }
        this.props.updateUser(endPoints.users.suspension, Utility.httpRequest('POST', reqBody), (err, resp) => {
            if(!err) {
                this.setState({ userForAction: null });
                this.onRefresh()
            }
        })
    }

    onRefresh = () => {
        this.props.emptyUserList();
        this.setState({ page: 1, haveMore: true }, this.fetchDirectors);
    }

    renderDialogContent = () => {
        const { inputs } = this.state;
        return (
            <>
                <PrimaryInput
                    inputValue          = { inputs.fullname || "" }
                    inputfullWidth      = { true }
                    inputLabel          = { "Fullname"}
                    formControlClasses  = { "light-input-container" }
                    inputPlaceholder    = { "Khurram"}
                    inputDisabled       = { true }
                />
                <PrimaryInput
                    inputValue          = { inputs.password || "" }
                    inputfullWidth      = { true }
                    inputLabel          = { "Password" }
                    inputType           = { "password" }
                    formControlClasses  = { "light-input-container" }
                    inputPlaceholder    = { "Set Password" }
                    onChangeInput       = { e => this.setState({ inputs: { ...inputs, password: e.target.value }})}
                />
            </>
        )
    }

    renderAction = () => {
        const { usersReducer } = this.props;
        return (
            <PrimaryButton
                buttonText      = { "Update" }
                inProgress      = { usersReducer.updateLoader }
                buttonDisabled  = { usersReducer.updateLoader }
                onClick         = { this.updateUser }
            />
        )
    }

    render(){
        const { usersReducer } = this.props;
        const { editDialog, userForAction } = this.state;
        return(
            <PrimaryContainer>

                <CustomDialog 
                    open    = { editDialog }
                    title   = { "Reset Password"}
                    content = { this.renderDialogContent()  } 
                    action  = { this.renderAction() }
                    onClose = { () => this.setState({ editDialog: false })}
                />

                <PrimarySnackbar 
                    open    = { Boolean(usersReducer.snackMsg) }
                    message = { usersReducer.snackMsg }
                    onClose = { () => this.props.setSnackMsg(null) }

                />

                <div style={{ borderBottom: '1px solid #eee' }} >
                    <Button>Directors</Button>
                    <IconButton disabled><IoMdArrowDropright /></IconButton>
                </div>
                
                <div className="fixed-content-width">
                    <Grid container spacing={6}>
                        { usersReducer.fetchLoader && usersReducer.users.length === 0 
                        ? 
                        <div className="single-elm-container">
                            <CircularProgress color="secondary" />
                        </div>
                        : usersReducer.users.length >  0 && usersReducer.users.map((user) => 
                            <Grid item xs = { 12 } sm = { 6 } md = { 4 } lg = { 3 } key = { user._id }>
                                <UserCard 
                                    user            = { user }
                                    inProgress      = { Boolean(usersReducer.updateLoader && userForAction && userForAction._id === user._id) }
                                    deleteUser      = { () => this.setState({ userForAction: user }, this.deleteUser)}
                                    suspendUser     = { () => this.setState({ userForAction: user }, () => user.suspended ? this.unSuspendUser() : this.suspendUser() )}
                                    resetPassword   = { () => this.setState({ userForAction: user, inputs: user, editDialog: true }) }
                                />
                            </Grid>
                        )}
                    </Grid>
                </div>
            </PrimaryContainer>
        )
    }
}

function mapStateToProps(state){
    return {
        usersReducer: state.usersReducer,
    }
}

function mapDispatchToProps(dispatch){
    return {
        setSnackMsg:        (msg)           =>  dispatch(setSnackMsg(msg)),
        emptyUserList:      ()              =>  dispatch(emptyUserList()),
        setSelectedUser:    (user)          =>  dispatch(setSelectedUser(user)),
        fetchUsers:         (url, body, cb) =>  dispatch(fetchUsers(url, body, cb)),
        updateUser:         (url, body, cb) =>  dispatch(updateUser(url, body, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Directors);