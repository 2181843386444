import React, { Component } from 'react';
import { connect } from 'react-redux';

import { PrimaryAppbar } from '../../components/app-bar';
import { PrimaryDrawer } from '../../components/drawer';
import { PrimaryContainer } from '../../components/container';

import { Dashboard, Profile, Admins, Directors, Packages, Stations, Requests, Omc, Tsp, NewResource, Activities } from '../../views/content-views';
import { logout, setSessionError } from '../../redux/auth/auth-actions';
import { setContentView } from '../../redux/dom/dom-actions';
import { contentViews, endPoints } from '../../lib/values';
import Utility from '../../lib/utility';


class App extends Component {

    state = {
        openDrawer: false,
        logout: false
    }

    onLogout = () => {
        this.setState({ logout: true });
        Utility._fetch(endPoints.auth.logout, Utility.httpRequest('GET'), (err, jsonRes) => {
            this.setState({ logout: false })
            if(!err) {
                this.props.setContentView(contentViews.dashboard);
                this.props.logout();
            }
        })
    }

    renderContentView = () => {
        switch(this.props.domReducer.contentView){
            case contentViews.dashboard     :   return <Dashboard />
            case contentViews.profile       :   return <Profile />
            case contentViews.admins        :   return <Admins />
            case contentViews.directors     :   return <Directors />
            case contentViews.packages      :   return <Packages />
            case contentViews.stations      :   return <Stations />
            case contentViews.requests      :   return <Requests />
            case contentViews.omc           :   return <Omc />
            case contentViews.tsp           :   return <Tsp />
            case contentViews.newResource   :   return <NewResource />
            case contentViews.activities    :   return <Activities />
            default                         :   return <Dashboard />
        }
    }

    render(){
        const { authReducer, domReducer } = this.props;
        return (
            <PrimaryContainer>
                <PrimaryAppbar 
                    avatar          = { authReducer.user.image }
                    onClickMenu     = { () => this.setState({ openDrawer: !this.state.openDrawer }) } 
                    onClickLogout   = { this.onLogout }
                    logoutProgress  = { this.state.logout }
                    onClickUserImg  = { () => this.props.setContentView(contentViews.profile) }
                />
                
                <PrimaryDrawer 
                    open            = { this.state.openDrawer }
                    slug            = { domReducer.contentView }
                    onClickMenuItem = { menuItem => this.props.setContentView(menuItem) }
                    onCloseDrawer   = { () => this.setState({ openDrawer: !this.state.openDrawer }) }
                />
                <div className="content-wrapper full-height">
                    { this.renderContentView() }
                </div>
                
            </PrimaryContainer>
        )
    }
}

function mapStateTopProps(state){
    return {
        domReducer: state.dom,
        authReducer: state.authReducer,
    }
}

function mapDispatchToProps(dispatch){
    return {
        logout:         ()      => dispatch(logout()),
        setContentView: (view)  => dispatch(setContentView(view)),
        setSessionError: (err)  => dispatch(setSessionError(err)),
    }
}

export default connect(mapStateTopProps, mapDispatchToProps)(App);