import React, { Component } from 'react';
import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Button, Menu, MenuItem} from '@material-ui/core';
import { IoMdArrowDropright, MdMoreVert } from 'react-icons/all';
import { connect } from 'react-redux';

import { setSnackMsg, fetchPackages, setSelectedPackage, emptyPackageList, delPackage } from '../../../redux/packages/packages-actions';
import { fetchStations } from '../../../redux/station/station-actions';
import { updateUser } from '../../../redux/users/users-actions';

import { CustomDialog } from '../../../components/dialog';
import { PrimaryInput } from '../../../components/inputs';
import { PrimaryContainer } from '../../../components/container';
import { PrimaryButton } from '../../../components/buttons';
import { PrimarySnackbar } from '../../../components/messages';
import Utility from '../../../lib/utility';
import { endPoints } from '../../../lib/values';

class Packages extends Component {

    state = {
        editDialog:     false,
        stDialog:       false,
        pkgForAction:   null,
        inputs:         {},
    }

    closeMenu   = () => this.setState({ anchorEl: null, pkgForAction: null })
    showMenu    = (e, pkg)  => this.setState({ anchorEl: e.currentTarget, pkgForAction: pkg })

    componentDidMount(){
        this.fetchPackages();
    }

    componentWillUnmount(){
        this.props.emptyPackageList();
    }

    filterStation = () => {
        const { pkgForAction } = this.state;
        const url = `${endPoints.station.filter}?pkg=${pkgForAction._id}`;
        this.props.filterStation(url, Utility.httpRequest('GET'), (err, jsonRes) => {
            // do something...
        })
    }

    fetchPackages = () => {
        const url = `${endPoints.package.listAll}`;
        this.props.fetchPackages(url, Utility.httpRequest('GET'), (err, jsonData) => {
            // do something here....
        })
    }

    updateUser = () => {
        this.props.updateUser(endPoints.users.update, Utility.httpRequest('POST', this.state.inputs), (err, jsonRes) => {
            // do something here...
            this.setState({ inputs: {}, userForAction: null, editDialog: false });
            !err && this.props.setSnackMsg("Manager password reset successfully");
        })
    }

    delPkg = () => {
        this.setState({ anchorEl: null })
        const reqBody = {
            package_id: this.state.pkgForAction._id
        }
        this.props.delPackage(endPoints.package.delPackage, Utility.httpRequest('DELETE', reqBody), (err, resp) => {
            if(err === null){
                this.setState({ pkgForAction: null })
                this.onRefresh();
            }
        })
    }

    onRefresh = () => {
        this.props.emptyPackageList();
        this.fetchPackages();
    }

    renderDialogContent = () => {
        const { inputs } = this.state;
        return (
            <>
                <PrimaryInput
                    inputValue          = { inputs.fullname || "" }
                    inputfullWidth      = { true }
                    inputLabel          = { "Fullname"}
                    formControlClasses  = { "light-input-container" }
                    inputPlaceholder    = { "Khurram"}
                    inputDisabled       = { true }
                />
                <PrimaryInput
                    inputValue          = { inputs.password || "" }
                    inputfullWidth      = { true }
                    inputLabel          = { "Password" }
                    inputType           = { "password" }
                    formControlClasses  = { "light-input-container" }
                    inputPlaceholder    = { "Set Password" }
                    onChangeInput       = { e => this.setState({ inputs: { ...inputs, password: e.target.value }})}
                />
            </>
        )
    }

    renderAction = () => {
        const { usersReducer } = this.props;
        return (
            <PrimaryButton
                buttonText      = { "Update" }
                inProgress      = { usersReducer.updateLoader }
                buttonDisabled  = { usersReducer.updateLoader }
                onClick         = { this.updateUser }
            />
        )
    }

    renderPkgStation = () => {
        const { stationReducer } = this.props;
        return stationReducer.fetchLoader
        ? 
        <div className="single-elm-container">
            <CircularProgress color="secondary" />
        </div>
        :
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell className="tb-head">Name      </TableCell>
                    <TableCell className="tb-head">Bound     </TableCell>
                    <TableCell className="tb-head">Lane      </TableCell>
                    <TableCell className="tb-head">System    </TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                { stationReducer.stations.map((station) => 
                <TableRow key={station._id}>
                    <TableCell>{ station.name  }</TableCell>
                    <TableCell>{ station.bound  }</TableCell>
                    <TableCell>{ station.lane  }</TableCell>
                    <TableCell>{ station.sys_installed  }</TableCell>
                </TableRow>
                )}
            </TableBody>
        </Table>
    }


    render(){
        const { packageReducer } = this.props;
        const { pkgForAction, anchorEl, editDialog, inputs, stDialog } = this.state;
        return(
            <PrimaryContainer>

                <PrimarySnackbar 
                    open    = { Boolean(!editDialog && packageReducer.snackMsg ) }
                    message = { packageReducer.snackMsg }
                    onClose = { () => this.props.setSnackMsg(null) }
                />

                <div style={{ borderBottom: '1px solid #eee' }} >
                    <Button>Packages</Button>
                    <IconButton disabled><IoMdArrowDropright /></IconButton>
                </div>

                <CustomDialog 
                    open    = { Boolean(editDialog || inputs._id) }
                    title   = { "Reset Manager Password"}
                    content = { this.renderDialogContent()  } 
                    action  = { this.renderAction() }
                    onClose = { () => this.setState({ editDialog: false, inputs: {} })}
                />
                
                <CustomDialog 
                    open    = { Boolean(stDialog) }
                    title   = { pkgForAction && `${pkgForAction.name} Stations` }
                    content = { this.renderPkgStation()  } 
                    action  = { <PrimaryButton  buttonText = "Dismiss" onClick = {() => this.setState({  pkgForAction: null, stDialog: false })} /> }
                    onClose = { () => this.setState({ stDialog: false, pkgForAction: null })}
                />

                <Menu
                    open        = { Boolean(anchorEl)}
                    anchorEl    = { anchorEl }
                    onClose     = { this.closeMenu }>
                    
                    <MenuItem onClick = { this.delPkg }>Delete Package</MenuItem>
                    <MenuItem onClick = { () => this.setState({ editDialog: true, anchorEl: null, inputs: pkgForAction.manager || {} })}>Reset Password</MenuItem>
                </Menu>

                { packageReducer.fetchLoader
                    ? 
                    <div className="single-elm-container">
                        <CircularProgress color="secondary" />
                    </div>
                    :
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="tb-head">Name         </TableCell>
                                <TableCell className="tb-head">TSP          </TableCell>
                                <TableCell className="tb-head">Created      </TableCell>
                                <TableCell className="tb-head">Created By   </TableCell>
                                <TableCell className="tb-head">Manager      </TableCell>
                                <TableCell className="tb-head">Stations     </TableCell>
                                <TableCell className="tb-head">Actions      </TableCell>
                            </TableRow>
                        </TableHead>
    
                        <TableBody>
                            { packageReducer.packages.map((pkg) => 
                            <TableRow key={pkg._id}>
                                
                                <TableCell>{ pkg.name  }</TableCell>
                                <TableCell>{ pkg.tsp && pkg.tsp.name  }</TableCell>
                                <TableCell>
                                    { Utility.formatFullDate(pkg.created)} <br/> 
                                    <span style={{ color: '#888' }}>{ Utility.timeAgoFormat(pkg.created) } </span>
                                </TableCell>
                                <TableCell>{ pkg.created_by && pkg.created_by.fullname }</TableCell>
                                <TableCell>
                                    { pkg.manager && pkg.manager.fullname }
                                </TableCell>
                                <TableCell>
                                    <PrimaryButton 
                                        buttonSize      = "small"
                                        buttonText      = "View Stations"
                                        buttonColor     = "secondary"
                                        buttonVariant   = "outlined"
                                        onClick         = { () => this.setState({ pkgForAction: pkg, stDialog: true }, this.filterStation)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton 
                                        color="secondary"
                                        disabled = { Boolean(pkgForAction && pkgForAction._id === pkg._id && packageReducer.delLoader) } 
                                        onClick = { e => { this.showMenu(e, pkg) } }>
                                        { pkgForAction && pkgForAction._id === pkg._id && packageReducer.delLoader 
                                        ? <CircularProgress color="secondary" size={24} />
                                        : <MdMoreVert />
                                        }
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                            )}
                        </TableBody>
                    </Table>
                }
            </PrimaryContainer>
        )
    }
}

function mapStateToProps(state){
    return {
        usersReducer:   state.usersReducer,
        packageReducer: state.packageReducer,
        stationReducer: state.stationReducer,
    }
}

function mapDispatchToProps(dispatch){
    return {
        setSnackMsg:            (msg)           => dispatch(setSnackMsg(msg)),
        emptyPackageList:       ()              => dispatch(emptyPackageList()),
        setSelectedPackage:     (pkg)           => dispatch(setSelectedPackage(pkg)),
        fetchPackages:          (url, body, cb) => dispatch(fetchPackages(url, body, cb)),
        delPackage:             (url, body, cb) => dispatch(delPackage(url, body, cb)),
        updateUser:             (url, body, cb) => dispatch(updateUser(url, body, cb)),
        filterStation:          (url, body, cb) => dispatch(fetchStations(url, body, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Packages);