import USER_CONST from './users-constants';
import Utility from '../../lib/utility';
import action from '../action-generator';


export const setSnackMsg     = (err)  => action(USER_CONST.SET_SNACK, err);
export const emptyUserList   = ()     => action(USER_CONST.EMPTY_USER_LIST, null);
export const setSelectedUser =  (user) => action(USER_CONST.SET_SELECTED_USER, user);

/*****************************************************************************************
 *              async actions
******************************************************************************************/
export const fetchUsers         = (url, body, cb) => asyncAction(url, body, cb, 'fetchUsers');
export const updateUser         = (url, body, cb) => asyncAction(url, body, cb, 'updateUser');
export const addUser            = (url, body, cb) => asyncAction(url, body, cb, 'addUser');

/***************************************************************************************************
 *              Helper method for async actions
 ***************************************************************************************************/
const asyncAction = (url, body, cb, actionType) => {
    let startAction = null, successAction = null, failAction = null;
    switch(actionType){
        case 'fetchUsers': {
            startAction     = USER_CONST.FETCH_USERS_START;
            successAction   = USER_CONST.FETCH_USERS_SUCCESS;
            failAction      = USER_CONST.FETCH_USERS_FAIL;
            break;
        }
        case 'updateUser': {
            startAction     = USER_CONST.USER_UPDATE_START;
            successAction   = USER_CONST.USER_UPDATE_SUCCESS;
            failAction      = USER_CONST.USER_UPDATE_FAIL;
            break;
        }
        case 'addUser': {
            startAction     = USER_CONST.ADD_USER_START;
            successAction   = USER_CONST.ADD_USER_SUCCESS;
            failAction      = USER_CONST.ADD_USER_FAIL;
            break;
        }
        
        default: {
            startAction     = USER_CONST.FETCH_USERS_START;
            successAction   = USER_CONST.FETCH_USERS_SUCCESS;
            failAction      = USER_CONST.FETCH_USERS_FAIL;
            break;
        }
    }
    return (dispatch) => {
        dispatch(action(startAction, null));
        Utility._fetch(url, body, (err, success) => {
            if(err){
                dispatch(action(failAction, err));
                cb && cb(err, null);
            } else {
                dispatch(action(successAction, success));
                cb && cb(null, success);
            }
        })
    }
}