import PACKAGE_CONST from './packages-constants';
import Utility from '../../lib/utility';
import action from '../action-generator';


export const setSnackMsg        = (err)      => action(PACKAGE_CONST.SET_SNACK, err);
export const emptyPackageList   = ()         => action(PACKAGE_CONST.EMPTY_PACKAGE_LIST, null);
export const setSelectedPackage = (pkg)      => action(PACKAGE_CONST.SET_SELECTED_PACKAGE, pkg);

/*****************************************************************************************
 *              async actions
 ****************************************************************************************/
export const fetchPackages      = (url, body, cb) => asyncAction(url, body, cb, 'fetchPackages');
export const newPackage         = (url, body, cb) => asyncAction(url, body, cb, 'newPackage');
export const delPackage         = (url, body, cb) => asyncAction(url, body, cb, 'delPackage');
export const replaceManager     = (url, body, cb) => asyncAction(url, body, cb, 'replaceManager');

/********************************************************************************************
 *              Helper method for async actions
 ********************************************************************************************/
const asyncAction = (url, body, cb, actionType) => {
    let startAction = null, successAction = null, failAction = null;
    switch(actionType){
        case 'fetchPackages': {
            startAction     = PACKAGE_CONST.FETCH_PACKAGES_START;
            successAction   = PACKAGE_CONST.FETCH_PACKAGES_SUCCESS;
            failAction      = PACKAGE_CONST.FETCH_PACKAGES_FAIL;
            break;
        }
        case 'newPackage': {
            startAction     = PACKAGE_CONST.ADD_NEW_PACKAGE_START;
            successAction   = PACKAGE_CONST.ADD_NEW_PACKAGE_SUCCESS;
            failAction      = PACKAGE_CONST.ADD_NEW_PACKAGE_FAIL;
            break;
        }
        case 'delPackage': {
            startAction     = PACKAGE_CONST.DEL_PACKAGE_START;
            successAction   = PACKAGE_CONST.DEL_PACKAGE_SUCCESS;
            failAction      = PACKAGE_CONST.DEL_PACKAGE_FAIL;
            break;
        }
        case 'replaceManager': {
            startAction     = PACKAGE_CONST.REPLACE_MANAGER_START;
            successAction   = PACKAGE_CONST.REPLACE_MANAGER_SUCCESS;
            failAction      = PACKAGE_CONST.REPLACE_MANAGER_FAIL;
            break;
        }
        
        default: {
            startAction     = PACKAGE_CONST.FETCH_PACKAGES_START;
            successAction   = PACKAGE_CONST.FETCH_PACKAGES_SUCCESS;
            failAction      = PACKAGE_CONST.FETCH_PACKAGES_FAIL;
            break;
        }
    }
    return (dispatch) => {
        dispatch(action(startAction, null));
        Utility._fetch(url, body, (err, success) => {
            if(err){
                dispatch(action(failAction, err));
                cb && cb(err, null);
            } else {
                dispatch(action(successAction, success));
                cb && cb(null, success);
            }
        })
    }
}