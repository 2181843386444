const data = [
    { title: 'Dashboard',       slug: 'dashboard' },
    { title: 'Support Request', slug: 'requests' },
    { title: 'Weigh Stations',  slug: 'weigh-stations' },
    { title: 'Packages',        slug: 'packages' },
    { title: 'TSP',             slug: 'tsp' },
    { title: 'OMC',             slug: 'omc' },
    { title: 'Directors',       slug: 'directors' },
    { title: 'Admin',           slug: 'admins' },
    { title: 'New Resource',    slug: 'new-resource' },
    { title: 'Activities',      slug: 'activities' },
]

export default data;