import STATION_CONST from './station-constant';
import Utility from '../../lib/utility';
import action from '../action-generator';


export const setSnackMsg        = (msg)      => action(STATION_CONST.SET_SNACK, msg);
export const emptyStationList   = ()         => action(STATION_CONST.EMPTY_STATION_LIST, null);
export const setSelectedStation = (station)  => action(STATION_CONST.SET_SELECTED_STATION, station);

/*****************************************************************************************
 *              async actions
******************************************************************************************/
export const updateStation      = (url, body, cb) => asyncAction(url, body, cb, 'updateStation');
export const deleteStation      = (url, body, cb) => asyncAction(url, body, cb, 'deleteStation');
export const fetchStations      = (url, body, cb) => asyncAction(url, body, cb, 'fetchStations');
export const updateStationOperator = (url, body, cb) => asyncAction(url, body, cb, 'updateStationOperator');

/*****************************************************************************************
 *              Helper method for async actions
******************************************************************************************/
const asyncAction = (url, body, cb, actionType) => {
    let startAction = null, successAction = null, failAction = null;
    switch(actionType){
        case 'updateStation': {
            startAction     = STATION_CONST.UPDATE_STATION_START;
            successAction   = STATION_CONST.UPDATE_STATION_SUCCESS;
            failAction      = STATION_CONST.UPDATE_STATION_FAIL;
            break;
        }
        case 'deleteStation': {
            startAction     = STATION_CONST.DELETE_STATION_START;
            successAction   = STATION_CONST.DELETE_STATION_SUCCESS;
            failAction      = STATION_CONST.DELETE_STATION_FAIL;
            break;
        }
        case 'fetchStations': {
            startAction     = STATION_CONST.FETCH_STATIONS_START;
            successAction   = STATION_CONST.FETCH_STATIONS_SUCCESS;
            failAction      = STATION_CONST.FETCH_STATIONS_FAIL;
            break;
        }
        case 'updateStationOperator': {
            startAction     = STATION_CONST.UPDATE_ST_OMC_START;
            successAction   = STATION_CONST.UPDATE_ST_OMC_SUCCESS;
            failAction      = STATION_CONST.UPDATE_ST_OMC_FAIL;
            break;
        }

        default: {
            startAction     = STATION_CONST.FETCH_STATIONS_START;
            successAction   = STATION_CONST.FETCH_STATIONS_SUCCESS;
            failAction      = STATION_CONST.FETCH_STATIONS_FAIL;
            break;
        }
    }
    return (dispatch) => {
        dispatch(action(startAction, null));
        Utility._fetch(url, body, (err, success) => {
            if(err){
                dispatch(action(failAction, err));
                cb && cb(err, null);
            } else {
                dispatch(action(successAction, success));
                cb && cb(null, success);
            }
        })
    }
}