import React from 'react';
import { connect } from 'react-redux';
import { FormControl, Select, MenuItem, CircularProgress } from '@material-ui/core';
import { Heading } from '../components';

import { newCompany, setSnackMsg, fetchCompanies, emptyCompanyList, updatePlayer } from '../../../../redux/company/company-actions';
import { PrimaryInput } from '../../../../components/inputs';
import { PrimaryButton } from '../../../../components/buttons';
import { PrimarySnackbar } from '../../../../components/messages';
import { endPoints } from '../../../../lib/values';
import Utility from '../../../../lib/utility';


class NewTSP extends React.Component {
    
    state = {
        inputs:     {},
        start_opt:  ' ',
    }

    componentWillUnmount(){
        this.props.emptyCompanyList();
    }

    fetchCompanies = () => {
        this.props.fetchCompanies(`${endPoints.company.listAll}/1`, Utility.httpRequest('GET'), (err, jsonData) => {
            // do something here...
        });
    }

    onSave = () => {
        const { start_opt } = this.state;
        start_opt === 'newTSP' && this.addTsp();
        start_opt === 'newTechOfficer' && this.addTechOfficer();
    }

    addTsp = () => {
        const { inputs } = this.state;
        this.props.newTsp(endPoints.company.addNew, Utility.httpRequest('POST', { ...inputs, type: 1 }), (err, jsonRes) => {
            !err && this.props.onSuccess('New TSP has been added successfully!') 
        })
    }

    addTechOfficer = () => {
        const { inputs } = this.state;
        
        if(!inputs.company_id) return this.props.setSnackMsg('TSP is not selected'); 

        this.props.updatePlayer(endPoints.company.addPlayer, Utility.httpRequest('POST', inputs), (err, jsonRes) => {
            !err && this.props.onSuccess('New Technical Officer has been added successfully!') 
        })
    }

    onChangeStartValue(e){
        const start_opt = e.target.value;
        start_opt === 'newTechOfficer' && this.fetchCompanies();
        this.setState({  start_opt, inputs: {} })
    }

    render(){
        const { inputs, start_opt } = this.state;
        const { companyReducer } = this.props;
        return (
            <>
                <PrimarySnackbar 
                    open    = { Boolean(companyReducer.snackMsg) }
                    message = { companyReducer.snackMsg }
                    onClose = { () => this.props.setSnackMsg(null) }
                />

                <Heading 
                    headingFirst = "TSP Resources"
                    headingSecond = "TSP is a company that provide support for generated support requests"
                />

                <FormControl fullWidth>
                    <Select
                        fullWidth
                        variant     = "outlined"
                        style       = {{ backgroundColor: '#f2f2f2', marginBottom: 24 }}
                        value       = { start_opt }
                        onChange    = { this.onChangeStartValue.bind(this) }>
                        <MenuItem value={" "}><em>Start With Best Option</em></MenuItem>
                        <MenuItem value={"newTSP"}>New TSP</MenuItem>
                        <MenuItem value={"newTechOfficer"}>New Tech Officer To Existing TSP</MenuItem>
                    </Select>
                </FormControl>

                { start_opt === 'newTSP' && 
                <>
                    <PrimaryInput
                        inputValue          = { inputs.name || "" }
                        inputfullWidth      = { true }
                        inputLabel          = { "TSP Name" }
                        formControlClasses  = { "light-input-container" }
                        inputPlaceholder    = "TolLink"
                        onChangeInput       = {(e) => this.setState({ inputs: { ...inputs, name: e.target.value }})}
                    />
                </>
                }

                { companyReducer.fetchLoader 
                ? <div style={{ display: 'flex',justifyContent: 'center', padding: 24 }}><CircularProgress color="secondary" /></div>
                : !companyReducer.fetchLoader && start_opt === 'newTechOfficer' && 
                <>
                    <FormControl fullWidth>
                        <Select
                            fullWidth
                            variant     = "outlined"
                            style       = {{ backgroundColor: '#f2f2f2', marginBottom: 24 }}
                            value       = { inputs.company_id || ' ' }
                            onChange    = { e => this.setState({ inputs: { ...inputs, company_id: e.target.value }}) }>
                            <MenuItem value={" "}><em>Choose TSP</em></MenuItem>
                            { companyReducer.companies.map(company => 
                               <MenuItem 
                                key     = { company._id } 
                                value   = { company._id }>
                                    { company.name }
                                </MenuItem> 
                            )}
                        </Select>
                    </FormControl>

                    <div className="text-through-container">
                        <span className="text-through-text">New Tech Officer</span>
                    </div>

                    <PrimaryInput
                        inputValue          = { inputs.player_name || "" }
                        inputfullWidth      = { true }
                        inputLabel          = { "Fullname" }
                        formControlClasses  = { "light-input-container" }
                        inputPlaceholder    = { "Nazir Jut" }
                        onChangeInput       = { e => this.setState({ inputs: { ...inputs, player_name: e.target.value }})}
                    />

                    <PrimaryInput
                        inputValue          = { inputs.player_mobile || "" }
                        inputfullWidth      = { true }
                        inputLabel          = { "Mobile" }
                        formControlClasses  = { "light-input-container" }
                        inputPlaceholder    = { "03336999640"}
                        inputMaxLength      = { 11 }
                        onChangeInput       = { e => this.setState({ inputs: { ...inputs, player_mobile: e.target.value.replace(/[^0-9]/g, '') }})}
                    />
                </>
                }

                { start_opt === ' ' ?
                <div className="auth-single-btn">
                    <PrimaryButton
                        buttonText      = "Start Again"
                        buttonVariant   = "outlined"
                        buttonColor     = "secondary"
                        onClick         = { this.props.onStartAgain }
                    />
                </div>
                
                :
                <div className="auth-btns-container">
                    <PrimaryButton
                        buttonText      = "Start Again"
                        buttonVariant   = "outlined"
                        buttonColor     = "secondary"
                        onClick         = { this.props.onStartAgain }
                    />
                    <PrimaryButton
                        buttonText      = "Save"
                        inProgress      = { Boolean(companyReducer.addLoader || companyReducer.playerLoader ) }
                        buttonDisabled  = { Boolean(companyReducer.addLoader || companyReducer.playerLoader )  }
                        onClick         = { this.onSave }
                    />
                </div>
                }
                
            </>
        )
    }
}

function mapStateToProps(state){
    return {
        companyReducer: state.companyReducer,
    }
}


function mapDispatchToProps(dispatch){
    return {
        setSnackMsg:        (msg)           => dispatch(setSnackMsg(msg)),
        emptyCompanyList:   ()              => dispatch(emptyCompanyList()),
        newTsp:             (url, body, cb) => dispatch(newCompany(url, body, cb)),
        fetchCompanies:     (url, body, cb) => dispatch(fetchCompanies(url, body, cb)),
        updatePlayer:       (url, body, cb) => dispatch(updatePlayer(url, body, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewTSP);