import React from 'react';
import { Grid } from '@material-ui/core';

const TwoColumnContainer = (props) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
                { props.leftContent }
            </Grid>
            <Grid item xs={12} md={4}>
                { props.rightContent }
            </Grid>
        </Grid>
    )
}

export default TwoColumnContainer;