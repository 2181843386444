const AUTH_CONST = {
    SET_LOGIN_USER:             'SET_LOGIN_USER', 
    SET_APP_LOADING:            'SET_APP_LOADING',
    SET_SESSION_ERR:            'SET_SESSION_ERR',

    SET_SESSION_VALIDITY:       'SET_SESSION_VALIDITY',
    LOGOUT:                     'LOGOUT',
    SET_SNACK:                  'SET_SNACK',
    SET_USER_IMG:               'SET_USER_IMG',

    AUTH_START:                 'AUTH_START',
    AUTH_SUCCESS:               'AUTH_SUCCESS',
    AUTH_FAIL:                  'AUTH_FAIL',

    FETCH_PROFILE_START:        'FETCH_PROFILE_START',
    FETCH_PROFILE_SUCCESS:      'FETCH_PROFILE_SUCCESS',
    FETCH_PROFILE_FAIL:         'FETCH_PROFILE_FAIL',

    PROFILE_UPDATE_START:       'PROFILE_UPDATE_START',
    PROFILE_UPDATE_SUCCESS:     'PROFILE_UPDATE_SUCCESS',
    PROFILE_UPDATE_FAIL:        'PROFILR_UPDATE_FAIL',

    USER_IMG_UPLOAD_START:      'USER_IMG_UPLOAD_START',
    USER_IMG_UPLOAD_SUCCESS:    'USER_IMG_UPLOAD_SUCCESS',
    USER_IMG_UPLOAD_FAIL:       'USER_IMG_UPLOAD_FAIL',

    GEN_AUTH_CODE_START:        'GEN_AUTH_CODE_START',
    GEN_AUTH_CODE_SUCCESS:      'GEN_AUTH_CODE_SUCCESS',
    GEN_AUTH_CODE_FAIL:         'GEN_AUTH_CODE_FAIL',
    
    FORGOT_PASS_START:          'FORGOT_PASS_START',
    FORGOT_PASS_SUCCESS:        'FORGOT_PASS_SUCCESS',
    FORGOT_PASS_FAIL:           'FORGOT_PASS_FAIL',
}

export default AUTH_CONST;