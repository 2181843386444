const COMPANY_CONST = {
    SET_SNACK_MSG:          'SET_SNACK_MSG',
    SET_SELECTED_COMPANY:   'SET_SELECTED_COMPANY',
    EMPTY_COMPANY_LIST:     'EMPTY_COMPANY_LIST',

    FETCH_COMPANY_START:    'FETCH_COMPANY_START',
    FETCH_COMPANY_SUCCESS:  'FETCH_COMPANY_SUCCESS',
    FETCH_COMPANY_FAIL:     'FETCH_COMPANY_FAIL',
    
    ADD_COMPANY_START:      'ADD_COMPANY_START',
    ADD_COMPANY_SUCCESS:    'ADD_COMPANY_SUCCESS',
    ADD_COMPANY_FAIL:       'ADD_COMPANY_FAIL',
    
    DEL_COMPANY_START:      'DEL_COMPANY_START',
    DEL_COMPANY_SUCCESS:    'DEL_COMPANY_SUCCESS',
    DEL_COMPANY_FAIL:       'DEL_COMPANY_FAIL',

    UPDATE_PLAYER_START:    'UPDATE_PLAYER_START',
    UPDATE_PLAYER_SUCCESS:  'UPDATE_PLAYER_SUCCESS',
    UPDATE_PLAYER_FAIL:     'UPDATE_PLAYER_FAIL',
}

export default COMPANY_CONST;