import React from 'react';
import { Slide, Paper, IconButton } from '@material-ui/core';
import { MdClose } from 'react-icons/all';
import PropTypes from 'prop-types';

const CustomSnackbar = (props) => {
    return (
        <Slide direction={ props.direction } in ={ props.show } mountOnEnter unmountOnExit>
            <Paper style={ styles.paper }>
                    { props.title }
                <IconButton style={ styles.iconsBtn } onClick = { props.onClose }>
                    <MdClose size = {18} />
                </IconButton>
            </Paper>
        </Slide>
    )
}

const styles = {
    paper: {
        backgroundColor: '#f2f2f2', 
        color: '#cc0000', 
        padding: "4px 8px"
    },
    iconsBtn: {
        marginLeft: 4
    }
}

CustomSnackbar.propTypes = {
    direction:  PropTypes.oneOf(['right', 'left', 'top', 'bottom']),
    show:       PropTypes.bool,
    title:      PropTypes.string,
    onClose:    PropTypes.func,
}
CustomSnackbar.defaultProps = {
    direction:  'right',
    show:       false,
    title:      "No text to show",
}

export default CustomSnackbar;