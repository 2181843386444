const STATION_CONST = {
    SET_SNACK:                  'SET_SNACK',
    EMPTY_STATION_LIST:         'EMPTY_STATION_LIST',
    SET_SELECTED_STATION:       'SET_SELECTED_STATION',
    SET_PACKAGE_FOR_STATIONS:   'SET_PACKAGE_FOR_STATIONS',

    FETCH_STATIONS_START:       'FETCH_STATIONS_START',
    FETCH_STATIONS_SUCCESS:     'FETCH_STATIONS_SUCCESS',
    FETCH_STATIONS_FAIL:        'FETCH_STATIONS_FAIL',

    UPDATE_STATION_START:       'UPDATE_STATION_START',
    UPDATE_STATION_SUCCESS:     'UPDATE_STATION_SUCCESS',
    UPDATE_STATION_FAIL:        'UPDATE_STATION_FAIL',

    DELETE_STATION_START:       'DELETE_STATION_START',
    DELETE_STATION_SUCCESS:     'DELETE_STATION_SUCCESS',
    DELETE_STATION_FAIL:        'DELETE_STATION_FAIL',
    
    UPDATE_ST_OPERATOR_START:   'UPDATE_ST_OPERATOR_START',
    UPDATE_ST_OPERATOR_SUCCESS: 'UPDATE_ST_OPERATOR_SUCCESS',
    UPDATE_ST_OPERATOR_FAIL:    'UPDATE_ST_OPERATOR_FAIL',
}

export default STATION_CONST;
