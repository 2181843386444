import REQUEST_CONST from './request-constants';
import Utility from '../../lib/utility';
import action from '../action-generator';

// set actions
export const setSnackMsg        = (msg)         => action(REQUEST_CONST.SET_SNACK, msg);
export const setSelectedRequest = (request)     => action(REQUEST_CONST.SET_SELECTED_REQUEST, request);
export const emptyRequests      = ()            => action(REQUEST_CONST.EMPTY_REQUESTS, null);

/**********************************************************************************
 *      async actions
 *********************************************************************************/
export const fetchRequests      = (url, body, cb) => asyncAction(url, body, cb, 'fetchRequests');
export const updateRequest      = (url, body, cb) => asyncAction(url, body, cb, 'updateRequest');

/**********************************************************************************
 *      Helper method for async actions
 *********************************************************************************/
const asyncAction = (url, body, cb, actionType) => {
    let startAction = null, successAction = null, failAction = null;
    switch(actionType){
        case 'fetchRequests': {
            startAction     = REQUEST_CONST.FETCH_REQUESTS_START;
            successAction   = REQUEST_CONST.FETCH_REQUESTS_SUCCESS;
            failAction      = REQUEST_CONST.FETCH_REQUESTS_FAIL;
            break;
        }
        case 'updateRequest': {
            startAction     = REQUEST_CONST.UPDATE_REQUEST_START;
            successAction   = REQUEST_CONST.UPDATE_REQUEST_SUCCESS;
            failAction      = REQUEST_CONST.UPDATE_REQUEST_FAIL;
            break;
        }

        default: {
            startAction     = REQUEST_CONST.FETCH_REQUESTS_START;
            successAction   = REQUEST_CONST.FETCH_REQUESTS_SUCCESS;
            failAction      = REQUEST_CONST.FETCH_REQUESTS_FAIL;
            break;
        }
    }
    return (dispatch) => {
        dispatch(action(startAction, null));
        Utility._fetch(url, body, (err, success) => {
            if(err){
                dispatch(action(failAction, err));
                cb && cb(err, null);
            } else {
                dispatch(action(successAction, success));
                cb && cb(null, success);
            }
        })
    }
}