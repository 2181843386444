import REQUEST_CONST from './request-constants';

var initial_state = {
    requests:           [],
    selectedRequest:    null,
    fetchLoader:        false,
    updateLoader:       false,
    snackMsg:           null,
}

export const requestReducer = (state = initial_state, action) => {
    switch(action.type){

        //////////////////////////////////////////////////////////////////////////////////
        case REQUEST_CONST.SET_SNACK: {
            return {
                ...state,
                snackMsg: action.payload
            }
        }

        //////////////////////////////////////////////////////////////////////////////////
        case REQUEST_CONST.SET_SELECTED_REQUEST: {
            return {
                ...state,
                selectedRequest: action.payload
            }
        }

        //////////////////////////////////////////////////////////////////////////////////
        case REQUEST_CONST.EMPTY_REQUESTS: {
            return {
                ...state,
                requests: []
            }
        }
        
        //////////////////////////////////////////////////////////////////////////////////
        case REQUEST_CONST.UPDATE_REQUEST_START: {
            return {
                ...state,
                updateLoader: true,
                snackMsg: null,
            }
        }
        case REQUEST_CONST.UPDATE_REQUEST_SUCCESS: {
            return {
                ...state,
                updateLoader: false,
                selectedRequest: action.payload,
            }
        }
        case REQUEST_CONST.UPDATE_REQUEST_FAIL: {
            return {
                ...state,
                updateLoader: false,
                snackMsg: action.payload,
            }
        }

        //////////////////////////////////////////////////////////////////////////////////
        case REQUEST_CONST.FETCH_REQUESTS_START: {
            return {
                ...state,
                fetchLoader: true,
                snackMsg: null
            }
        }
        case REQUEST_CONST.FETCH_REQUESTS_SUCCESS: {
            return {
                ...state,
                fetchLoader: false,
                requests: [ ...state.requests,  ...action.payload ],
            }
        }
        case REQUEST_CONST.FETCH_REQUESTS_FAIL: {
            return {
                ...state,
                fetchLoader: false,
                snackMsg: action.payload // payload is the snackMsg
            }
        }

        default: return state;
    }
}