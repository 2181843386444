import React from 'react';
import { connect } from 'react-redux';
import { Avatar, CircularProgress, Table, TableHead, TableRow, TableBody, TableCell } from '@material-ui/core';

import Utility from '../../../../lib/utility';
import { setContentView } from '../../../../redux/dom/dom-actions';
import { endPoints } from '../../../../lib/values';

class LowerDashboard extends React.Component {

    state = {
        reqCountLoader: false,
        reqCountData:   {}
    }

    componentDidMount = () => {
        setTimeout(() => {
            this.reqCount();
        }, 100)
    }
    
    reqCount = () => {
        let { reqCountData } = this.state;
        const url = `${endPoints.report.requestCount}`;
        this.setState({ reqCountLoader: true })
        Utility._fetch(url, Utility.httpRequest('GET'), (err, jsonRes) => {
            if(!err){
                console.log(jsonRes);
                this.setState({ reqCountLoader: false, reqCountData:jsonRes });
            }
        })
    }
    
    
    render(){
        const { authReducer } = this.props;
        const { reqCountLoader, reqCountData } = this.state;

        return (
            <div style={{ width: 400, margin: '0px auto', display: 'flex', flexDirection: 'column' }}>
                { reqCountLoader
                ?
                    <div className="single-elm-container">
                        <CircularProgress color="secondary" />
                    </div>
                :<>
                    <div className="large-box">
                        <Avatar style={{ width: 120, height: 120, marginBottom: 16 }} src={ authReducer.user.image } />
                        <h3 style={{ margin: 0, padding: 0, fontFamily: 'sans-serif', color: '#333' }}>Hi, { authReducer.user.fullname.split(' ')[0] }</h3>
                    </div>
                    <Table style={{ marginTop: 32 }}>
                        <TableBody>
                            <TableRow>
                                <TableCell className="tb-head">Your Mobile</TableCell>
                                <TableCell>{ authReducer.user.mobile }</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="tb-head">Your Role</TableCell>
                                <TableCell>{ Utility.UserRoleToStr(authReducer.user.role)}</TableCell>
                            </TableRow>
                            { reqCountData.company &&
                            <TableRow>
                                <TableCell className="tb-head">Company</TableCell>
                                <TableCell>{ reqCountData.company }</TableCell>
                            </TableRow>
                            }
                            { reqCountData.package &&
                            <TableRow>
                                <TableCell className="tb-head">Package</TableCell>
                                <TableCell>{ reqCountData.package }</TableCell>
                            </TableRow>
                            }
                            <TableRow>
                                <TableCell className="tb-head">Resolved Requests</TableCell>
                                <TableCell>{ reqCountData.resolved }</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="tb-head">Un-Resolved Requests</TableCell>
                                <TableCell>{  reqCountData.unresolved  }</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </>
                } 
            </div>
        )
    }
}

function mapStateToProps(state){
    return {
        authReducer: state.authReducer
    }
}
function mapDispatchToProps(dispatch){
    return {
        setContentView: (view) => dispatch(setContentView(view))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LowerDashboard);