import AUTH_CONST from './auth-constants';

var initial_state = {
    appLoading:         true,
    authorizing:        false,
    userImgUploading:   false,
    profileUpdating:    false,
    fetchingProfile:    false,
    profileFailed:      false,
    authCodeLoader:     false,
    forgotLoader:       false,
    user:               {},
    snackMsg:           null,
    sessionError:       null,
}

export const authReducer = (state = initial_state, action) => {
    switch(action.type){

        ///////////////////////////////////////////////////////////////////////////////////////
        case AUTH_CONST.SET_SESSION_ERR: {
            return {
                ...state,
                sessionError: action.payload
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        case AUTH_CONST.SET_APP_LOADING: {
            return {
                ...state,
                appLoading: action.payload
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        case AUTH_CONST.SET_LOGIN_USER: {
            return {
                ...state, 
                user: action.payload
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        case AUTH_CONST.LOGOUT: {
            return {
                ...state,
                user: {},
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        case AUTH_CONST.SET_USER_IMG: {
            return {
                ...state,
                user: { ...state.user, image: action.payload  }
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        case AUTH_CONST.SET_SNACK: {
            return {
                ...state,
                snackMsg: action.payload
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        case AUTH_CONST.AUTH_START: {
            return {
                ...state, 
                authorizing: true,
                snackMsg: null
            }
        }

        case AUTH_CONST.AUTH_SUCCESS: {
            return {
                ...state, 
                authorizing: false,
                user: action.payload,
            }
        }

        case AUTH_CONST.AUTH_FAIL: {
            return {
                ...state, 
                authorizing: false,
                snackMsg: action.payload // payload is the snackMsg
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        case AUTH_CONST.FETCH_PROFILE_START: {
            return {
                ...state,
                fetchingProfile: true,
                profileFailed: false,
                snackMsg: null,
            }
        }
        case AUTH_CONST.FETCH_PROFILE_SUCCESS: {
            return {
                ...state,
                appLoading: false,
                fetchingProfile: false,
                user: action.payload,
            }
        }
        case AUTH_CONST.FETCH_PROFILE_FAIL: {
            return {
                ...state,
                appLoading: false,
                fetchingProfile: false,
                profileFailed: true,
                snackMsg: action.payload,
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        case AUTH_CONST.PROFILE_UPDATE_START: {
            return {
                ...state,
                snackMsg: null,
                profileUpdating: true,
            }
        }

        case AUTH_CONST.PROFILE_UPDATE_SUCCESS: {
            return {
                ...state,
                profileUpdating: false,
                user: action.payload,
            }
        }

        case AUTH_CONST.PROFILE_UPDATE_FAIL: {
            return {
                ...state,
                profileUpdating: false,
                snackMsg: action.payload,
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        case AUTH_CONST.USER_IMG_UPLOAD_START: {
            return {
                ...state, 
                snackMsg: null,
                userImgUploading: true,
            }
        }

        case AUTH_CONST.USER_IMG_UPLOAD_SUCCESS: {
            return {
                ...state, 
                userImgUploading: false,
            }
        }

        case AUTH_CONST.USER_IMG_UPLOAD_FAIL: {
            return {
                ...state, 
                userImgUploading: false,
                snackMsg: action.payload,
            }
        }


        //////////////////////////////////////////////////////////////////////////////////////
        case AUTH_CONST.GEN_AUTH_CODE_START: {
            return {
                ...state, 
                snackMsg: null,
                authCodeLoader: true,
            }
        }

        case AUTH_CONST.GEN_AUTH_CODE_SUCCESS: {
            return {
                ...state, 
                authCodeLoader: false,
            }
        }

        case AUTH_CONST.GEN_AUTH_CODE_FAIL: {
            return {
                ...state, 
                authCodeLoader: false,
                snackMsg: action.payload,
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        case AUTH_CONST.FORGOT_PASS_START: {
            return {
                ...state, 
                snackMsg: null,
                forgotLoader: true,
            }
        }

        case AUTH_CONST.FORGOT_PASS_SUCCESS: {
            return {
                ...state, 
                forgotLoader: false,
            }
        }

        case AUTH_CONST.FORGOT_PASS_FAIL: {
            return {
                ...state, 
                forgotLoader: false,
                snackMsg: action.payload,
            }
        }

        default: return state;
    }
}