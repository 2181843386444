import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Grid, CircularProgress } from '@material-ui/core';

import { connect } from 'react-redux';
import { Auth, App, Error } from '../pages';

import { loadingProfile, setAppLoading } from '../redux/auth/auth-actions';
import Utility from '../lib/utility';
import { storageKeys, endPoints } from '../lib/values';

class Protected extends React.Component {


    componentDidMount(){
        const { path } = this.props;
        if(path === '/' ||  path === '/app') {
            if (localStorage.getItem(storageKeys.appTokken) === null ||  localStorage.getItem(storageKeys.appTokken).trim() === '') {
                this.props.setAppLoading(false);
            } else {
                this.props.loadingProfile(endPoints.auth.myProfile, Utility.httpRequest('GET'), (err, success) => {
                    // do something after fetch profile
                })
            }
        }
    }

    isAuthorized = () => {
        const { authReducer } = this.props;
        return (authReducer.appLoading === false && authReducer.user._id ) ? true : false;
    }

    renderAppLoading = () => {
        return (
            <Grid 
                container 
                style = {styles.loaderContainer} >
                <CircularProgress color="secondary" />
            </Grid>
        )
    }

    redirectTo  = (to)  => <Redirect to = { to } />
    loginRoute  = ()    => <Route path =  '/' exact component = { Auth } />;
    cpanelRoute = ()    => <Route path = "/app" exact component = { App } />;
    errorRoute  = ()    => <Route path = "**" exact render = {() => <Error /> } />


    renderRoutes = () => {
        switch(this.props.path){
            case '/app':
                return  !this.isAuthorized() ? this.redirectTo('/') : this.cpanelRoute();
            case '/':
                return  this.isAuthorized()  ? this.redirectTo('/app') : this.loginRoute();
            default: 
                return  this.redirectTo('/');    
        }
    }

    render(){
        const { authReducer } = this.props;
        if(authReducer.sessionError != null || authReducer.profileFailed === true) 
            return this.redirectTo('/error')
        else if(authReducer.appLoading)
            return this.renderAppLoading();
        else 
            return this.renderRoutes()
    }
}

function mapStateToProps(state){
    return {
        authReducer: state.authReducer,
    }
}
function mapDispatchToProps(dispatch){
    return {
        setAppLoading: (appLoading) => dispatch(setAppLoading(appLoading)),
        loadingProfile: (url, body, cb) => dispatch(loadingProfile(url, body, cb)),
    }
}

const styles = {
    loaderContainer: {
        flex:           1, 
        display:        'flex', 
        alignItems:     'center',
        justifyContent: 'center', 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Protected);