import React from 'react';
import { connect } from 'react-redux';
import DeviceDetector from "device-detector-js";

import PasswordAdornment from '../components/password-adornment';

import { authorizing, setSnackMsg } from '../../../redux/auth/auth-actions';
import { PrimaryInput } from '../../../components/inputs';
import { PrimaryButton } from '../../../components/buttons';
import { endPoints, storageKeys } from '../../../lib/values';
import Utility from '../../../lib/utility';

class Login extends React.Component {

    state = {
        visiblePassword: false, 
        inputs: {}
    }

    onClickLogin = () => {
        
        const { inputs } = this.state;
        let dc = new DeviceDetector();
        const device = dc.parse(navigator.userAgent);
        const device_info = {
            client_name:     device.client.name,
            client_version:  device.client.version,
            os_name:         device.os.name,
            os_version:      device.os.version,
            device_type:     device.device.type,
            device_brand:    (device.device.brand + ' ' + device.device.model).trim(),
        }
        inputs.device_info = device_info;
        this.props.authorizing(endPoints.auth.login, Utility.httpRequest('POST', inputs), (err, jsonRes) => {
            if(jsonRes){
               localStorage.setItem(storageKeys.appTokken, jsonRes.access_token);
            }
        })
    }

    renderAdornment = () => {
        return (
            <PasswordAdornment 
                showPasswordIcon = { !this.state.visiblePassword } 
                onClickBtn  = { () => this.setState({ ...this.state, visiblePassword: !this.state.visiblePassword }) }    
            />
        )
    }

    render(){
        const { authReducer } = this.props;
        const { inputs } = this.state;
        return (
            <div className="animate__animated animate__fadeIn auth-inputs-container">
               
                <PrimaryInput 
                    inputLabel          = { "Mobile" }
                    formControlClasses  = { "light-input-container" } 
                    inputValue          = { inputs.mobile || '' }
                    inputMaxLength      = { 11 }
                    onChangeInput       = {(e) => this.setState({ inputs: { ...inputs, mobile: e.target.value.replace(/[^0-9]/g, '') }})}
                />
                <PrimaryInput 
                    inputType           = { !this.state.visiblePassword ? 'password' : 'text' }
                    inputLabel          = { "Password" }
                    formControlClasses  = { "light-input-container" }
                    endAdornment        = { this.renderAdornment() }
                    inputValue          = { inputs.password || '' }
                    onChangeInput       = {(e) => this.setState({ inputs: { ...inputs, password: e.target.value } })}
                />
                
                <div className="auth-btns-container">
                    <PrimaryButton
                        buttonText      = { "Forgot Password" }
                        buttonVariant   = { "text" }
                        buttonColor     = { "default" }
                        onClick         = { this.props.onForgotClick }
                    />
                    <PrimaryButton
                        buttonText      = { "Login" }
                        onClick         = { this.onClickLogin }
                        inProgress      = { authReducer.authorizing } 
                        buttonDisabled  = { authReducer.authorizing } 
                    />
                </div>        
            </div>
        )
    }
}

function mapStateToProps(state){
    return {
        authReducer: state.authReducer
    }
}

function mapDispatchToProps(dispatch){
    return {
        setSnackMsg: (msg) => dispatch(setSnackMsg(msg)),
        authorizing: (url, body, cb) => dispatch(authorizing(url, body, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);