import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Card, CardHeader, CardMedia, CardContent, Table, TableBody, TableRow, TableCell, Menu, MenuItem, CircularProgress } from '@material-ui/core';
import { MdMoreVert } from 'react-icons/all';
import Utility from '../../lib/utility';


class UserCard extends React.Component {

    state = {
        anchorEl: null,
    }

    closeMenu   = () => this.setState({ anchorEl: null })
    showMenu    = e  => this.setState({ anchorEl: e.currentTarget })

    onClickMenuItem = action => {
        this.closeMenu();
        switch(action){
            case 'resetPass':   return this.props.resetPassword()
            case 'suspend'  :   return this.props.suspendUser()
            case 'delete'   :   return this.props.deleteUser()
            default         :   return;
        }
    }

    render(){
        const { anchorEl } = this.state;
        const { user } = this.props;


        return (
            <>
                <Menu
                    open        = { Boolean(anchorEl)}
                    anchorEl    = { anchorEl }
                    onClose     = { this.closeMenu }>

                    <MenuItem onClick = { () => this.onClickMenuItem('resetPass') }>Reset Password</MenuItem>
                    <MenuItem onClick = { () => this.onClickMenuItem('suspend') }>{ user.suspended ? "Un-suspend" : "Suspend" }</MenuItem>
                    <MenuItem onClick = { () => this.onClickMenuItem('delete') }>Delete</MenuItem>

                </Menu>

                <Card>

                    <CardHeader
                        title       = { user.fullname }
                        subheader   = {  Utility.UserRoleToStr(user.role) }
                        style       = { styles.cardHead }
                        titleTypographyProps = {{ style: { fontSize: 18, fontWeight: 'bold' } }}
                        action      = {
                            <IconButton color="secondary" onClick = { this.showMenu } disabled = { this.props.inProgress }>
                                { !this.props.inProgress 
                                ? <MdMoreVert />
                                : <CircularProgress size = { 24 } color = "secondary" />
                                }
                            </IconButton>
                        }
                    />

                    <CardMedia
                        style   = {{ height: 200 }}
                        image   = { user.image }
                        title   = { user.fullname } 
                    />

                    
                
                    <CardContent style = { styles.cardContent }>
                        <Table size = "small">
                            <TableBody>
                                <TableRow hover>
                                    <TableCell style = { styles.bold }>Mobile</TableCell>
                                    <TableCell>{ user.mobile }</TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell style = { styles.bold }>Status</TableCell>
                                    <TableCell style={{ color: user.suspended ? '#cc0000' : 'green', fontWeight: 'bold' }}>{ user.suspended ? "Suspended" : "Not Suspended"  }</TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell style = { styles.bold }>Created</TableCell>
                                    <TableCell>{ Utility.formatDateOnly(user.created) }</TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell style = { styles.bold }>Created By</TableCell>
                                    <TableCell>{ user.created_by && user.created_by.fullname }</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </>
        )
    }
}

const styles = {
    bold: {
        fontWeight: 'bold' 
    },

    cardContent: {
       // padding: 0, 
        paddingBottom: 0 
    },

    cardHead: {
        backgroundColor: 'rgba(0,0,0,0.08)'
    }
}

UserCard.propTypes = {
    user:           PropTypes.object,
    resetPassword:  PropTypes.func,
    suspendUser:    PropTypes.func,
    deleteUser:     PropTypes.func,
    inProgress:     PropTypes.bool,
}

UserCard.defaultProps = {
    user:           {},
    inProgress:     false,
    resetPassword:  () => console.log('resetPassword not define'),
    suspendUser:    () => console.log('suspendUser not define'),
    deleteUser:     () => console.log('deleteUser not define'),
}

export default UserCard;