const CHAT_CONST = {

    SET_SNACK:                  'SET_SNACK',
    SET_SELECTED_CHATROOM:      'SET_SELECTED_CHATROOM',

    FETCH_MESSAGE_START:        'FETCH_MESSAGE_START',
    FETCH_MESSAGE_SUCCESS:      'FETCH_MESSAGE_SUCCESS',
    FETCH_MESSAGE_FAIL:         'FETCH_MESSAGE_FAIL',
    
    SEND_MESSAGE_START:         'SEND_MESSAGE_START',
    SEND_MESSAGE_SUCCESS:       'SEND_MESSAGE_SUCCESS',
    SEND_MESSAGE_FAIL:          'SEND_MESSAGE_FAIL',

}

export default CHAT_CONST;