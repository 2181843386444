const PKG_CONST = {
    SET_SNACK:                  'SET_SNACK',
    EMPTY_PACKAGE_LIST:         'EMPTY_PACKAGE_LIST',
    SET_SELECTED_PACKAGE:       'SET_SELECTED_PACKAGE',

    FETCH_PACKAGES_START:       'FETCH_PACKAGES_START',
    FETCH_PACKAGES_SUCCESS:     'FETCH_PACKAGES_SUCCESS',
    FETCH_PACKAGES_FAIL:        'FETCH_PACKAGES_FAIL',

    ADD_NEW_PACKAGE_START:      'ADD_NEW_PACKAGE_START',
    ADD_NEW_PACKAGE_SUCCESS:    'ADD_NEW_PACKAGE_SUCCESS',
    ADD_NEW_PACKAGE_FAIL:       'ADD_NEW_PACKAGE_FAIL',

    DEL_PACKAGE_START:          'DEL_PACKAGE_START',
    DEL_PACKAGE_SUCCESS:        'DEL_PACKAGE_SUCCESS',
    DEL_PACKAGE_FAIL:           'DEL_PACKAGE_FAIL',

    REPLACE_MANAGER_START:      'REPLACE_MANAGER_START',
    REPLACE_MANAGER_SUCCESS:    'REPLACE_MANAGER_SUCCESS',
    REPLACE_MANAGER_FAIL:       'REPLACE_MANAGER_FAIL',
}

export default PKG_CONST;