import React from 'react';
import { Table, TableRow, TableCell, TableBody } from '@material-ui/core';
import Utility from '../../../../lib/utility';
import { PrimaryButton } from '../../../../components/buttons';

const RequestDetail = (props) => {
    const { selectedRequest } = props;
    return (
        <>
            <div className="content-title-container">
                <span className="content-title-text">Request Detail</span>
                <div>
                    <PrimaryButton 
                        buttonText="Generate PDF" 
                        buttonSize="small" 
                        onClick = { props.onClickGeneratePdf }
                        inProgress  = { props.gen_pdf }
                        buttonDisabled  = { props.gen_pdf }
                    />
                </div>
            </div>
            <Table>
                <TableBody>
                <TableRow>
                    <TableCell className="tb-head">Title</TableCell>
                    <TableCell>{ selectedRequest.title }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tb-head">Request Type</TableCell>
                    <TableCell>{ Utility.requestTypeToStr(selectedRequest.request_type) }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tb-head">Support Required</TableCell>
                    <TableCell>{ Utility.reqSupportToStr(selectedRequest.support_required) }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tb-head">Problem Related To</TableCell>
                    <TableCell><strong>{ Utility.reqProblemToStr(selectedRequest.problem_type) }</strong> { selectedRequest.sub_problem.trim() !== '' && `(${selectedRequest.sub_problem})` }</TableCell>
                </TableRow>
                
                <TableRow>
                    <TableCell className="tb-head">Problem</TableCell>
                    <TableCell> { selectedRequest.problem } </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tb-head">Issued at</TableCell>
                    <TableCell>{ Utility.formatFullDate(selectedRequest.created) }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tb-head">Status</TableCell>
                    <TableCell> { Utility.reqStatusToStr(selectedRequest.status) } </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tb-head">Weigh Station</TableCell>
                    <TableCell>{ selectedRequest.station && selectedRequest.station.name }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tb-head">Bound</TableCell>
                    <TableCell>{ selectedRequest.station && selectedRequest.station.bound }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tb-head">Lane</TableCell>
                    <TableCell>{ selectedRequest.station && selectedRequest.station.lane }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tb-head">System</TableCell>
                    <TableCell>{ selectedRequest.station && selectedRequest.station.sys_installed }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tb-head">Package</TableCell>
                    <TableCell>{ selectedRequest.package && selectedRequest.package.name }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tb-head">Project Manager</TableCell>
                        <TableCell>
                            { selectedRequest.package && selectedRequest.package.manager && selectedRequest.package.manager.fullname } ({ selectedRequest.package && selectedRequest.package.manager && selectedRequest.package.manager.mobile })
                        </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tb-head">OMC</TableCell>
                    <TableCell>{ selectedRequest.omc && selectedRequest.omc.name } </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tb-head">TSP</TableCell>
                    <TableCell>{ selectedRequest.tsp && selectedRequest.tsp.name } </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tb-head">Operator</TableCell>
                    <TableCell>
                        { selectedRequest.operator && selectedRequest.operator.fullname } ({ selectedRequest.operator && selectedRequest.operator.mobile })
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tb-head">Tech Officer</TableCell>
                    <TableCell>
                        { !selectedRequest.tech_officer && "Not Yet Appointed" }
                        { selectedRequest.tech_officer && selectedRequest.tech_officer.fullname } { selectedRequest.tech_officer && ` (${selectedRequest.tech_officer.mobile})` }
                    </TableCell>
                </TableRow>
                </TableBody>
            </Table>
        </>
    )
}

export default RequestDetail;
