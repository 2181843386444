import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, IconButton } from '@material-ui/core';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

const PasswordAdornment = (props) => {
    return (
        <InputAdornment position="end">
            <IconButton 
                onClick = { props.onClickBtn } 
                title   = { props.showPasswordIcon ? "Show Password" : "Hide Password" } >
                { props.showPasswordIcon 
                    ? <MdVisibility /> 
                    : <MdVisibilityOff /> 
                } 
            </IconButton>
        </InputAdornment>
    )
}

PasswordAdornment.propTypes = {
    onClickBtn:         PropTypes.func,
    showPasswordIcon:   PropTypes.bool
}

PasswordAdornment.defaultProps = {
    onClickBtn:         () => console.log('Not setup'),
    showPasswordIcon:   true
}


export default PasswordAdornment;