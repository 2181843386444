import React from 'react';
import { connect } from 'react-redux';
import { HigherDashboard, LowerDashboard } from './segments';

class Dashboard extends React.Component {

    render(){
        const { authReducer } = this.props;
        return (authReducer.user.role === 1 || authReducer.user.role === 5)
        ? <HigherDashboard /> 
        : <LowerDashboard /> 
    }
}

function mapStateToProps(state){
    return {
        authReducer: state.authReducer
    }
}

export default connect(mapStateToProps, null)(Dashboard);