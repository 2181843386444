import React, { Component } from 'react';
import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { MdRadioButtonUnchecked, MdCheckCircle } from 'react-icons/all';

import { fetchRequests, setSelectedRequest, emptyRequests, setSnackMsg } from '../../../../redux/requests/request-actions';
import { PrimaryButton } from '../../../../components/buttons';
import Utility from '../../../../lib/utility';
import { endPoints } from '../../../../lib/values';

class RequestList extends Component {

    state = {
        page: 1
    }

    componentDidMount(){
        this.fetchRequests()
    }

    componentWillUnmount(){
        this.props.emptyRequests();
    }

    fetchRequests = () => {
        const url = `${endPoints.request.listAll}?page=${this.state.page}`;
        this.props.fetchRequests(url, Utility.httpRequest('GET'), (err, jsonData) => {
            // do something here....
        })
    }

    onRefresh = () => {
        this.props.emptyRequests();
        this.fetchRequests();
    } 

    render(){
        const { requestReducer, authReducer } = this.props;
        return(
            requestReducer.fetchLoader && requestReducer.requests.length === 0 
            ? 
            <div className="single-elm-container">
                <CircularProgress color="secondary" />
            </div>
            :
            <>
                <div className="tb-left-head-btn">
                    <PrimaryButton 
                        buttonText  = { "Refresh" }
                        onClick     = { this.onRefresh } 
                    />
                    { authReducer.user.role === 4  && 
                    <PrimaryButton 
                        buttonText  = { "Add New" } 
                        buttonStyle = {{ marginLeft: 8 }} 
                        onClick = { this.props.onClickAddNew } 
                    /> 
                    }
                </div>
                { requestReducer.requests.length === 0 
                ? <div style={{ textAlign: 'center', padding: 24, color: '#888' }}>No Data Found!</div>
                :
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className="tb-head">Title    </TableCell>
                            <TableCell className="tb-head">Support</TableCell>
                            <TableCell className="tb-head">Created</TableCell>
                            <TableCell className="tb-head">Status   </TableCell>
                            <TableCell className="tb-head">Station </TableCell>
                            <TableCell className="tb-head">Package </TableCell>
                            <TableCell className="tb-head">TSP</TableCell>
                            <TableCell className="tb-head">OMC</TableCell>
                            <TableCell className="tb-head">View </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        { requestReducer.requests.map((request) => 
                        <TableRow key={request._id}>
                            <TableCell>{ request.title }    </TableCell>
                            <TableCell>{ Utility.reqSupportToStr(request.support_required) }</TableCell>
                            <TableCell>{ Utility.timeAgoFormat(request.created) }</TableCell>
                            <TableCell>
                                { request.status === 0 
                                ? <Tooltip title="Issued"><div><MdRadioButtonUnchecked color ="#cc0000" size={24} /></div></Tooltip>
                                : <Tooltip title="Resolved"><div><MdCheckCircle color="#228B22" size={24}  /></div></Tooltip> }
                            </TableCell>
                            <TableCell>{ request.station && request.station.name }</TableCell>
                            <TableCell>
                                <span className="tb-head">{ request.package && request.package.name }</span> <br />
                                { request.package && request.package.manager && request.package.manager.fullname }<br />
                                <span style={{ color: '#888' }}>{ request.package && request.package.manager && request.package.manager.mobile }</span>
                            </TableCell>
                            <TableCell>
                            <span className="tb-head">{ request.tsp && request.tsp.name }</span> <br />
                                { !request.tech_officer 
                                ? <span style={{ color: '#888', fontSize: 12 }}> Tech Officer Not Appointed </span>
                                : 
                                <>
                                { request.tech_officer && request.tech_officer.fullname }<br />
                                <span style={{ color: '#888' }}>{ request.tech_officer && request.tech_officer.mobile }</span>
                                </>
                                }
                            </TableCell>
                            <TableCell>
                                <span className="tb-head">{ request.omc && request.omc.name }</span> <br />
                                { request.operator && request.operator.fullname }<br />
                                <span style={{ color: '#888' }}>{ request.operator && request.operator.mobile }</span>
                            </TableCell>
                            <TableCell> 
                                <PrimaryButton
                                    buttonText      = { "Detail" }
                                    buttonVariant   = { "outlined" }
                                    buttonSize      = { "small" }
                                    buttonColor     = { "secondary" }
                                    onClick         = { () => { this.props.setSelectedRequest(request); this.props.onClickViewRequest () }  }
                                />
                            </TableCell>
                        </TableRow>
                        )}
                    </TableBody>
                </Table>
            }
            </>
        )
    }
}

function mapStateToProps(state){
    return {
        requestReducer: state.requestReducer,
        authReducer: state.authReducer,
    }
}

function mapDispatchToProps(dispatch){
    return {
        setSnackMsg:        (msg)           => dispatch(setSnackMsg(msg)),
        emptyRequests:      ()              => dispatch(emptyRequests()),
        setSelectedRequest: (request)       => dispatch(setSelectedRequest(request)),
        fetchRequests:      (url, body, cb) => dispatch(fetchRequests(url, body, cb))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestList);