import React from 'react';
import { connect } from 'react-redux';

import { genAuthCode, resetPassword, setSnackMsg } from '../../../redux/auth/auth-actions';
import PasswordAdornment from '../components/password-adornment';
import { PrimaryInput } from '../../../components/inputs';
import { PrimaryButton } from '../../../components/buttons';
import { endPoints } from '../../../lib/values';
import Utility from '../../../lib/utility';

class Forgot extends React.Component {

    state = {
        step: 1,
        inputs: {},
        visiblePassword: false
    }

    generateAuthCode = () => {
        const { inputs } = this.state;
        this.props.genAuthCode(endPoints.auth.genAuthCode, Utility.httpRequest("POST", inputs), (err, jsonRes) => {
            
            if(jsonRes){
                this.setState({ step: 2 })
                this.props.setSnackMsg(jsonRes.message);
            }
        })
    }

    resetPassword = () => {
        const { inputs } = this.state;
        this.props.resetPassword(endPoints.auth.resetPassword, Utility.httpRequest("POST", inputs), (err, jsonRes) => {
            if(jsonRes){
                this.setState({ step: 3 })
            }
        })
    }

    renderAdornment = () => {
        return (
            <PasswordAdornment 
                showPasswordIcon = { !this.state.visiblePassword } 
                onClickBtn  = { () => this.setState({ ...this.state, visiblePassword: !this.state.visiblePassword }) }    
            />
        )
    }

    renderStep1 = () => {
        const { inputs } = this.state;
        const { authReducer } = this.props;
        return (
            <div className="animate__animated animate__fadeIn auth-inputs-container">
                
                <PrimaryInput 
                    inputLabel          = { "Mobile" }
                    formControlClasses  = { "light-input-container" }
                    inputValue          = { inputs.mobile || '' }
                    inputMaxLength      = { 11 }
                    onChangeInput       = {e => this.setState({ inputs: { ...inputs, mobile:  e.target.value.replace(/[^0-9]/g, '') } })}
                />

                <div className="auth-btns-container">
                    <PrimaryButton
                        buttonText      = { "Login" }
                        buttonVariant   = { "text" }
                        buttonColor     = { "default" }
                        onClick         = { this.props.onLoginClick }
                    />
                    <PrimaryButton
                        buttonText      = { "Get Code" }
                        inProgress      = { authReducer.authCodeLoader }
                        buttonDisabled  = { authReducer.authCodeLoader }
                        onClick         = { this.generateAuthCode }
                    />
                </div>
            </div>
        )
    }

    renderStep2 = () => {
        const { inputs } = this.state;
        const { authReducer } = this.props;
        return (
            <div className="animate__animated animate__fadeIn auth-inputs-container">
                
                <PrimaryInput 
                    inputLabel          = { "Authorization Code" }
                    formControlClasses  = { "light-input-container" }
                    inputValue          = { inputs.auth_code || '' }
                    inputMaxLength      = { 6 }
                    onChangeInput       = {e => this.setState({ inputs: { ...inputs, auth_code:  e.target.value.replace(/[^0-9]/g, '') } })}
                />
                <div className="auth-single-btn" style={{ marginTop: -28, marginBottom: 16 }}>
                    <PrimaryButton
                        buttonText      = { "Resend Code" }
                        buttonSize      = { "small" }
                        buttonColor     = { "default" }
                        buttonVariant   = { "text" }
                        inProgress      = { authReducer.authCodeLoader }
                        buttonDisabled  = { authReducer.authCodeLoader }
                        onClick         = { this.generateAuthCode }
                    />
                </div>
                <PrimaryInput 
                    inputType           = { !this.state.visiblePassword ? 'password' : 'text' }
                    inputLabel          = { "New Password" }
                    formControlClasses  = { "light-input-container" }
                    endAdornment        = { this.renderAdornment() }
                    inputValue          = { inputs.new_password || '' }
                    onChangeInput       = {e => this.setState({ inputs: { ...inputs, new_password:  e.target.value } })}
                    
                />

                <div className="auth-single-btn">
                    <PrimaryButton
                        buttonText      = { "Set Passowrd" }
                        inProgress      = { this.props.authReducer.forgotLoader }
                        buttonDisabled  = { this.props.authReducer.forgotLoader }
                        onClick         = { this.resetPassword }
                    />
                </div>
            </div>
        )
    }

    renderStep3 = () => {
        return (
            <div className="animate__animated animate__fadeIn auth-inputs-container">
                
                <div style={{ textAlign: 'center', fontFamily: "Roboto", color: '#666', marginTop: 16, marginBottom: 16 }}>
                    Your password has been changed successfully. 
                    You can login now with new password.
                </div>

                <div className="auth-single-btn">
                    <PrimaryButton
                        buttonText      = { "Login Now" }
                        onClick         = { this.props.onLoginClick }
                    />
                </div>
            </div>
        )
    }

    renderStep = () => {
        const { step } = this.state;
        switch(step){
            case 1: return this.renderStep1();
            case 2: return this.renderStep2();
            case 3: return this.renderStep3();
            default: return this.renderStep1()
        }
    }

    render(){
        return this.renderStep();
    }
}

const mapStateToProps = (state) => {
    return {
        authReducer: state.authReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSnackMsg:    (msg)           =>  dispatch(setSnackMsg(msg)),
        genAuthCode:    (url, body, cb) =>  dispatch(genAuthCode(url, body, cb)),
        resetPassword:  (url, body, cb) =>  dispatch(resetPassword(url, body, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);