import React from 'react';
import PropTypes from 'prop-types';

class Heading extends React.Component {
    
    render(){
        const { headingFirst, headingSecond } = this.props;
        return (
            <div className="heading-container">
                <h2 className="heading-first">{ headingFirst }</h2>
                <h3 className="heading-second">{ headingSecond }</h3>
            </div>
        )
    }
}

Heading.propTypes = {
    headingFirst:   PropTypes.string,
    headingSecond:  PropTypes.string,
}

Heading.defaultProps = {
    headingFirst:   'Let\'s start with a glance',
}

export default Heading;