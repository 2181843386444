import React from 'react';
import { connect } from 'react-redux';
import { FormControl, Select, MenuItem, CircularProgress } from '@material-ui/core';
import { Heading } from '../components'; 
import { PrimaryInput } from '../../../../components/inputs';
import { PrimaryButton } from '../../../../components/buttons';
import { PrimarySnackbar } from '../../../../components/messages';
import { endPoints } from '../../../../lib/values';
import Utility from '../../../../lib/utility';
import { fetchCompanies, emptyCompanyList } from '../../../../redux/company/company-actions';
import { newPackage, setSnackMsg } from '../../../../redux/packages/packages-actions';


class NewPackage extends React.Component {
    

    state = {
        inputs: {},
    }

    componentDidMount(){
       this.fetchTsp();
    }

    componentWillUnmount(){
        this.props.emptyCompanyList();
    }

    fetchTsp = () => {
        this.props.fetchTsp(`${endPoints.company.listAll}/1`, Utility.httpRequest('GET'), (err, jsonRes) => {
            // do something here...
        })
    }

    onSave = () => {
        const { inputs } = this.state;
        this.props.newPackage(endPoints.package.newPackage, Utility.httpRequest('POST', inputs), (err, resp) => {
            !err && this.props.onSuccess('New Package has been added successfully!') 
        })
    }

    render(){
        const { inputs } = this.state;
        const { packageReducer, companyReducer } = this.props;
        return companyReducer.fetchLoader 
            ? <div style={{ display: 'flex',justifyContent: 'center', padding: 24 }}><CircularProgress color="secondary" /></div>
            :
            <>

                <PrimarySnackbar 
                    open    = { Boolean(packageReducer.snackMsg) }
                    message = { packageReducer.snackMsg }
                    onClose = { () => this.props.setSnackMsg(null) }
                />
                <Heading 
                    headingFirst = "New Package"
                    headingSecond = "Packages contains weigh stations, Every package assigned to a TSP and have a manager"
                />

                <PrimaryInput
                    inputValue          = { inputs.name || "" }
                    inputfullWidth      = { true }
                    inputLabel          = { "Package Name" }
                    formControlClasses  = { "light-input-container" }
                    inputPlaceholder    = { "Package 1" }
                    onChangeInput       = { e => this.setState({ inputs: { ...inputs, name: e.target.value }})}
                />

                <div className="text-through-container">
                    <span className="text-through-text">Project Manager</span>
                </div>

                <PrimaryInput
                    inputValue          = { inputs.manager_name || "" }
                    inputfullWidth      = { true }
                    inputLabel          = { "Fullname" }
                    formControlClasses  = { "light-input-container" }
                    inputPlaceholder    = { "Abdus Samad" }
                    onChangeInput       = { e => this.setState({ inputs: { ...inputs, manager_name: e.target.value }})}
                />

                <PrimaryInput
                    inputValue          = { inputs.manager_mobile || "" }
                    inputfullWidth      = { true }
                    inputLabel          = { "Mobile" }
                    formControlClasses  = { "light-input-container" }
                    inputPlaceholder    = { "03336999640" }
                    inputMaxLength      = { 11 }
                    onChangeInput       = { e => this.setState({ inputs: { ...inputs, manager_mobile: e.target.value.replace(/[^0-9]/g, '') }})}
                />

                <div className="text-through-container">
                    <span className="text-through-text">TSP</span>
                </div>

                <FormControl fullWidth>
                    <Select
                        fullWidth
                        variant     = "outlined"
                        style       = {{ backgroundColor: '#f2f2f2', marginBottom: 24 }}
                        value       = { inputs.tsp_id || " "}
                        onChange    = { e => this.setState({ inputs: { ...inputs, tsp_id: e.target.value } }) }>
                        <MenuItem value={" "}><em>Choose TSP</em></MenuItem>
                        { companyReducer.companies.map(tsp =>
                            <MenuItem 
                                key     = { tsp._id } 
                                value   = { tsp._id }>
                                    { tsp.name }
                            </MenuItem> 
                        )}
                    </Select>
                </FormControl>

                
                <div className="auth-btns-container">
                    <PrimaryButton
                        buttonText      = "Start Again"
                        buttonVariant   = "outlined"
                        buttonColor     = "secondary"
                        onClick         = { this.props.onStartAgain }
                    />
                    <PrimaryButton
                        buttonText      = "Save"
                        inProgress      = { packageReducer.addLoader }
                        buttonDisabled  = { packageReducer.addLoader }
                        onClick         = { this.onSave }
                    />
                </div>
            </>
    }
}

function mapStateToProps(state){
    return {
        packageReducer: state.packageReducer,
        companyReducer: state.companyReducer,
    }
}


function mapDispatchToProps(dispatch){
    return {
        setSnackMsg:     (msg)           => dispatch(setSnackMsg(msg)),
        emptyCompanyList:()              => dispatch(emptyCompanyList()),
        newPackage:      (url, body, cb) => dispatch(newPackage(url, body, cb)),
        fetchTsp:        (url, body, cb) => dispatch(fetchCompanies(url, body, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPackage);