import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Protected  from './protected';
import { Error } from '../pages';

const index = () => {
    return(
    <BrowserRouter>
        <Switch>
            <Route path = "/error" component = { Error } />
            <Protected path = "/app" />
            <Protected path = "/" />
        </Switch>
    </BrowserRouter>
    )
}

export default index;