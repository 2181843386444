import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

const PrimaryButton = (props) => {
    return (
        <Button 
            color       = { props.buttonColor }
            variant     = { props.buttonVariant }
            size        = { props.buttonSize }
            disabled    = { props.buttonDisabled }
            fullWidth   = { props.buttonFullWidth }
            href        = { props.href }
            target      = { "_blank" }
            style       = { props.buttonStyle }
            classes     = { props.btnClasses }
            onClick     = { props.onClick }
            disableElevation>
                { props.inProgress 
                ? <CircularProgress size = { props.progCircleSize } color = { props.progCircleColor } />
                : <span style = { props.btnTextStyle }>{ props.buttonText } </span>
                }
        </Button>
    )
}

PrimaryButton.propTypes = {
    buttonColor:        PropTypes.oneOf(['default', 'primary', 'secondary']),
    buttonSize:         PropTypes.oneOf(['small', 'medium', 'large']),
    buttonVariant:      PropTypes.oneOf(['text', 'outlined', 'contained', 'fab', 'extendedFab']),
    buttonFullWidth:    PropTypes.bool,
    buttonDisabled:     PropTypes.bool,
    buttonText:         PropTypes.string,
    href:               PropTypes.string,
    onClick:            PropTypes.func,
    progCircleSize:     PropTypes.number,
    progCircleColor:    PropTypes.oneOf(['default', 'primary', 'secondary']),
    inProgress:         PropTypes.bool,
    buttonStyle:        PropTypes.object,
    btnTextStyle:       PropTypes.object,
    btnClasses:         PropTypes.object,

}

PrimaryButton.defaultProps = {
    buttonColor:     'primary',
    buttonSize:      'large',
    buttonVariant:   'contained',
    buttonFullWidth: false,
    buttonDisabled:  false,
    buttonText:      'Click Me',
    progCircleSize:  30,
    progCircleColor: 'secondary',
    inProgress:      false,
}

export default PrimaryButton;