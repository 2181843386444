import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Heading } from '../components'; 
import { PrimaryButton } from '../../../../components/buttons';
import { segments } from '../new-resource';


class Success extends React.Component {

    renderListBtnText = () => {
        const { current_segment } = this.props;
        switch(current_segment){
            case segments.ws:       return "View All Stations";
            case segments.pkg:      return "View All Packages";
            case segments.tsp:      return "View All TSPs";
            case segments.omc:      return "View All OMCs";
            case segments.director: return "View All Directors";
            case segments.admin:    return "View All Admins";
            default:                return "View All"
        }
    }
    
    render(){
        const { success_icon, success_msg } = this.props;
        return (
            <>
                <Heading headingFirst = "Congratulations" />
                
                <div className="success-msg-container">
                    { success_icon }
                    { success_msg }
                </div>
                
                <div className="auth-btns-container">
                    <PrimaryButton
                        buttonText      = "Start Again"
                        buttonVariant   = "outlined"
                        buttonColor     = "secondary"
                        onClick         = { this.props.onStartAgain }
                    />
                    <PrimaryButton
                        buttonText      = { this.renderListBtnText() }
                        onClick         = { this.props.onSuccessViewList }
                    />
                </div>
            </>
        )
    }
}

Success.propTypes = {
    success_msg:        PropTypes.string,
    success_icon:       PropTypes.node,
    current_segment:    PropTypes.string,
    onStartAgain:       PropTypes.func,
    onSuccessViewList:  PropTypes.func,
}


export default connect(null, null)(Success);