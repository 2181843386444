import React, { Component } from 'react';
import { Button, IconButton } from '@material-ui/core';
import { IoMdArrowDropright } from 'react-icons/all';
import { connect } from 'react-redux';

import { RequestList, RequestEditor, NewRequest } from './segments';

const segments = {
    requestList:    'List',
    requestEditor:  'Editor',
    newRequest:     'newRequest',
}

class Requests extends Component {

    state = {
        currentSegment: segments.requestList,
        editDialog:     false,
    }

    renderSegment = () => {
        const { currentSegment } = this.state;
        switch(currentSegment){
            case segments.requestList:     return <RequestList onClickAddNew = { () => this.setState({ editDialog: true }) } onClickViewRequest = {() => this.setState({ currentSegment: segments.requestEditor })} /> 
            case segments.requestEditor:   return <RequestEditor /> 
            default:                       return <RequestList onClickAddNew = { () => this.setState({ editDialog: true }) } onClickViewRequest = {() => this.setState({ currentSegment: segments.requestEditor })}  />
        }
    }

    render(){
        const { editDialog } = this.state;
        return(
            <>
                <div style={{ borderBottom: '1px solid #eee' }} >
                    <Button onClick = {() => this.setState({ currentSegment: segments.requestList }) }>Support Request</Button>
                    <IconButton disabled><IoMdArrowDropright /></IconButton>
                    <Button disabled>{ this.state.currentSegment }</Button>
                </div>
                { this.renderSegment() }
                { editDialog &&
                    <NewRequest 
                        showDialog  = { editDialog }
                        onClose     = { () => this.setState({ editDialog: false })}
                    />
                }
            </>
        )
    }
}

function mapStateToProps(state){
    return {
        requestReducer: state.requestReducer,
    }
}

export default connect(mapStateToProps, null)(Requests);