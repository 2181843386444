import React, { Component } from 'react';
import { Button, IconButton, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { IoMdArrowDropright } from 'react-icons/all';
import { connect } from 'react-redux';

import { emptyActivities, fetchActivites } from '../../../redux/activity/activity-actions';
import { PrimaryContainer, } from '../../../components/container';
import { PrimaryButton } from '../../../components/buttons';
import { endPoints, dataLimit } from '../../../lib/values';
import Utility from '../../../lib/utility';


class Activities extends Component {

    state = {
        page: 1,
        haveMore: true,
    }

    componentDidMount(){
        this.fetchActivities();
    }

    componentWillUnmount(){
        this.props.emptyActivities();
    }

    fetchActivities = () => {
        const { page } = this.state;
        const url = `${endPoints.activity.listAll}?page=${page}&limit=${dataLimit.perPage}`;
        this.props.fetchActivites(url, Utility.httpRequest('GET'), (err, jsonData) => {
            if(jsonData){
                jsonData.length < dataLimit.perPage ? this.setState({ haveMore: false }) :this.setState({ haveMore: true })
            }
        })
    }

    onLoadMore = () => {
        let { page } = this.state;
        this.setState({ page: ++page }, this.fetchActivities);
    }

    render(){
        const { activityReducer } = this.props;
        const { haveMore } = this.state;
        return(
            <PrimaryContainer>

                <div style={{ borderBottom: '1px solid #eee' }} >
                    <Button>Activities</Button>
                    <IconButton disabled><IoMdArrowDropright /></IconButton>
                </div>
                
                <div className="fixed-content-width">
                    { activityReducer.fetchLoader && activityReducer.activities.length === 0
                    ? 
                    <div className="single-elm-container">
                        <CircularProgress color="secondary" />
                    </div>
                    :
                    <>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="tb-head">#     </TableCell>
                                <TableCell className="tb-head">Activity     </TableCell>
                                <TableCell className="tb-head">Created      </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            { activityReducer.activities.map((activity, i) => 
                            <TableRow key={activity._id}>
                                <TableCell>{ i + 1 }</TableCell>
                                <TableCell>{ activity.text }</TableCell>
                                <TableCell>{ Utility.formatFullDate(activity.created) } <br /> 
                                <span style={{ color: '#888' }}>{ Utility.timeAgoFormat(activity.created) } </span>
                            </TableCell>
                            </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <div className="single-elm-container">
                        <PrimaryButton 
                            buttonText      = "Load More"
                            inProgress      = { activityReducer.fetchLoader }
                            buttonDisabled  = { Boolean(activityReducer.fetchLoader || !haveMore ) }
                            onClick         = { this.onLoadMore }
                        />
                    </div>
                    </>
                    }
                </div>
            </PrimaryContainer>
        )
    }
}

function mapStateToProps(state){
    return {
        activityReducer: state.activityReducer,
    }
}

function mapDispatchToProps(dispatch){
    return {
        emptyActivities:      ()              =>  dispatch(emptyActivities()),
        fetchActivites:       (url, body, cb) =>  dispatch(fetchActivites(url, body, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Activities);